module.exports=function(scope){ return `<!DOCTYPE html>
<html>
<head>
    <title>Chat</title>
    <link rel="icon" type="image/x-icon" rel="shortcut icon" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTnU1rJkAAABOUlEQVQ4T81SsY6CUBDkw1RCQSQWECxNJNoYLTCEUNDZATZWVGAhH0Bs/Ab9BBpC5QdAQT2XfXDv7uTlcrlccZNMstm3MyyTlf4XNpsNRqMRNE2DrutQFAWqqiKKIvQjYoRhiOl0ivP5jKqq0LYtY9M0uN/v8DyPvffjQxiGgbIsuVDEy+WC2Ww2NLEsC9frVSh65Wq1GhrQvz4eD6Hglb7vc4PJZNLV1AyCQCj4zOfzyXM4nU4sbNd1OxNKOo5j1HUtFBdFgeVyyTegLMhgPp/zjSTTNOE4DrIsQ57nuN1urKavyLKM4/HIhsmExMT9fv9hQFlQc7FYYLfbYb1eM3GSJHzItm0uJvbt7g6+uL3gcDiwLd6FdGD90/egy6RhEo3HY1DyVG+3258ZiEDbkGmapr83+UNI0hucYFhKHTy+rAAAAABJRU5ErkJggg=="/>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">

    <style>
        #asseco-chat-window {
            width: 420px;
            margin-left: auto;
            margin-right: auto;
        }

        .mdl-list {
            display: block;
            padding: 8px 0;
            list-style: none;
        }

        .mdl-list__item {
            font-family: Roboto,Helvetica,Arial,sans-serif;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .01em;
            line-height: 1;
            display: flex;
            min-height: 48px;
            box-sizing: border-box;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            padding: 0;
            margin: 0;
            cursor: default;
            color: rgba(0,0,0,.87);
            overflow: hidden;
        }

        .mdl-list__item .mdl-list__item-primary-content {
            margin: 5px 5px 5px 35px;
            padding: 10px;
            order: 0;
            flex-grow: 2;
            text-decoration: none;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }

        .asseco-window-chat-agent-message .mdl-list__item-primary-content {
            background-color: #e0f2f1;
        }

        .asseco-window-chat-agent-message:after {
            content: " ";
            position: relative;
            left: -385px;
            top: -10px;
            border: 5px solid;
            border-color: #e0f2f1 #e0f2f1 transparent transparent;
        }

        .asseco-window-chat-client-message .mdl-list__item-primary-content {
            background-color: #c5e1a5;
            margin: 5px 15px 5px 50px;
        }

        .asseco-window-chat-client-message:after {
            content: " ";
            position: relative;
            right: 80px;
            top: -5px;
            border: 5px solid;
            border-color: #c5e1a5 transparent transparent #c5e1a5;
        }

        .asseco-window-chat-message-time {
            padding: 5px;
            font-size: 12px;
            min-width: 55px;
            width: 55px;
        }

        .asseco-window-chat-agent-message .mdl-list__item-icon {
            position: relative;
            left: -40px;
            top: -12px;
            margin-left: 0 !important;
            margin-right: -25px !important;
        }

        .mdl-list__item-icon, .mdl-list__item-icon.material-icons {
            width: 24px;
            height: 24px;
            font-size: 24px;
            box-sizing: border-box;
            color: #757575;
        }
    </style>
</head>
<body>
    <div id="asseco-chat-window">
        <ul class="demo-list-item mdl-list">
            ${scope.textFromChat}
        </ul>
    </div>
</body>
</html>`};