import Connection from 'data/Connection';
import 'util/String';
import Utils from 'util/Utils';

///////////////////////
/////////////////////////////
//////////

/**
 * LIVE Check IP Component (not available in Asseco namespace)
 *
 * This component authenticate user already logged into private part of web page
 * It expect that Asseco.customer property is available with information needed to authenticate existing user
 *
 * @private
 */
class LiveCheckIP {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        Utils.apply(this, config);

        Utils.toast(a24n('Connecting...'), 60000);
        fetch(Asseco.ChatServerUrl + '/authAdapters/checkIP/?validate&uuid=' + Asseco.UUID, {
            method: 'post',
            body: JSON.stringify(Asseco.contextData || {}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                Utils.toast(null);
                if (res.success) {
                    // set display name to Asseco namespace
                    Asseco.displayName = res.displayName;

///////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////
//////////////////////////////

                    // Utils.toast(String.format(a24n('Welcome {0}'), Asseco.displayName));

                    console.log('LiveAuthUser::Got ws url: ' + res.wsUrl);
                    Connection.wsUrl = res.wsUrl;
                    Connection.connect();
                }
                else {
                    if (res.adapter) {
                        console.log('LiveCheckIP::Secondary adapter: ' + res.adapter);
                        Asseco.config.authAdapter = res.adapter;
                        Asseco.UUID = Utils.generateUUID();
                        console.log('LiveCheckIP::Regenerate Asseco UUID: ' + Asseco.UUID);
                        
                        Connection.wsUrl = null;
                        Connection.connect();
                    } else {
                        // Utils.toast(String.format(a24n('Error fetching token: {0}'), res.reason), 10000, () => {
                        //     Utils.toast(null);
                        // }, a24n('Close'));

                        Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), res.reason));
                    }
                }
            })
            .catch((err) => {
                // Utils.toast(String.format(a24n('Error fetching token: {0}'), err.message), 10000, () => {
                //     Utils.toast(null);
                // }, a24n('Close'));

                Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }
}
LiveCheckIP.prototype.xtype = 'LiveCheckIP';
export default LiveCheckIP;
