import Connection from 'data/Connection';
import 'util/String';
import Utils from 'util/Utils';

///////////////////////
/////////////////////////////
//////////

/**
 * LIVE Auth User Component (not available in Asseco namespace)
 *
 * This component authenticate user already logged into private part of web page
 * It expect that Asseco.customer property is available with information needed to authenticate existing user
 *
 * @private
 */
class LiveAuthUser {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        Utils.apply(this, config);

        var cP = Object.keys(Asseco.customer)[0],
            cV = Asseco.customer[cP];

        Utils.toast(a24n('Connecting...'), 60000);
        fetch(Asseco.ChatServerUrl + '/authAdapters/authUser/?validate&uuid=' + Asseco.UUID + '&customer[' + cP + ']=' + cV, {
            method: 'post',
            body: JSON.stringify(Asseco.contextData || {}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                if (res.success) {
                    // set display name to Asseco namespace
                    Asseco.displayName = res.displayName;

///////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////
//////////////////////////////

                    Utils.toast(null);
                    // Utils.toast(String.format(a24n('Welcome {0}'), Asseco.displayName));

                    console.log('LiveAuthUser::Got ws url: ' + res.wsUrl);
                    Connection.wsUrl = res.wsUrl;
                    Connection.connect();
                }
                else {
                    // Utils.toast(String.format(a24n('Error fetching token: {0}'), res.reason), 10000, () => {
                    //     Utils.toast(null);
                    // }, a24n('Close'));

                    Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), res.reason));

///////////////////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////
//////////////////////////////
                }
            })
            .catch((err) => {
                // Utils.toast(String.format(a24n('Error fetching token: {0}'), err.message), 10000, () => {
                //     Utils.toast(null);
                // }, a24n('Close'));

                Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }
}
LiveAuthUser.prototype.xtype = 'LiveAuthUser';
export default LiveAuthUser;
