import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import Element from 'util/Element';
import LiveChat from './../chat/index';

/**
 * LIVE Screen Share Component (not available in Asseco namespace) <br/>
 * Components is used for sharing client screen to agent in LIVE and is only available during active videochat with agent in LIVE
 *
 * Available only when using Asseco Toolbar
 */
class LiveScreenShare extends LiveChat {
    /**
     * Holds reference to LiveVideoChatWindow
     *
     * @private {LiveVideoChatWindow} windowVideo
     */
    windowVideo;

    /**
     * Is screen sharing started
     *
     * @private {Boolean} started
     */
    started = false;

    /**
     * Holds info if this component is supported
     *
     * @private {Boolean} isSupported
     */
    isSupported;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier     : 'screenshare',
            label          : a24n('Start Screen Share'),
            icon           : 'M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.11-.9-2-2-2H4c-1.11 0-2 .89-2 2v10c0 1.1.89 2 2 2H0v2h24v-2h-4zm-7-3.53v-2.19c-2.78 0-4.61.85-6 2.72.56-2.67 2.11-5.33 6-5.87V7l4 3.73-4 3.74z',
            renderDisabled : config.isPlugin
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Start Screen Share
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

////////////////////////////////////
        // check if WebRTC is supported on this browser
        if (require('webrtc-adapter').default.browserDetails.browser === 'Not a supported browser.') {
            this.isSupported = false;
            this.disable();
            document.querySelector('.mdl-tooltip[for=' + this.id + ']').innerHTML = a24n('Not Supported');
        }
        else {
            this.isSupported = true;
        }
//////////////////

////////////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////
///////////////////////
/////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////
    }

    /**
     * Enable component if disabled (enable is not possible if WebRTC is not supported)
     */
    enable() {
        if (! this.isSupported) {
            return;
        }

        super.enable();
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onClick(event) {
        if (this.disabled) {
            return;
        }

        if (! this.started) {
            this.onStartScreenShare();
        }
        else {
            this.onStopScreenShare();
        }
    }

    /**
     * Executed on screen share start
     *
     * @private
     */
    onStartScreenShare() {
        if (this.started || ! this.windowVideo) {
            return;
        }

        if (this.windowVideo.webRtcPeerConnection && ! this.windowVideo.localScreenShareStream) {
            var mediaConstraints = {
                audio: false,
                video: {
                    maxWidth: 1920,
                    maxHeight: 1080,
                    minAspectRatio: 1.77,
                    cursor: 'always'
                }
            };

            navigator.mediaDevices.getDisplayMedia(mediaConstraints)
                .then((stream) => {
                    console.log('LiveScreenShare::User has granted access to share screen');

                    this.windowVideo.localScreenShareStream = stream;
                    this.windowVideo.webRtcPeerConnection.addStream(this.windowVideo.localScreenShareStream);

                    Element.addClass(this.containerEl, 'is-active');
                    this.started = true;
                })

                .catch((err) => {
                    console.log('LiveScreenShare::Failed to get access to local media. Error code was ' + err.code);
                });
        }
    }

    /**
     * Executed on screen share stop
     *
     * @private
     */
    onStopScreenShare() {
        if (!this.started || ! this.windowVideo || ! this.windowVideo.localScreenShareStream) {
            return;
        }

        // this.windowVideo.webRtcStopMediaTracks(this.windowVideo.localScreenShareStream);

        this.windowVideo.webRtcPeerConnection.removeStream(this.windowVideo.localScreenShareStream);
        var pc = this.windowVideo.webRtcPeerConnection,
            stream = this.windowVideo.localScreenShareStream;

        pc.getSenders().forEach(s => {
            stream.getTracks().forEach(t => {
                if (t === s.track) {
                    pc.removeTrack(s);
                }
            });
        });

        this.windowVideo.localScreenShareStream = null;

        Element.removeClass(this.containerEl, 'is-active');
        this.started = false;
    }
}
LiveScreenShare.prototype.xtype = 'LiveScreenShare';
CmpMgr.registerXtype(LiveScreenShare.prototype.xtype, LiveScreenShare);
export default LiveScreenShare;
