import Utils from 'util/Utils';
import LiveVideochatWindowVideo from 'components/videochat/window/Video';

/**
 * LIVE Audio Chat Window Audio Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveAudiochatWindowAudio extends LiveVideochatWindowVideo {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id       : 'asseco-audiochat-window-audio-panel',
            cls      : 'asseco-audiochat-window-audio',
            type     : 'audio',
            elPrefix : 'asseco-audiochat-window-audio-'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Audio.scss');
    }

    /**
     * Get component template
     *
     * @return {String}
     * @private
     */
    getTemplate() {
        if (! this.template) {
            this.template = require('babel-loader!template-string-loader!./Audio.html')({
                audioTxt: Utils.getIconMarkup('M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z', 'asseco-absolute-center audio-playing', 'width: 56px; height: 56px;', '#000'),
                audioBtnOn: Utils.getIconMarkup(this.audioBtnOnIcon)
            });
        }
        return this.template;
    }
}
LiveAudiochatWindowAudio.prototype.xtype = 'LiveAudiochatWindowAudio';
export default LiveAudiochatWindowAudio;
