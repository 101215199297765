import Svgs from 'data/Svgs';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import Utils from 'util/Utils';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE Filemanager Auth Abstract Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveFilemanagerAuthAbstract extends LiveCard {
    /**
     * Folder that needs to be authorized
     *
     * @type {Object} file
     */
    folder;

    /**
     * Icon for sign button (SVG path or icon img path)
     *
     * @type {String} signIcon
     */
    signIcon;

    /**
     * Icon for showing document in full screen (SVG path or icon img path)
     *
     * @type {String} docFullIcon
     */
    docFullIcon;

    /**
     * Prefix for elements ids
     *
     * @type {String} pf
     * @private
     */
    pf;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            buttonsAlign   : 'center',
            btnOkCls       : 'asseco-mdl-button--colored-green',
            btnCloseCls    : 'asseco-mdl-button--colored-red',
            destroyOnClose : true,
            signIcon       : Svgs.EDIT,
            docFullIcon    : Svgs.FULL_SCREEN
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Change window size for loading
     *
     * @type {Number} h Window height to set (default to 200px)
     * @private
     */
    wHeight(h = 200) {
        if (! window.matchMedia('(max-width: 700px)').matches) {
            this.setSize(null, h +  'px');
        }
    }

    /**
     * Get documents and links for signing
     *
     * @private
     */
    getFolderFiles() {
        this.showMask(a24n('Fetching documents...'));
        Connection.sendMessage(Constants.REQ_GET_FOLDER_FILES, {
            type : 'merge',
            path : this.folder.path
        }, this.onGetFolderFiles, this);
    }
}
LiveFilemanagerAuthAbstract.prototype.xtype = 'LiveFilemanagerAuthAbstract';
export default LiveFilemanagerAuthAbstract;
