import Svgs from 'data/Svgs';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import CmpMgr from 'util/ComponentManager';
import Element from 'util/Element';
import Utils from 'util/Utils';
import LiveField from 'widgets/field/Field';
import LiveList from 'widgets/list/List';

/////////////////////////////////////////////
import LiveFilemanagerRds from './../auth/Rds/Rds';
//////////

////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////

/**
 * LIVE Filemanager Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveFilemanagerWindow extends LiveList {
    /**
     * Enable moving to parent folder
     *
     * @type {Boolean} enableParentFolder
     */
    enableParentFolder;

    /**
     * Enable downloading folder as zip files
     *
     * @type {Boolean} enableFolderDownload
     */
    enableFolderDownload;

    /**
     * Current folder level
     *
     * @private {Number} folderLevel
     */
    folderLevel;

    /**
     * Folder level data
     *
     * @private {Array} folderLevelData
     */
    folderLevelData;

    /**
     * Holds list of available autohrization types
     *
     * @private {Object} authTypes
     */
    authTypes;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id                   : 'asseco-filemanager-window',
            title                : a24n('Filemanager'),
            buttonsAlign         : 'center',
            enableParentFolder   : true,
            enableFolderDownload : true,
            enableFilter         : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        super.initComponent();

        this.authTypes = {};

/////////////////////////////////////////////////////
        this.authTypes.Rds = LiveFilemanagerRds;
//////////////////

////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Get list of files
     *
     * @private
     */
    fetchList() {
        super.fetchList();

        this.showMask();

        var d = {
            limit  : this.limit || 15,
            filter : this.filter || [{field: 'type', value: 'notsigned'}]
        };

        console.log('LiveFilemanagerWindow::Fetching list');
        Connection.sendMessage(Constants.REQ_GET_FILES, d, this.onFetchList, this);
    }

    /**
     * Executed after list is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onFetchList(conn, msg) {
        var d = JSON.parse(msg.data);

        this.waitingConfirmCount = 0;

        this.folderLevel = 0;
        this.folderLevelData = {
            0: d
        };

        this.addItem(this.folderLevelData[this.folderLevel]);
        this.hideMask();
    }

    /**
     * Prepare folder name for display
     *
     * @param {String} n
     */
    static displayFolderName(n) {
        if (typeof n === 'string') {
            return Utils.ellipsis(n.replace(/\s\(ID:\s(\w){5}\)/gi, ''), 40);
        }
        return n;
    }

    /**
     * Add item(s) to list
     *
     * @param {Object|Array} item Item with id, name and icon for adding to listconf
     */
    addItem(item) {
        // make sure we have array
        item = Array.isArray(item) ? item : [item];
        item.forEach(i => {
            i.name = LiveFilemanagerWindow.displayFolderName(i.name);
            i.desc = Utils.toDate(i.creation_time, true);
            i.icon = i.type === 'folder' ? 'M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z' : 'M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z';
            i.actions = [];

            if (i.type === 'folder' && i.name !== '..') {
                var opt = i.conf ? i.conf.options : null;

                // folder needs some kind of authorization
                if (opt && ! Utils.isEmpty(opt.authorization)) {
                    // show authorization status in description
                    i.desc += ' <span class="asseco-files-' +
                                    (Utils.isEmpty(i.auth) ? ('waiting">' + a24n('Waiting confirmation')) : ('confirmed">' + a24n('Confirmed'))) +
                                '</span>';

                    // show authorization button
                    if (Utils.isEmpty(i.auth)) {
                        i.actions.push({
                            type: 'auth',
                            icon: Svgs.AUTH,
                            tooltip: a24n('Authorize documents')
                        });
                    }
                }

                // show download button
                if (this.enableFolderDownload) {
                    i.actions.push({
                        type: 'download',
                        icon: Svgs.DOWNLOAD,
                        tooltip: a24n('Download')
                    });
                }
            }
            else if (i.type === 'file') {
                // this should be translated in LIVE but just to make sure its translated
                if (i.name.indexOf('RDS Signed Contract') > -1) {
                    i.name = a24n('RDS Signed Contract');
                }
                i.desc += ! Utils.isEmpty(i.auth) ? (' <span class="asseco-files-confirmed">' + a24n('Confirmed') +'</span>') : '';
            }
        }, this);

        console.log('Add item: ', item);
        super.addItem(item);
    }

    /**
     * Executed on filter tool click (Show filter mask)
     *
     * @private
     */
    showFilterMask() {
        var fR = LiveField.getRadioField;

        var type = 'notsigned', limit = this.limit;
        if (! Utils.isEmpty(this.filter)) {
            this.filter.forEach(f => {
                if (f.field === 'type') {
                    type = f.value;
                }
            }, this);
        }

        this.setContent(
            '<div style="padding: 15px 0 15px 0;">' + a24n('Show items') + ': </div>' +
            fR({
                name  : 'type',
                label : a24n('All'),
                value : 'all',
                checked : type === 'all',
                attrs : ' style="width: 100%"'
            }) +
            fR({
                name    : 'type',
                label   : a24n('Not Signed'),
                value   : 'notsigned',
                checked : type === 'notsigned',
                attrs   : ' style="width: 100%"'
            }) +
            fR({
                name  : 'type',
                label : a24n('Signed'),
                value : 'signed',
                checked : type === 'signed',
                attrs : ' style="width: 100%"'
            }) +
            '<div style="padding: 15px 0 15px 0;">' + a24n('Show limit') + ': </div>' +
            fR({
                name  : 'limit',
                label : a24n('All'),
                value : 'all',
                checked : limit === 'all',
                attrs : ' style="width: 100%"'
            }) +
            fR({
                name  : 'limit',
                label : '15',
                value : 15,
                checked : parseInt(limit, 10) === 15,
                attrs : ' style="width: 100%"'
            }) +
            fR({
                name    : 'limit',
                label   : '30',
                value   : 30,
                checked : parseInt(limit, 10) === 30,
                attrs   : ' style="width: 100%"'
            }) +
            fR({
                name  : 'limit',
                label : '50',
                value : 50,
                checked : parseInt(limit, 10) === 50,
                attrs : ' style="width: 100%"'
            })
        );

        // register radio buttons
        Array.prototype.slice.call(this.containerEl.querySelectorAll('label.mdl-radio')).forEach(c => {
            this.cHU(c);
        }, this);

        this.setButtons([{
            text: a24n('Filter'),
            cls: this.buttonsCls + ' mdl-button--raised',
            icon: Svgs.FILTER,
            scope: this,
            handler: this.onFilter.createDelegate(this, [true], 0)
        }, {
            text: a24n('Close'),
            cls: this.buttonsCls + ' mdl-button--raised',
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onFilter.createDelegate(this, [false], 0)
        }]);
    }

    /**
     * Collect filter values
     *
     * @private
     */
    applyFilter() {
        this.limit = this.getEl('input[name="limit"]:checked').value;
        this.filter = [{field: 'type', value: this.getEl('input[name="type"]:checked').value}];
    }

    /**
     * Executed on list item click
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} li Clicked HTML element (li)
     * @param {Object} dO Original list item data
     * @param {Object} dR Rendered list item data
     */
    // eslint-disable-next-line no-unused-vars
    onItemClick(e, li, dO, dR) {
        // load folder file list or go up to parent folder
        if (dO.type === 'folder') {
            if (dO.name === '..') {
                // Removing all list items
                while (this.listEl.firstChild) {
                    this.listEl.removeChild(this.listEl.firstChild);
                }

                this.folderLevel--;
                this.addItem(this.folderLevelData[this.folderLevel]);
            }
            else {
                this.showMask();

                console.log('LiveFilemanagerWindow::Fetching folder files (list)');
                Connection.sendMessage(Constants.REQ_GET_FOLDER_FILES, {
                    type : 'list',
                    path : dO.path
                }, this.onGetFolderFiles, this);
            }
        }
        // open file
        else if (! Utils.isEmpty(dO.path)) {
            console.log('LiveFilemanagerWindow::Fetching download link for: ' + dO.path);
            Connection.sendMessage(Constants.REQ_GET_FILE_URL, {
                id   : dO.id,
                path : dO.path,
                name : dO.name,
                type : dO.contenttype === 'application/pdf' ? 'preview' : 'get'
            }, this.onGetFileUrl, this);
        }
    }

    /**
     * Executed after folder list is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetFolderFiles(conn, msg) {
        var d = JSON.parse(msg.data);

        // handle only of request is for list
        if (d.type === 'list') {
            // Removing all list items
            while (this.listEl.firstChild) {
                this.listEl.removeChild(this.listEl.firstChild);
            }

            this.folderLevel++;
            this.folderLevelData[this.folderLevel] = d.data;

            this.addItem([]
                .concat(this.enableParentFolder ? [{
                    name: '..',
                    type: 'folder'
                }] : [])
                .concat(this.folderLevelData[this.folderLevel]));

            this.hideMask();
        }
    }

    /**
     * Executed after file url is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetFileUrl(conn, msg) {
        var d = JSON.parse(msg.data);

        if (d.type === 'preview') {
            window.open(d.url, '_blank', 'toolbar=0,location=0,menubar=0,status=0,titlebar=0');
        } else {
            Utils.download(d.url, d.name);
        }
    }

    /**
     * Executed on list action click
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} li Clicked HTML list element (li)
     * @param {HTMLElement} btn Clicked HTML action button element (button)
     * @param {Object} dO Original list item data
     * @param {Object} dR Rendered list item data
     * @param {Object} a Action configuration
     */
    // eslint-disable-next-line no-unused-vars
    onActionClick(e, li, btn, dO, dR, a) {
        e.stopPropagation();

        console.log('LiveFilemanagerWindow::onActionClick - action: ' + a.type);

        switch (a.type)
        {
        case 'auth':
            var opt = dO.conf.options;
            if (this.authTypes.hasOwnProperty(opt.authorization)) {
                // close any opened auth window
                var ew = CmpMgr.getByXtype(this.authTypes[opt.authorization].prototype.xtype);
                if (ew) {
                    ew.hide(true);
                }

                new this.authTypes[opt.authorization]({
                    folder: dO,
                    onSuccess: this.onConfirmSuccess.createDelegate(this)
                });
            }
            else {
                console.warn('LiveFilemanagerWindow::onActionClick - auth type ' + opt.authorization + ' not available');
            }
            break;

        case 'download':
            // hide download button and show mdl spinner
            btn.style.display = 'none';
            btn.insertAdjacentHTML('afterend', Utils.getMdlSpinner('margin-left: 5px; vertical-align: middle;'));

            var sp = btn.parentNode.querySelector('.mdl-spinner');
            this.cHU(sp);

            Connection.sendMessage(Constants.REQ_GET_FOLDER_FILES, {
                type : 'download',
                path : dO.path
            }, (conn, msg) => {
                var d = JSON.parse(msg.data);
                Utils.download(Asseco.config.ChatServerUrl + '/server/chat-download.php?disposition=attachment&downloadKey=' + d.data, dO.name + '.zip');

                // remove mdl spinner and show download button again
                Element.removeNode(sp);
                btn.style.display = 'inline-block';
            }, this);
            break;

        default:
            console.warn('LiveFilemanagerWindow::onActionClick - action click type not defined');
        }

    }

    /**
     * Executed when authorization with defined adapter succeeded
     *
     * @param {String} m Message
     * @param {Object} f Folder
     * @private
     */
    onConfirmSuccess(m, f) {
        // add view buttom to dialog text
        if (f) {
            var uuidP = 'btn-' + Utils.generateUUID(5, 'id_');
            m += ' <button id="' + uuidP + '" class="mdl-button mdl-button--icon">' +
                    Utils.getIconMarkup(Svgs.FILE, '', '', '#000') +
                '</button>';
        }

        // show notification
        var d = Utils.dialog('', m);

        if (f) {
            // this.tips.push(Utils.tooltip(uuidP, a24n('View')));
            document.querySelector('#' + uuidP).onclick = (e) => {
                // close current dialog
                if (d && d.hasAttribute('open')) {
                    d.close();
                }

                // load signed document in opened filemanager window
                this.enableParentFolder = false;
                this.setTools([{
                    icon: Svgs.CLOSE,
                    scope: this,
                    handler: this.onClose
                }]);
                this.onItemClick(e, e.target, f);

                e.preventDefault();
            };
        }

        this.fetchList();
    }
}
LiveFilemanagerWindow.prototype.xtype = 'LiveFilemanagerWindow';
export default LiveFilemanagerWindow;
