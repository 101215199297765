import Utils from 'util/Utils';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE Survey Preview Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveSurveyPreviewWindow extends LiveCard {
    /**
     * Survey object
     *
     * @type {Object} survey
     */
    survey;

    /**
     * Array of button objects
     *
     * @private {Array} buttons
     */
    buttons;

    /**
     * Is servey saved in iframe
     *
     * @private {Boolean} assecoSurveyFinished
     */
    assecoSurveyFinished;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-survey-preview-window',
            title          : a24n('Preview'),
            destroyOnClose : true,
            width          : Math.min(600, window.innerWidth - 100) + 'px',
            height         : window.innerHeight - 50 + 'px'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        // add close button
        this.buttons = [{
            text: a24n('Close'),
            scope: this,
            handler: () => {
                if (this.assecoSurveyFinished) {
                    this.onSurveySuccess();
                } else {
                    this.hide(true);
                }
            }
        }];

        // attach listener for checking message send from iframe in which survey is loaded
        window.addEventListener('message', (e) => {
            if (e.data === 'AssecoSurveyFinished') {
                this.assecoSurveyFinished = true;
            } else if (e.data === 'AssecoSurveySuccess') {
                this.onSurveySuccess();
            }
        });

        super.initComponent();
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Preview.scss');
    }

    /**
     * Return content for card
     *
     * @return {String}
     * @private
     */
    getContentHtml() {
        return require('babel-loader!template-string-loader!./Preview.html')({
            url: this.survey.publicLink
        });
    }

    /**
     * Called after survey loaded in iframe called global function that survey is saved
     *
     * @param {Object} msg
     * @private
     */
    onSurveySuccess(msg) {
        // call onSuccess callback if defined
        if (this.onSuccess) {
            this.onSuccess(msg);
        }
    }
}
LiveSurveyPreviewWindow.prototype.xtype = 'LiveSurveyPreviewWindow';
export default LiveSurveyPreviewWindow;
