module.exports=function(scope){ return `<div class="asseco-rds-cert-confirm-wrapper">
    <div class="asseco-list">
        <ul class="mdl-list">
            <li class="mdl-list__item mdl-list__item--two-line">
                <span class="mdl-list__item-primary-content">
                    <span>${scope.typeLabel}</span>
                    <span class="mdl-list__item-sub-title">${scope.type}</span>
                </span>
            </li>
            <li class="mdl-list__item mdl-list__item--two-line">
                <span class="mdl-list__item-primary-content">
                    <span>${scope.subjectLabel}</span>
                    <span class="mdl-list__item-sub-title">${scope.subject}</span>
                </span>
            </li>
            <li class="mdl-list__item mdl-list__item--two-line">
                <span class="mdl-list__item-primary-content">
                    <span>${scope.subjectAltLabel}</span>
                    <span class="mdl-list__item-sub-title">${scope.subjectAlt}</span>
                </span>
            </li>
            <li class="mdl-list__item mdl-list__item--two-line">
                <span class="mdl-list__item-primary-content">
                    <span>${scope.issuerLabel}</span>
                    <span class="mdl-list__item-sub-title">${scope.issuer}</span>
                </span>
            </li>
            <li class="mdl-list__item mdl-list__item--two-line">
                <span class="mdl-list__item-primary-content">
                    <span>${scope.validToLabel}</span>
                    <span class="mdl-list__item-sub-title">${scope.validTo}</span>
                </span>
            </li>
        </ul>
    </div>

    <div style="padding-top: 20px">${scope.msgPass}</div>

    ${scope.pass}
    ${scope.passr}

    <div style="padding-top: 20px">${scope.msgRevokePass}</div>

    ${scope.revokePass}
    ${scope.revokePassr}

    <div style="padding-top: 75px;">${scope.msgOtp}</div>

    ${scope.otp}

    <div id="${scope.errTxtId}" class="mdl-textfield asseco-rds-fRight" style="padding: 0;">
        <span class="mdl-textfield__error"></span>
    </div>

    ${scope.info}

    ${scope.accept}
</div>`};