import 'util/Function';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveVideoChatWindowIM from 'components/videochat/window/WindowIM';
import LiveChatAudio from './Audio';

/**
 * LIVE Chat Winow Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveAudioChatWindowIM extends LiveVideoChatWindowIM {
    /**
     * Holds the reference to window video component
     *
     * @private {LiveChatAudio} windowStream
     */
    windowStream;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id           : 'asseco-audiochat-window',
            title        : a24n('Agent') + ': ' + config.agent,
            drawer       : true,
            videoEnabled : false
        });

        // modify default window size if we are not using fixed drawer
        if (config.drawerFixed === false && ! config.hasOwnProperty('width')) {
            config.width = '320px';
        }

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Create window for stream
     * @private
     */
    createStreamWindow() {
        console.log('LiveAudioChatWindow::createStreamWindow');
        this.windowStream = new LiveChatAudio({
            renderTo: this.getEl('.mdl-layout__content')
        });
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // disable LiveChat, LiveVideoChat
        CmpMgr.updateComponents(['LiveChat', 'LiveVideoChat'], 'disable');

        // enable LiveAudioChat (for maximizing), LiveCobrowsing, LiveScreenShare
        CmpMgr.updateComponents(['LiveAudioChat', 'LiveCobrowsing', 'LiveScreenShare'], 'enable');
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        super.beforeDestroy();

        // enable LiveChat, LiveAudioChat, LiveVideoChat
        CmpMgr.updateComponents(['LiveChat', 'LiveAudioChat', 'LiveVideoChat'], 'enable');

        // disable LiveCobrowsing, LiveScreenShare
        CmpMgr.updateComponents(['LiveCobrowsing', 'LiveScreenShare'], 'disable');

        return true;
    }
}
LiveAudioChatWindowIM.prototype.xtype = 'LiveAudioChatWindow';
export default LiveAudioChatWindowIM;
