import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveFab from 'widgets/fab/Fab';
import LiveCallbackWindow from './window/Window';

/**
 * LIVE Callback Component - xtype: LiveCallback - (available in Asseco namespace) <br/>
 * Component is used for making callback request in LIVE
 *
 * @example
 * var aCallCh = new Asseco.LiveCallback({
 *     deferShow: 500,
 *     position: {
 *         position: 'absolute',
 *         top: '255px',
 *         right: '25px'
 *     },
 *     icon: 'custom icon - SVG path or IMG src',
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 * });
 */
class LiveCallback extends LiveFab {
    /**
     * Holds configuration object for main window {@link LiveComponent}
     *
     * @type {Object} winCfg
     */
    winCfg;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier : 'callback',
            label      : a24n('Request callback'),
            icon       : 'M18 11l5-5-5-5v3h-4v4h4v3zm2 4.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onClick(event) {
        if (this.disabled) {
            return;
        }

        new LiveCallbackWindow(this.winCfg || {});
    }
}
LiveCallback.prototype.xtype = 'LiveCallback';
CmpMgr.registerXtype(LiveCallback.prototype.xtype, LiveCallback);
export default LiveCallback;
