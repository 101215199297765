import Svgs from 'data/Svgs';
import Utils from 'util/Utils';
import LiveComponent from 'widgets/Component';

/**
 * LIVE Fab Component (available in Asseco namespace) <br/>
 * Component is used for creating floatable FAB button
 *
 * @example
 * var aFab = new Asseco.LiveFab({
 *     identifier: 'example',
 *     label: 'Example FAB',
 *     icon: 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z',
 *     cls: 'extra-example-css-class',
 *     position: {
 *         position: 'absolute',
 *         top: '25px',
 *         right: '25px'
 *     },
 *     onClick: function (event) {
 *         alert('Fab clicked');
 *     }
 * });
 */
class LiveFab extends LiveComponent {
    /**
     * Specify FAB identifier
     *
     * @type {String} identifier
     */
    identifier;

    /**
     * Specify FAB icon (Image path, embeded data uri or path for SVG)
     *
     * @type {String} icon
     */
    icon;

    /**
     * Specify FAB label
     *
     * @type {String} label
     */
    label;

    /**
     * Set to true to tigger FAB auto click
     *
     * @type {Boolean} autoInit
     */
    autoInit;

    /**
     * Holds the reference to component's container Element
     *
     * @private {HTMLElement} containerEl
     */
    containerEl;

    /**
     * Holds loaded HTML template for this component
     *
     * @private {String} template
     */
    template;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            renderTo   : config.isPlugin ? null : document.body,
            position   : {
                position: 'absolute',
                bottom: '10px',
                right: '10px'
            },
            id         : config.isPlugin ? ('asseco-' + config.identifier + '-plugin') : ('asseco-' + config.identifier),
            icon       : config.icon || Svgs.ADD,
            deferShow  : 1,
            showAnim   : 'asseco-bounceIn'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Fab.scss');
    }

    /**
     * Load html template for this component
     *
     * @return {String} Loaded HTML template
     * @private
     */
    getTemplate() {
        if (! this.template) {
            let tplData = this.getTemplateData();
            if (this.isPlugin) {
                // if this component is used as plugin render method is not called so require style here
                this.getStyle();
                this.template = require('babel-loader!template-string-loader!./FabListItem.html')(tplData);
            } else {
                this.template = require('babel-loader!template-string-loader!./Fab.html')(tplData);
            }
        }

        return this.template;
    }

    /**
     * Get data for for component template
     *
     * @return {Object} Data that will be applied to this component HTML template
     * @private
     */
    getTemplateData() {
        return {
            id    : this.id,
            label : this.label || '',
            icon  : Utils.getIconMarkup(this.icon, 'asseco-' + this.identifier + '-floaty-icon-start asseco-absolute-center')
        };
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // needed if component is used as plugin
        if (! this.containerEl) {
            this.containerEl = document.getElementById(this.id);
        }

        // add tooltip for FAB
        this.tips.push(Utils.tooltip(this.id, this.label, 'left'));

        // add click event to this component main Element
        this.containerEl.addEventListener('click', this.onClick.createDelegate(this), false);

        // trigger auto click if configured
        if (this.autoInit) {
            this.onClick();
        }
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     */
    // eslint-disable-next-line no-unused-vars
    onClick(event) {
        if (this.disabled) {
            return;
        }

        // check for MW
        this.showMW(Utils.getFuncName(this.__proto__.xtype, 'onClick'));
    }
}
LiveFab.prototype.xtype = 'LiveFab';
export default LiveFab;
