import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveFab from 'widgets/fab/Fab';
import LiveMeetingWindow from './window/Window';

/**
 * LIVE Meeting Component - xtype: LiveMeeting - (available in Asseco namespace) <br/>
 * Components is used for arranging meeting in LIVE
 *
 * @example
 * var aMeeting = new Asseco.LiveMeeting({
 *     deferShow: 500,
 *     position: {
 *         position: 'absolute',
 *         top: '125px',
 *         right: '25px'
 *     },
 *     icon: 'custom icon - SVG path or IMG src',
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 * });
 */
class LiveMeeting extends LiveFab {
    /**
     * Holds configuration object for main window {@link LiveComponent}
     *
     * @type {Object} winCfg
     */
    winCfg;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier : 'meeting',
            label      : a24n('Request meeting'),
            icon : 'M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onClick(event) {
        if (this.disabled) {
            return;
        }

        new LiveMeetingWindow(this.winCfg || {});
    }
}
LiveMeeting.prototype.xtype = 'LiveMeeting';
CmpMgr.registerXtype(LiveMeeting.prototype.xtype, LiveMeeting);
export default LiveMeeting;
