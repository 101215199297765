if (! Function.prototype.createSequence) {
    /**
     * Create a combined function call sequence of the original function + the passed function.
     * The resulting function returns the results of the original function.
     * The passed fcn is called with the parameters of the original function.
     *
     * @param {Function} fcn The function to sequence
     * @param {Object} s (optional) The scope (<code><b>this</b></code> reference) in which the passed function is executed.
     * <b>If omitted, defaults to the scope in which the original function is called or the browser window.</b>
     * @return {Function} The new function
     */
    Function.prototype.createSequence = function(fcn, s) {
        var method = this;
        return (typeof fcn != 'function') ?
            this :
            function(){
                var retval = method.apply(this || window, arguments);
                fcn.apply(s || this || window, arguments);
                return retval;
            };
    };
}

if (! Function.prototype.createDelegate) {
    /**
     * Creates a delegate (callback) that sets the scope to obj.
     * Call directly on any function. Example: <code>this.myFunction.createDelegate(this, [arg1, arg2])</code>
     * Will create a function that is automatically scoped to obj so that the <tt>this</tt> variable inside the
     * callback points to obj.
     *
     * @param {Object} scope (optional) The scope (<code><b>this</b></code> reference) in which the function is executed.
     * <b>If omitted, defaults to the browser window.</b>
     * @param {Array} args (optional) Overrides arguments for the call. (Defaults to the arguments passed by the caller)
     * @param {Boolean/Number} aArgs (optional) if True args are appended to call args instead of overriding,
     * if a number the args are inserted at the specified position
     * @return {Function} The new function
     */
    Function.prototype.createDelegate = function(obj, args, aArgs) {
        var method = this;
        return function() {
            var callArgs = args || arguments;
            if (aArgs === true) {
                callArgs = Array.prototype.slice.call(arguments, 0);
                callArgs = callArgs.concat(args);
            } else if (typeof aArgs === 'number') {
                callArgs = Array.prototype.slice.call(arguments, 0); // copy arguments first
                var applyArgs = [aArgs, 0].concat(args); // create method call params
                Array.prototype.splice.apply(callArgs, applyArgs); // splice them in
            }
            return method.apply(obj || window, callArgs);
        };
    };
}

if (! Function.prototype.defer) {
    /**
     * Calls this function after the number of millseconds specified, optionally in a specific scope. Example usage:
     *
     * @param {Number} ms The number of milliseconds for the setTimeout call (if less than or equal to 0 the function is executed immediately)
     * @param {Object} scope (optional) The scope (<code><b>this</b></code> reference) in which the function is executed.
     * <b>If omitted, defaults to the browser window.</b>
     * @param {Array} args (optional) Overrides arguments for the call. (Defaults to the arguments passed by the caller)
     * @param {Boolean/Number} append (optional) if True args are appended to call args instead of overriding,
     * if a number the args are inserted at the specified position
     * @return {Number} The timeout id that can be used with clearTimeout
     */
    Function.prototype.defer = function(ms, obj, args, append) {
        var fn = this.createDelegate(obj, args, append);
        if (ms > 0) {
            return setTimeout(fn, ms);
        }
        fn();
        return 0;
    };
}
