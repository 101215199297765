import Utils from 'util/Utils';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE Chat Consent Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveChatConsentWindow extends LiveCard {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-chat-consent-window',
            title          : a24n('Consent'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        this.buttons = [{
            text: a24n('Continue'),
            scope: this,
            handler: this.onContinue.createDelegate(this)
        }, {
            text: a24n('Cancel'),
            scope: this,
            handler: this.onCancel.createDelegate(this)
        }];

        super.initComponent();
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Consent.scss');
    }

    /**
     * Return content for card
     *
     * @return {String}
     * @private
     */
    getContentHtml() {
        return require('babel-loader!template-string-loader!./Consent.html')({
            message: a24n('Conversation will be recorded in order to enhance the chat service.<br/><br/>If you consent, please click Continue.')
        });
    }

    /**
     * Executed on consent accept
     *
     * @private
     */
    onContinue() {
    }

    /**
     * Executed on consent deny
     *
     * @private
     */
    onCancel() {
        this.hide(true);
    }
}
LiveChatConsentWindow.prototype.xtype = 'LiveChatConsentWindow';
export default LiveChatConsentWindow;
