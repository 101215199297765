/* global getmdlSelect */

import Utils from 'util/Utils';
import LiveField from 'widgets/field/Field';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE Callback Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveCallbackWindow extends LiveCard {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id            : 'asseco-callback-window',
            title          : a24n('Callback'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        this.buttons = [{
            text: a24n('Submit'),
            scope: this,
            handler: this.onSaveCallback.createDelegate(this)
        }];

        super.initComponent();
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Return content for card
     *
     * @private
     */
    getContentHtml() {
        var tF = LiveField.getTextField,
            tS = LiveField.getSelectField;

        return require('babel-loader!template-string-loader!./Window.html')({
            fSubject : tS({
                id    : 'asseco-callback-group',
                label : a24n('Subject'),
                value : 'Savings',
                items: ['Accounts', 'Savings']
            }),

            fDate : tF({
                id       : 'asseco-callback-date',
                label    : a24n('Time for callback'),
                required : true
            }),

            fNum : tF({
                id       : 'asseco-callback-number',
                label    : a24n('Telephone number'),
                required : true
            })
        });
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // register dynamic material design components
        getmdlSelect.init('#asseco-callback-group');

        this.cHU(this.getEl('#asseco-callback-date'));
        this.cHU(this.getEl('#asseco-callback-number'));
    }

    /**
     * Executed on callback save
     *
     * @private
     */
    onSaveCallback() {
    }
}
LiveCallbackWindow.prototype.xtype = 'LiveCallbackWindow';
export default LiveCallbackWindow;
