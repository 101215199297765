import Svgs from 'data/Svgs';
import Connection from 'data/Connection';
import 'util/Function';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveFab from 'widgets/fab/Fab';
import LiveFilemanagerWindow from './window/Window';

/**
 * LIVE Filemanager Component - xtype: LiveFilemanager - (available in Asseco namespace) <br/>
 * Components is used for viewing and signing documents
 *
 * Available on private web base for existing customers
 */
class LiveFilemanager extends LiveFab {
    /**
     * Holds configuration object for main window {@link LiveComponent}
     *
     * @type {Object} winCfg
     */
    winCfg;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config) {
        config = config || {};

        // apply default config if not specified
        Utils.applyIf(config, {
            identifier : 'filemanager',
            label      : a24n('Filemanager'),
            icon       : Svgs.FILE
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // get files list but don't show filemanager window
        // this.onClick({showWin: false});
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     * @private
     */
    onClick(event) {
        if (this.disabled) {
            return;
        }

        // by default show filemanager window
        var showWin = event.hasOwnProperty('showWin') ? event.showWin : true;

        // if web socket connection is not opened we need to establish connection first
        if (Connection.getStatus() !== Connection.OPEN) {
            // add callback for web socket connection open
            Connection.addCallback(this, 'open', this.onConnectionOpen.createDelegate(this, [showWin]), this);

            // renew user UUID
            Asseco.UUID = Utils.generateUUID();
            console.log('LiveFilemanager::Regenerate Asseco UUID: ' + Asseco.UUID);

            Connection.wsUrl = null;
            Connection.targetEl = this.containerEl;
            Connection.connect();
        }
        else {
            this.onConnectionOpen(showWin);
        }
    }

    /**
     * Executed on opening web socket connection
     *
     * @param {Boolean} showWin If window with file list sholud be shown after web socket open
     * @private
     */
    onConnectionOpen(showWin) {
        // show filemanager list if not already rendered
        if (! CmpMgr.hasByXtype(LiveFilemanagerWindow.prototype.xtype)) {
            console.log('LiveFilemanager::onConnectionOpen - show filemanager window');

            // remove callback for web socket connection open
            Connection.removeCallback(this, 'open');

            // open filemanager list window
            var win = new LiveFilemanagerWindow(Utils.applyIf({
                renderHidden: true
            }, this.winCfg || {}));

            if (showWin) {
                win.show();
            }
        }
        else if (showWin) {
            CmpMgr.getByXtype(LiveFilemanagerWindow.prototype.xtype).show();
        }
    }
}
LiveFilemanager.prototype.xtype = 'LiveFilemanager';
CmpMgr.registerXtype(LiveFilemanager.prototype.xtype, LiveFilemanager);
export default LiveFilemanager;
