/**
 * Element utility class
 *
 * @private
 */
class Element {
    /**
     * @private
     */
    static trimRe = /^\s+|\s+$/g;

    /**
     * @private
     */
    static spacesRe = /\s+/;

    /**
     * @private
     */
    static cssRe = /([a-z0-9-]+)\s*:\s*([^;\s]+(?:\s*[^;\s]+)*);?/gi;

    /**
     * Applies a style specification to an element.
     *
     * @param {String|HTMLElement} el The element to apply styles to
     * @param {String|Object} s A style specification string e.g. 'width:100px', or object in the form {width:'100px'}
     * @return {HTMLElement} HTML element with applied style
     */
    static applyStyles(el, s) {
        if (el && s) {
            var matches;

            el = typeof el === 'string' ? document.getElementById(el) : el;
            if (typeof s === 'string') {
                /**
                 * Since we're using the g flag on the regex, we need to set the lastIndex.
                 * This automatically happens on some implementations, but not others, see:
                 * http://stackoverflow.com/questions/2645273/javascript-regular-expression-literal-persists-between-function-calls
                 * http://blog.stevenlevithan.com/archives/fixing-javascript-regexp
                 */
                Element.cssRe.lastIndex = 0;
                while ((matches = Element.cssRe.exec(s))) {
                    Element.setStyle(el, matches[1], matches[2]);
                }
            } else if (typeof s === 'object') {
                Element.setStyle(el, s);
            }
        }
        return el;
    }

    /**
     * Wrapper for setting style properties, also takes single object parameter of multiple styles.
     *
     * @param {HTMLElement} el The element to set styles to
     * @param {String|Object} p The style property to be set, or an object of multiple styles.
     * @param {String} v (optional) The value to apply to the given property, or null if an object was passed.
     * @return {HTMLElement} HTML element with style set
     */
    static setStyle(el, p, v) {
        if (typeof p !== 'object') {
            let tmp = {};
            tmp[p] = v;
            p = tmp;
        }

        for (let s in p) {
            v = p[s];
            el.style[s] = v;
        }
        return el;
    }

    /**
     * Checks if the specified CSS class exists on this element's DOM node.
     *
     * @param {HTMLElement} el The element for checking CSS class existance
     * @param {String} c The CSS class to check for
     * @return {Boolean} True if the class exists, else false
     */
    static hasClass(el, c) {
        return c && (' ' + el.className + ' ').indexOf(' ' + c + ' ') !== -1;
    }

    /**
     * Replaces a CSS class on the element with another. If the old name does not exist, the new name will simply be added.
     *
     * @param {HTMLElement} el The element for replacing CSS class
     * @param {String} oC The CSS class to replace
     * @param {String} nC The replacement CSS class
     * @return {HTMLElement} el HTML element with replaced CSS class
     */
    static replaceClass(el, oC, nC) {
        Element.removeClass(el, oC);
        Element.addClass(el, nC);

        return el;
    }

    /**
     * Removes one or more CSS classes from the element.
     *
     * @param {HTMLElement} el The element for removing CSS class from
     * @param {String|Array} c The CSS class to remove, or an array of classes
     * @return {HTMLElement} el HTML element with removed class
     */
    static removeClass(el, c) {
        var i, idx, len, cls, elClasses;

        if (! Array.isArray(c)) {
            c = [c];
        }
        if (el && el.className) {
            elClasses = el.className.replace(Element.trimRe, '').split(Element.spacesRe);
            for (i = 0, len = c.length; i < len; i++) {
                cls = c[i];
                if (typeof cls === 'string') {
                    cls = cls.replace(Element.trimRe, '');
                    idx = elClasses.indexOf(cls);
                    if (idx !== -1) {
                        elClasses.splice(idx, 1);
                    }
                }
            }
            el.className = elClasses.join(' ');
        }
        return el;
    }

    /**
     * Adds one or more CSS classes to the element. Duplicate classes are automatically filtered out.
     *
     * @param {HTMLElement} el The element for adding CSS class
     * @param {String|Array} c The CSS class to add, or an array of classes
     * @return {HTMLElement} el HTML element with added CSS class
     */
    static addClass(el, c) {
        var i, len, v, cls = [];

        // Separate case is for speed
        if (! Array.isArray(c)) {
            if (typeof c === 'string' && ! Element.hasClass(el, c)) {
                el.className += ' ' + c;
            }
        }
        else {
            for (i = 0, len = c.length; i < len; i++) {
                v = c[i];
                if (typeof v === 'string' && (' ' + el.className + ' ').indexOf(' ' + v + ' ') === -1) {
                    cls.push(v);
                }
            }
            if (cls.length) {
                el.className += ' ' + cls.join(' ');
            }
        }
        return el;
    }

    /**
     * Put HTML element to full screen (if document is already in full screen then exit full screen)
     *
     * @param {HTMLElement} el The element for put to full screen
     */
    static fullScreen(el) {
        // exit from full screen
        var d = document;
        if (d.fullscreenElement || d.webkitFullscreenElement || d.mozFullScreenElement || d.msFullscreenElement) {
            if (d.exitFullscreen) {
                d.exitFullscreen();
            } else if (d.mozCancelFullScreen) {
                d.mozCancelFullScreen();
            } else if (d.webkitExitFullscreen) {
                d.webkitExitFullscreen();
            } else if (d.msExitFullscreen) {
                d.msExitFullscreen();
            }

            return;
        }

        // enter element to full screen mode
        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        }
    }

    /**
     * Removes this element from the document, removes all DOM event listeners
     *
     * @param {HTMLElement} el The element to remove
     */
    static removeNode(el) {
        if (el && el.parentNode && el.tagName !== 'BODY') {
            // remove all listeners by cloning element
            var oldEl = el,
                newEl = el.cloneNode(true);

            oldEl.parentNode.replaceChild(newEl, oldEl);

            // remove element
            try {
                oldEl.parentNode.removeChild(oldEl);
            } catch (e) {
                //
            }

            try {
                newEl.parentNode.removeChild(newEl);
            } catch (e) {
                //
            }
        }
    }

    /**
     * Creates custom event
     *
     * @param {String} e The name of custom event
     * @param {Object} d Optional and defaulting to null, of type any, that is an event-dependent value associated with the event.
     * @return {CustomEvent} Created CustomEvent
     */
    static createEvent(e, d) {
        var ev;
        if (document.createEvent) {
            ev = document.createEvent('Event');
            ev.initCustomEvent(e, true, true, d || {});
        }
        else {
            ev = new CustomEvent(e, d || {});
        }

        return ev;
    }

    /**
     * Set element disable property to true
     *
     * @param {String} el Id of the element to set disabled property
     */
    static disabledElement(el) {
        document.getElementById(el).disabled = true;
    }

    /**
     * Set element disable property to false
     *
     * @param {String} el Id of the element to set disabled property
     */
    static enabledElement(el) {
        document.getElementById(el).disabled = false;
    }

    /**
     * Add badge to HTML Element element
     *
     * @param {HTMLElement} el The element for adding badge
     * @param {String} t Badge text
     * @param {Boolean} fo Use full overlap
     * @param {String} c Badge color (css class must exists e.g.: mdl-badge--red)
     */
    static addBadge(el, t, fo, c) {
        Element.removeBadge(el);

        var cls = 'mdl-badge mdl-badge--overlap';
        if (fo) {
            cls += ' mdl-badge--onel';
        }
        if (cls) {
            cls += ' mdl-badge--' + c;
        }
        Element.addClass(el, cls);
        el.setAttribute('data-badge', t);
    }

    /**
     * Remove badge from HTML element
     *
     * @param {HTMLElement} el The element for removing badge
     */
    static removeBadge(el) {
        Element.removeClass(el, ['mdl-badge', 'mdl-badge--overlap', 'mdl-badge--onel']);
        el.removeAttribute('data-badge');
    }

    /**
     * Make element draggable
     *
     * @param {String} h Handle CSS selector
     * @param {String} t Target CSS selector
     */
    static makeDraggable(h, t) {
        try {
            // object to be moved
            var dragObj = null;

            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0, rect;
            var hEl = document.querySelector(h);

            hEl.addEventListener('mousedown', startDrag, true);
            hEl.addEventListener('click', toFront, true);
        } catch (e) {
            // ignore error
            return;
        }

        // sets offset parameters and starts listening for mouse-move
        function startDrag (e) {
            if (! dragObj) {
                dragObj = document.querySelector(t);
            }
            dragObj.style.position = 'absolute';

            if (Element.hasClass(dragObj, 'asseco-margin-auto')) {
                let cR = dragObj.getBoundingClientRect();
                Element.removeClass(dragObj, 'asseco-margin-auto');

                dragObj.style.top = cR.top + 'px';
                dragObj.style.left = cR.left + 'px';
            }

            e = e || window.event;

            e.preventDefault();
            e.stopPropagation();

            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;

            document.addEventListener('mousemove', dragObject, true);
            document.addEventListener('mouseup', endDrag, true);
        }

        // bring element to front
        function toFront (e) {
            if (! dragObj) {
                dragObj = document.querySelector(t);
            }

            e = e || window.event;

            e.preventDefault();
            // e.stopPropagation();

            let CmpMgr = require('util/ComponentManager').default;
            CmpMgr.bringToFront(dragObj.id);
        }

        // Drag object
        function dragObject (e) {
            if (! dragObj) {
                return;
            }

            e = e || window.event;

            e.preventDefault();
            e.stopPropagation();

            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;

            rect = dragObj.getBoundingClientRect();

            // constrain top offset to window limit
            var t = dragObj.offsetTop - pos2;
            if (t < 0) {
                t = 0;
            } else if (t + rect.height > window.innerHeight) {
                t = window.innerHeight - rect.height;
            }
            dragObj.style.top = t + 'px';

            // constrain left offset to window limit
            var l = dragObj.offsetLeft - pos1;
            if (l < 0) {
                l = 0;
            } else if (l + rect.width > window.innerWidth) {
                l = window.innerWidth - rect.width;
            }
            dragObj.style.left = l + 'px';
        }

        // End dragging
        function endDrag () {
            if (dragObj) {
                dragObj = null;
                document.removeEventListener('mousemove', dragObject, true);
                document.removeEventListener('mouseup', endDrag, true);
            }
        }
    }
}
export default Element;
