/*global grecaptcha*/

import Utils from 'util/Utils';
import Connection from 'data/Connection';
import LiveField from 'widgets/field/Field';
import LiveCard from 'widgets/card/Card';

///////////////////////
/////////////////////////////
//////////

/**
 * LIVE ReCaptcha Card Component (not available in Asseco namespace)
 *
 * This component authenticate anonymouse user who needs to solve captcha
 *
 * @private
 */
class LiveReCaptcha extends LiveCard {
    /**
     * Holds reCaptcha type (standard/invisible)
     *
     * @type {String} type
     */
    type;

    /**
     * URL to Google ReCaptcha API URL
     *
     * @private {String} GOOGLE_API
     */
    static GOOGLE_API = 'https://www.google.com/recaptcha/api.js';

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-recaptcha',
            title          : a24n('ReCaptcha validation'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        this.buttons = [{
            text: a24n('Submit'),
            scope: this,
            handler: this.onValidateReCaptcha.createDelegate(this)
        }];

        super.initComponent();
    }

    /**
     * Return content for card
     *
     * @private
     */
    getContentHtml() {
        return require('babel-loader!template-string-loader!./ReCaptcha.html')({
            fName : LiveField.getTextField({
                id       : 'asseco-recaptcha-fullName',
                label    : a24n('Display Name'),
                value    : Asseco.displayName || '',
                required : true
            }),
            type : this.type,

            sitekey : Asseco.config.reCaptchaSitekey || ''
        });
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./ReCaptcha.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // register dynamic material design components
        this.cHU(this.getEl('#asseco-recaptcha-fullName'));

        // attach global callback for Google recaptcha
        window.AssecoReCaptchaCb = this.onValidateReCaptcha.createDelegate(this);

        // add Google recaptcha API script
        var po = document.createElement('script');
        po.type = 'text/javascript';
        po.async = true;
        po.src = LiveReCaptcha.GOOGLE_API;

        var elem = document.querySelector('script[nonce]');
        var nonce = elem && (elem['nonce'] || elem.getAttribute('nonce'));
        if (nonce) {
            po.setAttribute('nonce', nonce);
        }
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(po, s);
    }

    /**
     * Validate captcha
     *
     * @param {String} resp
     * @private
     */
    onValidateReCaptcha(resp) {
        var fullNameEl = this.getEl('#asseco-recaptcha-fullName-input'),
            fullNameVal = fullNameEl.value;

        // name is required before reCaptcha validation
        if (Utils.isEmpty(fullNameVal))  {
            fullNameEl.focus();
            fullNameEl.blur();
            return;
        }

        // if we have no reCaptcha response and we are using invisible reCaptcha, trigger validation
        if (typeof resp !== 'string' && this.type === 'invisible') {
            grecaptcha.execute();
            return;
        }

        if (typeof resp !== 'string') {
            resp = grecaptcha.getResponse();
        }

        if (Utils.isEmpty(resp)) {
            return;
        }

        var data = new FormData();
        data.append('recaptcha', resp);
        data.append('contextData', JSON.stringify(Asseco.contextData || {}));

        // Utils.toast(a24n('Verifying...'), 5000);
        fetch(Asseco.ChatServerUrl + '/authAdapters/reCaptcha/?validate&uuid=' + Asseco.UUID + '&displayName=' + fullNameVal, {
            method: 'post',
            body: data
        })
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                if (res.success) {
                    // Utils.toast(null);

                    // set display name to Asseco namespace
                    Asseco.displayName = fullNameVal;

///////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////
//////////////////////////////

                    console.log('LiveReCaptcha::Got ws url: ' + res.wsUrl);
                    Connection.wsUrl = res.wsUrl;
                    Connection.connect();
                    this.hide(true);
                }
                else {
                    grecaptcha.reset();
                }
            })
            .catch((err) => {
                // Utils.toast(String.format(a24n('Error fetching token: {0}'), err.message));
                Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }
}
LiveReCaptcha.prototype.xtype = 'LiveReCaptcha';
export default LiveReCaptcha;
