module.exports=function(scope){ return `<div class="asseco-rds-cert-user-wrapper">
    ${scope.fName}
    ${scope.lName}
    ${scope.adr}
    ${scope.pin}
    ${scope.docType}
    ${scope.docNo}
    ${scope.email}
    ${scope.mob}

    <div id="${scope.errTxtId}" class="mdl-textfield" style="padding: 0;">
        <span class="mdl-textfield__error"></span>
    </div>

    ${scope.accept}
</div>`};