/* global getmdlSelect */
import Svgs from 'data/Svgs';
import 'util/Function';
import Utils from 'util/Utils';
import Element from 'util/Element';
import LiveField from 'widgets/field/Field';
import LiveComponent from 'widgets/Component';
import Constants from 'data/Constants';
import Connection from 'data/Connection';

/**
 * LIVE Video Chat Window Video Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveVideochatWindowVideo extends LiveComponent {
    /**
     * Holds the reference to panel element
     *
     * @private {HTMLElement} panelEl
     */
    panelEl;

    /**
     * Holds the reference to media element holding local stream
     *
     * @private {HTMLElement} localMediaEl
     */
    localMediaEl;

    /**
     * Holds the reference to media element holding remote stream
     *
     * @private {HTMLElement} remoteMediaEl
     */
    remoteMediaEl;

    /**
     * Reference to window components (LiveVideoChatWindowIM or LiveVideoChatWindowFS)
     * 
     * @private {Object} windowCmp
     */
    windowCmp;

    /**
     * Current camera facing mode
     * 
     * @private {String} facingMode
     */
    facingMode;

    /**
     * 
     * Holds info about whether overlay frame is shown
     * 
     * @param {Boolean} config 
     */
    overlayFrameShown;

    canvasOutputEl;
    canvasOutputCtx;
    previousTextMetrics;

    /**
     *
     * Holds timestamp, timezone and timestamp interval
     */
    serverTimestamp;
    serverTimezone;
    timestampInterval;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id              : 'asseco-videochat-window-video-panel',
            cls             : 'asseco-videochat-window-video',
            closable        : false,
            destroyOnClose  : true,

            type            : 'video',
            elPrefix        : 'asseco-videochat-window-video-',
            facingMode      : 'user',

            screenBtnIcon   : Svgs.FULL_SCREEN,

            audioBtnOnIcon  : 'M18.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM5 9v6h4l5 5V4L9 9H5z',
            audioBtnOffIcon : 'M7 9v6h4l5 5V4l-5 5H7z',

            videoBtnOnIcon  : 'M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z',
            videoBtnOffIcon : 'M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z',

            flipCameraBtn   : '<path d="M9,12c0,1.66,1.34,3,3,3s3-1.34,3-3s-1.34-3-3-3S9,10.34,9,12z"/><path d="M8,10V8H5.09C6.47,5.61,9.05,4,12,4c3.72,0,6.85,2.56,7.74,6h2.06c-0.93-4.56-4.96-8-9.8-8C8.73,2,5.82,3.58,4,6.01V4H2v6 H8z"/><path d="M16,14v2h2.91c-1.38,2.39-3.96,4-6.91,4c-3.72,0-6.85-2.56-7.74-6H2.2c0.93,4.56,4.96,8,9.8,8c3.27,0,6.18-1.58,8-4.01V20 h2v-6H16z"/>',
            switchCameraBtn : 'M20 4h-3.17L15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 11.5V13H9v2.5L5.5 12 9 8.5V11h6V8.5l3.5 3.5-3.5 3.5z'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);

        Connection.sendMessage(Constants.REQ_GET_SERVER_TIMESTAMP, null, this.onGetServerTimestamp, this);
    }

    /**
     * Get timestamp from LiveChat Server
     *
     * @private
     */
    onGetServerTimestamp(conn, msg){
        var dataParse = JSON.parse(msg.data);
        this.serverTimestamp  = dataParse.serverTimestamp;
        this.serverTimezone = dataParse.serverTimezone;

        this.timestampInterval = setInterval(() => {
            this.serverTimestamp++;
        }, 1000);
    }

    beforeDestroy() {
        super.beforeDestroy();
        clearInterval(this.timestampInterval);

        return true;
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Video.scss');
    }

    /**
     * Get component template
     *
     * @return {String}
     * @private
     */
    getTemplate() {
        if (! this.template) {
            let supports = navigator.mediaDevices.getSupportedConstraints();

            if(Asseco.config.hasOwnProperty('manageButton')){
                let configSetup = Asseco.config.manageButton;

                this.template = require('babel-loader!template-string-loader!./Video.html')({
                    screenBtn       : configSetup['screenBtn'] === true,
                    screenBtnOn     : Utils.getIconMarkup(this.screenBtnIcon),
                    audioBtn        : configSetup['audioBtn'] === true,
                    audioBtnOn      : Utils.getIconMarkup(this.audioBtnOnIcon),
                    videoBtn        : configSetup['videoBtn'] === true,
                    videoBtnOn      : Utils.getIconMarkup(this.videoBtnOnIcon),
                    flipCamera      : Utils.isMobile() && supports['facingMode'] === true && configSetup['flipCameraBtn'] === true,
                    flipCameraBtn   : Utils.getIconMarkup(this.flipCameraBtn),
                    switchCamera    : ! Utils.isMobile() && configSetup['switchCameraBtn'] === true,
                    switchCameraBtn : Utils.getIconMarkup(this.switchCameraBtn)
                });
            } else {
                this.template = require('babel-loader!template-string-loader!./Video.html')({
                    screenBtn       : true,
                    screenBtnOn     : Utils.getIconMarkup(this.screenBtnIcon),
                    audioBtn        : true,
                    audioBtnOn      : Utils.getIconMarkup(this.audioBtnOnIcon),
                    videoBtn        : true,
                    videoBtnOn      : Utils.getIconMarkup(this.videoBtnOnIcon),
                    flipCamera      : Utils.isMobile() && supports['facingMode'] === true,
                    flipCameraBtn   : Utils.getIconMarkup(this.flipCameraBtn),
                    switchCamera    : ! Utils.isMobile(),
                    switchCameraBtn : Utils.getIconMarkup(this.switchCameraBtn)
                });
            }
        }
        return this.template;
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        var pf = this.elPrefix;
        this.panelEl = document.getElementById(pf + 'panel');
        this.localMediaEl = document.getElementById(pf + 'local');
        this.remoteMediaEl = document.getElementById(pf + 'remote');

        if (this.type === 'video') {
            this.screenBtn = this.panelEl.querySelector('button.screen-btn');
            if (this.screenBtn) {
                this.screenBtn.onclick = this.onScreenBtnClick.createDelegate(this);
            }

            this.videoBtn = this.panelEl.querySelector('button.video-btn');
            if (this.videoBtn) {
                this.videoBtn.onclick = this.onMuteBtnClick.createDelegate(this, ['video']);
            }

            this.flipCameraBtn = this.panelEl.querySelector('button.flip-camera-btn');
            if (this.flipCameraBtn) {
                this.flipCameraBtn.onclick = this.onFlipCameraBtnClick.createDelegate(this);
            }

            this.switchCameraBtn = this.panelEl.querySelector('button.switch-camera-btn');
            if (this.switchCameraBtn) {
                this.switchCameraBtn.onclick = this.onSwitchCameraBtnClick.createDelegate(this);
            }
        }

        this.audioBtn = this.panelEl.querySelector('button.audio-btn');
        if (this.audioBtn) {
            this.audioBtn.onclick = this.onMuteBtnClick.createDelegate(this, ['audio']);
        }

        this.showMask(a24n('Connecting...'));
    }

    /**
     * Show loading mask over card content
     *
     * @param {String} msg Mask message
     * @param {Boolean} s Show spinner (defaults to true)
     */
    showMask(msg, s) {
        if (! this.panelEl) {
            return;
        }

        // remove existing mask
        var m = this.panelEl.querySelector('div.asseco-mask');
        if (m) {
            Element.removeNode(m);
        }

        this.panelEl.insertAdjacentHTML('beforeend', require('babel-loader!template-string-loader!./../../../widgets/card/CardMask.html')({
            msg: msg || ''
        }));

        if (s === false) {
            Element.removeNode(this.panelEl.querySelector('div.mdl-spinner'));
        } else {
            // register dynamic material design components
            this.cHU(this.panelEl.querySelector('div.mdl-spinner'));
        }
    }

    /**
     * Hide loading mask over card content
     */
    hideMask() {
        if (! this.panelEl) {
            return;
        }

        var mask = this.getEl('.asseco-mask');
        if (mask) {
            mask.parentNode.removeChild(mask);
        }
    }

    /**
     * Executed on screen button click
     *
     * @private
     */
    onScreenBtnClick() {
        Element.fullScreen(this.panelEl);
    }

    /**
     * Executed on audio/video mute button click
     *
     * @param {String} type
     * @private
     */
    onMuteBtnClick(type) {
        console.log('onMuteBtnClick: ', type);

        var el = this[type + 'Btn'],
            action = Element.hasClass(el, 'muted') ? 'unmute' : 'mute',
            lStream = this.localMediaEl.srcObject;

        // do mute / unmute
        var tracks = type === 'audio' ? lStream.getAudioTracks() : lStream.getVideoTracks();
        if (tracks.length === 0) {
            return;
        }

        for (var i = 0; i < tracks.length; i++) {
            console.log(action +  ' track: ', tracks[i]);
            tracks[i].enabled = action !== 'mute';
        }

        // remove old icon and add new
        while (el.firstChild) {
            el.removeChild(el.firstChild);
        }
        el.insertAdjacentHTML('beforeend', Utils.getIconMarkup(this[type + 'Btn' + (action === 'mute' ? 'Off' : 'On') + 'Icon']));
        Element[action === 'mute' ? 'addClass' : 'removeClass'](el, 'muted');
    }

    /**
     * Executed on flip camera button click
     * @private
     */
    onFlipCameraBtnClick() {
        // set new facing mode
        this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
        console.log('LiveVideochatWindowVideo::onFlipCameraBtnClick - set facing mode: ' + this.facingMode);

        var videoConstraints = {
            facingMode: {
                exact: this.facingMode
            }
        };

        if (this.facingMode === 'environment') {
            if (Asseco.config.backCamera && Asseco.config.backCamera.videoConstraints) {
                Utils.applyIf(videoConstraints, Asseco.config.backCamera.videoConstraints);
            }
    
            // Default video constraints
            Utils.applyIf(videoConstraints, {
                width: 1280,
                height: 720
            });
        }

        this.replaceCamera({
            audio: true,
            video: videoConstraints
        });
    }

    /**
     * Executed on switch camera button click
     * @private
     */
    onSwitchCameraBtnClick() {
        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                var currentTrack = this.windowCmp.localStream.getVideoTracks()[0];
                var currentDevice = currentTrack.getSettings();

                var cameras = devices.filter((d) => {
                    return d.kind === 'videoinput' && d.deviceId !== currentDevice.deviceId;
                });

                // if there is only one additional camera and switchCameraAutoSelect is set use that camera
                if (cameras.length === 1 && this.windowCmp.switchCameraAutoSelect) {
                    this.replaceCamera({
                        audio: true,
                        video: {
                            deviceId: {
                                exact: cameras[0].deviceId
                            }
                        }
                    });
                // there are more additional cameras so show camera chooser
                } else {
                    var d = Utils.dialog(
                        a24n('Choose camera'),
                        LiveField.getSelectField({
                            id    : 'asseco-camera-select',
                            label : '',
                            value : currentTrack.label,
                            items: devices.filter((device) => {
                                return device.kind === 'videoinput';
                            }).map((d) => d.label)
                        }),
                        [{
                            text: a24n('Close'),
                            handler: (d) => d.close()
                        }]
                    );

                    document.querySelector('#asseco-camera-select input').onchange = (ev) => {
                        var newCamera = devices.filter((d) => {
                            return d.kind === 'videoinput' && d.deviceId !== currentDevice.deviceId && d.label === ev.target.value;
                        });
                        if (! Utils.isEmpty(newCamera)) {
                            console.log('LiveVideochatWindowVideo::onSwitchCameraBtnClick - selected camera:', newCamera[0]);
                            d.close();

                            this.replaceCamera({
                                audio: true,
                                video: {
                                    deviceId: {
                                        exact: newCamera[0].deviceId
                                    }
                                }
                            });
                        }
                    };

                    // register dynamic material design components
                    getmdlSelect.init('#asseco-camera-select');
                }
            })
            .catch((err) => {
                console.error('LiveVideochatWindowVideo::onSwitchCameraBtnClick - Error:', err);
            });

    }

    /**
     * Show timestamp in agent videochat
     * @private
     */
    showTimestamp() {

        var width = this.localMediaEl.videoWidth;
        var height = this.localMediaEl.videoHeight;

        // create output canvas
        this.canvasOutputEl = document.createElement('canvas');
        this.canvasOutputEl.width = width;
        this.canvasOutputEl.height = height;
        this.canvasOutputEl.style.position = 'fixed';
        this.canvasOutputEl.style.zIndex = 100;
        this.canvasOutputEl.style.right = '-3000px';
        this.canvasOutputEl.style.bottom = '-3000px';
        // canvasOutput.style.position = 'absolute';
        // canvasOutput.style.right = '10px';
        // canvasOutput.style.bottom = '10px';
        // canvasOutput.style.border = '1px solid white';
        document.getElementById('asseco-videochat-window-video-panel').appendChild(this.canvasOutputEl);
        this.canvasOutpuCtx = this.canvasOutputEl.getContext('2d');

        // set canvas as source of the stream
        const stream = this.canvasOutputEl.captureStream();
        const [videoTrack] = stream.getVideoTracks();

        const sender = this.windowCmp.webRtcPeerConnection.getSenders().find((s) => {
            return s.track.kind == videoTrack.kind;
        });
        sender.replaceTrack(videoTrack);

        this.drawTimeCanvas();
    }

    /**
     * Draw frame of video stream to canvas and add timestamp
     */
    drawTimeCanvas() {
        // draw video to input canvas
        this.canvasOutpuCtx.drawImage(this.localMediaEl, 0, 0, this.localMediaEl.videoWidth, this.localMediaEl.videoHeight);

        // clear the previously displayed text
        if (this.previousTextMetrics) {
            this.canvasOutpuCtx.clearRect((this.localMediaEl.videoWidth / 2) - 50, 25, this.previousTextMetrics.width, this.previousTextMetrics.height);
        }

        const now = new Date(this.serverTimestamp * 1000);
        const text = now.toLocaleDateString('hr-HR', {timeZone: this.serverTimezone}) + ' ' + now.toLocaleTimeString('hr-HR',{hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: this.serverTimezone});

        var widthBackground = this.canvasOutpuCtx.measureText(text).width;
        this.canvasOutpuCtx.fillStyle = 'rgb(95, 95, 95)';
        this.canvasOutpuCtx.fillRect((this.localMediaEl.videoWidth / 2) - 50, 5, widthBackground, 22);

        this.canvasOutpuCtx.fillStyle = 'white';
        this.canvasOutpuCtx.font = '20px Roboto';
        this.canvasOutpuCtx.fillText(text, (this.localMediaEl.videoWidth / 2) - 50, 25);

        this.previousTextMetrics = this.canvasOutpuCtx.measureText(text);

        if (this.windowCmp.connected) {
            requestAnimationFrame(this.drawTimeCanvas.createDelegate(this));
        }
    }

    /**
     * Replace Camera with given contrains
     * @param {Object} c 
     * @private
     */
    replaceCamera(c) {
        console.log('LiveVideochatWindowVideo::replaceCamera - constraints: ', c);

        // stop tracks of current local stream
        console.log('LiveVideochatWindowVideo::replaceCamera - stoping current local video tracks');
        this.windowCmp.localStream.getVideoTracks().forEach((track) => {
            track.stop();
        });

        navigator.mediaDevices
            .getUserMedia(c)
            .then((stream) => {
                this.windowCmp.localStream = stream;
                this.attachStream(stream, this.windowCmp.remoteStream);

                let videoTrack = stream.getVideoTracks()[0];
                var sender = this.windowCmp.webRtcPeerConnection.getSenders().find((s) => {
                    return s.track.kind == videoTrack.kind;
                });
                console.log('LiveVideochatWindowVideo::replaceCamera - Found sender:', sender);
                sender.replaceTrack(videoTrack);
            })
            .catch((err) => {
                console.error('LiveVideochatWindowVideo::replaceCamera - Error:', err);
            });
    }

    /**
     * Create media element and add it to window
     *
     * @param {Stream} localStream
     * @param {Stream} remoteStream
     * @private
     */
    attachStream(localStream, remoteStream) {
        if (Utils.isIOS() && Asseco.config.cloneVideoStreams !== false) {
            this.localMediaEl.srcObject = localStream.clone();
            this.remoteMediaEl.srcObject = remoteStream.clone();
        } else {
            this.localMediaEl.srcObject = localStream;
            this.remoteMediaEl.srcObject = remoteStream;
        }

        var self = this;

        this.localMediaEl.addEventListener('resize', function () {
            if (self.overlayFrameShown) {
                self.toggleFrame();
                self.toggleFrame();
            }
        });

        this.hideMask();

        this.localMediaEl.setAttribute('playsinline', true);
        this.remoteMediaEl.setAttribute('playsinline', true);

        if(Asseco.config.includeTimestamp === true){
            setTimeout(this.showTimestamp.createDelegate(this), 500);
        }
    }

    /**
     * Toggle frame on top of local stream
     */
    toggleFrame() {
        var overlayDiv = document.getElementById('asseco-videochat-overlay');
        var visibilityOptions = ['hidden', 'visible'];
        this.overlayFrameShown = !this.overlayFrameShown;
        overlayDiv.style.visibility = visibilityOptions[+this.overlayFrameShown];
        
        var localVideoEl = document.getElementById('asseco-videochat-window-video-local');
        
        if (this.overlayFrameShown) {
            localVideoEl.style.width  = '100%';
            localVideoEl.style.height = '100%';
            localVideoEl.style.top    = 0;
            localVideoEl.style.left   = 0;
            localVideoEl.style.border = 0;

            var videoRatio = localVideoEl.videoWidth / localVideoEl.videoHeight;
            var videoElementRatio = localVideoEl.clientWidth / localVideoEl.clientHeight;

            var realVideoWidth, realVideoHeight;

            if (videoRatio === videoElementRatio) {
                realVideoWidth = localVideoEl.clientWidth;
                realVideoHeight = localVideoEl.clientHeight;
            }
            // There are black/white borders on top and bottom of the video
            else if (videoRatio > videoElementRatio) {
                realVideoWidth = localVideoEl.clientWidth;
                realVideoHeight = localVideoEl.clientWidth / videoRatio;
            }
            // There are black/white borders on left and right of the video
            else {
                realVideoHeight = localVideoEl.clientHeight;
                realVideoWidth = localVideoEl.clientHeight * videoRatio;
            }

            overlayDiv.style.width = Math.round(realVideoWidth * 0.9) + 'px';
            overlayDiv.style.height = Math.round(realVideoHeight * 0.8) + 'px';
            
            var topOffset = Math.round((localVideoEl.clientHeight - realVideoHeight) / 2);
            var leftOffset = Math.round((localVideoEl.clientWidth - realVideoWidth) / 2);

            // 2 px is border so we are subtracting it
            overlayDiv.style.marginTop = (topOffset + Math.round(realVideoHeight * 0.03) - 2) + 'px';
            overlayDiv.style.marginLeft = (leftOffset + Math.round(realVideoWidth * 0.05) - 2) + 'px';
        } else {
            /**
             * Reset to original values
             */
            localVideoEl.style.width  = '';
            localVideoEl.style.height = '';
            localVideoEl.style.top    = '';
            localVideoEl.style.left   = '';
            localVideoEl.style.border = '';
        }
    }

    /**
     * Get image from given video element
     * 
     * @param {HTMLElement} videoElement 
     * @return {String}
     */
    getImageFromVideo(videoElement) {
        var newCanvas = document.createElement('canvas');
        newCanvas.width = videoElement.videoWidth;
        newCanvas.height = videoElement.videoHeight;

        var context = newCanvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, newCanvas.width, newCanvas.height);

        return newCanvas.toDataURL('image/png');
    }
}
LiveVideochatWindowVideo.prototype.xtype = 'LiveVideochatWindowVideo';
export default LiveVideochatWindowVideo;
