import Svgs from 'data/Svgs';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE Direct Messaging Preview Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveDirectMessagingPreviewWindow extends LiveCard {
    /**
     * Message object
     *
     * @type {Object} message
     */
    message;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-directmessaging-preview-window',
            title          : a24n('Preview'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        // add close button
        this.buttons = [{
            text: a24n('Close'),
            scope: this,
            handler: this.onClose
        }];

        super.initComponent();
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // fetch message body
        this.fetchBody();

        // attach handlers to attachment links
        Array.prototype.slice.call(this.containerEl.querySelectorAll('span.mdl-chip__text')).forEach(c => {
            c.onclick = (e) => {
                var aI = e.target.id.split('_')[1],
                    att;

                this.message.attachments.some((a) => {
                    if (a.id === aI) {
                        att = a;
                        return true;
                    }
                }, this);

                console.log('LiveDirectMessagingPreviewWindow::Fetching download link for: ' + att.name);
                Connection.sendMessage(Constants.REQ_GET_FILE_URL, {
                    id   : 'DirectMessaging*' + aI,
                    path : 'DirectMessaging*' + aI,
                    name : att.name,
                    type : 'get'
                }, this.onGetFileUrl, this);
            };
        }, this);
    }

    /**
     * Executed after file url is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetFileUrl(conn, msg) {
        var d = JSON.parse(msg.data);
        Utils.download(d.url, d.name);
    }

    /**
     * Fetch Message body
     *
     * @private
     */
    fetchBody() {
        this.showMask();

        console.log('LiveDirectMessagingPreviewWindow::Fetch message body (' + this.message.id + ')');
        Connection.sendMessage(Constants.REQ_DIRECT_MESSAGE_BODY, {
            id: this.message.id
        }, this.onFetchBody, this);
    }

    /**
     * Executed after message body is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onFetchBody(conn, msg) {
        var d = JSON.parse(msg.data);

        this.hideMask();

        this.getEl('.asseco-dm-message').insertAdjacentHTML('beforeend', JSON.parse(d.data));

        // mark message as seen
        if (this.message.seen === '0') {
            console.log('LiveDirectMessagingPreviewWindow::Mark message seen (' + this.message.id + ')');
            Connection.sendMessage(Constants.REQ_DIRECT_MESSAGE_SEEN, {
                id: this.message.id
            });
        }
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Preview.scss');
    }

    /**
     * Return content for card
     *
     * @return {String}
     * @private
     */
    getContentHtml() {
        return require('babel-loader!template-string-loader!./Preview.html')({
            attIcon : Utils.getIconMarkup(Svgs.ATT, '', 'padding: 4px;', '#000'),
            atts    : this.message.attachments || null
        });
    }

    /**
     * Executed when close button is clicked
     */
    onClose() {
        // reload list window before closing preview window
        var c = CmpMgr.getByXtype('LiveDirectMessagingWindow');
        if (c) {
            c.fetchList();
        }

        super.onClose();
    }
}
LiveDirectMessagingPreviewWindow.prototype.xtype = 'LiveDirectMessagingPreviewWindow';
export default LiveDirectMessagingPreviewWindow;
