import Utils from 'util/Utils';

/**
 * Field helper class
 *
 * @private
 */
class LiveField {
    /**
     * Get textfield HTML
     *
     * @param {Object} o Textfield configuration
     * @return {String} returns HTML for textfield
     */
    static getTextField(o) {
        // apply default values if not set
        Utils.applyIf(o, {
            id         : Utils.generateUUID(5, 'id_'),
            cls        : '',
            type       : 'text',
            value      : '',
            readonly   : false,
            required   : false,
            label      : '',
            inputAttrs : '',
            error      : a24n('Value is required')
        });
        o.readonly = o.readonly ? 'readonly' : '';
        o.required = o.required ? 'required' : '';

        return require('babel-loader!template-string-loader!./Text.html')(o);
    }

    /**
     * Get textarea field HTML
     *
     * @param {Object} o Textarea field configuration
     * @return {String} returns HTML for textarea field
     */
    static getTextareaField(o) {
        // apply default values if not set
        Utils.applyIf(o, {
            id       : Utils.generateUUID(5, 'id_'),
            cls      : '',
            value    : '',
            rows     : 3,
            readonly : false,
            required : false,
            label    : '',
            error    : a24n('Value is required')
        });
        o.readonly = o.readonly ? 'readonly' : '';
        o.required = o.required ? 'required' : '';

        return require('babel-loader!template-string-loader!./Textarea.html')(o);
    }

    /**
     * Get select field HTML
     *
     * @param {Object} o Select field configuration
     * @return {String} returns HTML for select field
     */
    static getSelectField(o) {
        // apply default values if not set
        Utils.applyIf(o, {
            id       : Utils.generateUUID(5, 'id_'),
            cls      : '',
            value    : '',
            required : false,
            error    : a24n('Value is required'),
            label    : '',
            items    : []
        });
        o.required = o.required ? 'required' : '';

        return require('babel-loader!template-string-loader!./Select.html')(o);
    }

    /**
     * Get checkbox field HTML
     *
     * @param {Object} o Checkbox field configuration
     * @return {String} returns HTML for checbox field
     */
    static getCheckboxField(o) {
        // apply default values if not set
        Utils.applyIf(o, {
            id       : Utils.generateUUID(5, 'id_'),
            cls      : '',
            value    : '',
            checked  : false,
            label    : ''
        });
        o.checked = o.checked ? 'checked' : '';

        return require('babel-loader!template-string-loader!./Checkbox.html')(o);
    }

    /**
     * Get radio field HTML
     *
     * @param {Object} o Radio field configuration
     * @return {String} returns HTML for radio field
     */
    static getRadioField(o) {
        // apply default values if not set
        Utils.applyIf(o, {
            id       : Utils.generateUUID(5, 'id_'),
            cls      : '',
            name     : '',
            value    : '',
            checked  : false,
            label    : '',
            attrs     : ''
        });
        o.checked = o.checked ? 'checked' : '';

        return require('babel-loader!template-string-loader!./Radio.html')(o);
    }
}
export default LiveField;
