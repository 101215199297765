module.exports=function(scope){ return `<li id="${scope.id}" class="mdl-list__item ${scope.lineCls} ${scope.itemCls}">
    <span class="mdl-list__item-primary-content">
        <span class="material-icons mdl-list__item-icon">${scope.icon}</span>
        <span class="asseco-list-item-label">${scope.name}</span>
        <span class="mdl-list__item-sub-title asseco-list-item-sublabel">${scope.desc}</span>
    </span>

    ${scope.actions.length > 0 ? `
        <span class="mdl-list__item-secondary-content">
            <span class="mdl-list__item-secondary-action">
                ${scope.actions.map(a => `<button id="${a.id}" class="mdl-button mdl-button--icon ${a.type}">${a.icon}</button>`).join('')}
            </span>
        </span>`
    : ''}
</li>`};