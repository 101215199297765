module.exports=function(scope){ return `${scope.fName}

<div style="padding-top: 10px;">
    <label class="mdl-textfield__label" style="position: inherit; margin-bottom: 5px; padding-top: 5px;">${scope.fImgLabel}</label>
    <img alt="" id="${scope.fImgId}" />
</div>

<div style="padding-bottom: 5px; font-size: 14px;">
    <a id="${scope.fImgId}-change" tabindex="-1" class="asseco-link" style="margin-bottom: 5px;">${scope.fImgLinkLabel}</a>
</div>

${scope.fSolution}`};