import Connection from 'data/Connection';
import 'util/String';
import Utils from 'util/Utils';

///////////////////////
/////////////////////////////
//////////

/**
 * LIVE Auth JWS Component (not available in Asseco namespace)
 *
 * This component do JWS verification of given access token
 *
 * @private
 */
class LiveJWS {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        Utils.apply(this, config);

        // Utils.toast(a24n('Verifying user...'), 5000);
        fetch(Asseco.ChatServerUrl + '/authAdapters/jws/?validate&uuid=' + Asseco.UUID + '&jwsToken=' + Asseco.jwsToken + '&displayName=' + Asseco.displayName, {
            method: 'post',
            body: JSON.stringify(Asseco.contextData || {}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                if (res.success) {
                    // set display name to Asseco namespace
                    Asseco.displayName = res.displayName;

///////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////
//////////////////////////////

                    // Utils.toast(String.format(a24n('Welcome {0}'), Asseco.displayName));

                    console.log('LiveJWS::Got ws url: ' + res.wsUrl);
                    Connection.wsUrl = res.wsUrl;
                    Connection.connect();
                }
                else {
                    // Utils.toast(String.format(a24n('Error fetching token: {0}'), res.reason), 10000, () => {
                    //     Utils.toast(null);
                    // }, a24n('Close'));

                    Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), res.reason));

///////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////////////////
//////////////////////////////////
//////////////////////////////
                }
            })
            .catch((err) => {
                // Utils.toast(String.format(a24n('Error fetching token: {0}'), err.message), 10000, () => {
                //     Utils.toast(null);
                // }, a24n('Close'));

                Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }
}
LiveJWS.prototype.xtype = 'LiveJWS';
export default LiveJWS;
