import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveVideoChat from './../videochat/index';

////////////////////////////
import LiveAudioChatWindowIM from './window/WindowIM';
//////////

////////////////////////////
//////////////////////////////////////////////////////
//////////

/**
 * LIVE Audio Chat Component - xtype: LiveAudioChat - (available in Asseco namespace) <br/>
 * Component is used for making audio call using WebRTC
 *
 * @example
 * var aVCh = new Asseco.LiveAudioChat({
 *     deferShow: 500,
 *     position: {
 *         position: 'absolute',
 *         top: '190px',
 *         right: '25px'
 *     },
 *     icon: 'custom icon - SVG path or IMG src',
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 * });
 */
class LiveAudioChat extends LiveVideoChat {
////////////////////////////////
    /**
     * Specify chat window class to open
     *
     * @private {LiveAudioChatWindowIM} chatWindowClass
     */
    chatWindowClass = LiveAudioChatWindowIM;
//////////////

////////////////////////////////
///////
////////////////////////////////////////
//////
///////////////////////////////////////////////////////
///////
////////////////////////////////////////////
//////////////

    /**
     * List of groups/plans to fetch
     *
     * @private {String} list
     */
    list = 'audiochat';

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier : 'audiochat',
            label      : a24n('Start Audio Chat'),
            icon       : 'M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }
}
LiveAudioChat.prototype.xtype = 'LiveAudioChat';
CmpMgr.registerXtype(LiveAudioChat.prototype.xtype, LiveAudioChat);
export default LiveAudioChat;
