/* global componentHandler, MaterialTextfield, MaterialTooltip */

import Element from 'util/Element';

// Component handler
require ('mdl/mdlComponentHandler.js');

// Base components
require ('mdl/button/button.js');
require ('mdl/checkbox/checkbox.js');
// require ('mdl/icon-toggle/icon-toggle.js');
require ('mdl/menu/menu.js');
// require ('mdl/progress/progress.js');
require ('mdl/radio/radio.js');
// require ('mdl/slider/slider.js');
require ('mdl/snackbar/snackbar.js');
require ('mdl/spinner/spinner.js');
// require ('mdl/switch/switch.js');
// require ('mdl/tabs/tabs.js');
require ('mdl/textfield/textfield.js');
require ('mdl/tooltip/tooltip.js');

// Complex components (which reuse base components)
require ('mdl/layout/layout.js');
// require ('mdl/data-table/data-table.js');

// And finally, the ripples
// require ('mdl/ripple/ripple.js');

////////////////////////// MDL FIXES ///////////////////////////////

MaterialTextfield.prototype.onBlur_ = function (event) {
    this.element_.classList.remove(this.CssClasses_.IS_FOCUSED);

    // FIX
    this.checkValidity();
};

MaterialTextfield.prototype.onChange_ = function() {
    this.checkValidity();
};

MaterialTextfield.prototype.updateClasses_ = function () {
    this.checkDisabled();
    //this.checkValidity();
    this.checkDirty();

    // FIX
    var dirty = this.element_.classList.contains(this.CssClasses_.IS_DIRTY);
    var required = this.input_.required;
    if (!required ||
        required && dirty) {
        this.checkValidity();
    }

    this.checkFocus();
};

MaterialTextfield.prototype.enable = function () {
    this.input_.disabled = false;
    this.updateClasses_();

    // FIX
    this.checkValidity();
};

MaterialTextfield.prototype.init = function () {
    if (this.element_) {
        this.label_ = this.element_.querySelector('.' + this.CssClasses_.LABEL);
        this.input_ = this.element_.querySelector('.' + this.CssClasses_.INPUT);
        if (this.input_) {
            if (this.input_.hasAttribute(this.Constant_.MAX_ROWS_ATTRIBUTE)) {
                this.maxRows = parseInt(this.input_.getAttribute(this.Constant_.MAX_ROWS_ATTRIBUTE), 10);
                if (isNaN(this.maxRows)) {
                    this.maxRows = this.Constant_.NO_MAX_ROWS;
                }
            }
            if (this.input_.hasAttribute('placeholder')) {
                this.element_.classList.add(this.CssClasses_.HAS_PLACEHOLDER);
            }
            this.boundUpdateClassesHandler = this.updateClasses_.bind(this);
            this.boundFocusHandler = this.onFocus_.bind(this);
            this.boundBlurHandler = this.onBlur_.bind(this);
            this.boundResetHandler = this.onReset_.bind(this);

            // FIX
            this.boundChangeHandler = this.onChange_.bind(this);

            this.input_.addEventListener('input', this.boundUpdateClassesHandler);
            this.input_.addEventListener('focus', this.boundFocusHandler);
            this.input_.addEventListener('blur', this.boundBlurHandler);
            this.input_.addEventListener('reset', this.boundResetHandler);

            // FIX
            this.input_.addEventListener('change', this.boundChangeHandler);

            if (this.maxRows !== this.Constant_.NO_MAX_ROWS) {
                // TODO: This should handle pasting multi line text.
                // Currently doesn't.
                this.boundKeyDownHandler = this.onKeyDown_.bind(this);
                this.input_.addEventListener('keydown', this.boundKeyDownHandler);
            }
            var invalid = this.element_.classList.contains(this.CssClasses_.IS_INVALID);
            this.updateClasses_();
            this.element_.classList.add(this.CssClasses_.IS_UPGRADED);
            if (invalid) {
                this.element_.classList.add(this.CssClasses_.IS_INVALID);
            }
            if (this.input_.hasAttribute('autofocus')) {
                this.element_.focus();
                this.checkFocus();
            }
        }
    }
};

MaterialTooltip.prototype.CssClasses_.PERSISTENT = 'mdl-tooltip--persistent';

MaterialTooltip.prototype.hideTooltip_ = function() {
    if (! this.element_.classList.contains(this.CssClasses_.PERSISTENT)) {
        this.element_.classList.remove(this.CssClasses_.IS_ACTIVE);
    }
};

MaterialTooltip.prototype.handleMouseEnter_ = function(event) {
    var props = event.target.getBoundingClientRect(),
        left = props.left + (props.width / 2),
        top = props.top + (props.height / 2),
        marginLeft = -1 * (this.element_.offsetWidth / 2),
        marginTop = -1 * (this.element_.offsetHeight / 2);

    // if tooltip should be shown on left but there is no space, show it on the right
    if (Element.hasClass(this.element_, this.CssClasses_.LEFT) && (props.left - this.element_.offsetWidth < 0)) {
        Element.replaceClass(this.element_, this.CssClasses_.LEFT, this.CssClasses_.RIGHT);
    }

    // if tooltip should be shown on right but there is no space, show it on the left
    if (Element.hasClass(this.element_, this.CssClasses_.RIGHT) && ((document.documentElement.clientWidth - props.right)  - this.element_.offsetWidth < 0)) {
        Element.replaceClass(this.element_, this.CssClasses_.RIGHT, this.CssClasses_.LEFT);
    }

    if (this.element_.classList.contains(this.CssClasses_.LEFT) || this.element_.classList.contains(this.CssClasses_.RIGHT)) {
        left = (props.width / 2);
        if (top + marginTop < 0) {
            this.element_.style.top = '0';
            this.element_.style.marginTop = '0';
        } else {
            this.element_.style.top = top + 'px';
            this.element_.style.marginTop = marginTop + 'px';
        }
    } else {
        if (left + marginLeft < 0) {
            this.element_.style.left = '0';
            this.element_.style.marginLeft = '0';
        } else {
            this.element_.style.left = left + 'px';
            this.element_.style.marginLeft = marginLeft + 'px';
        }
    }

    if (this.element_.classList.contains(this.CssClasses_.TOP)) {
        this.element_.style.top = props.top - this.element_.offsetHeight - 10 + 'px';
    } else if (this.element_.classList.contains(this.CssClasses_.RIGHT)) {
        this.element_.style.left = props.left + props.width + 10 + 'px';
    } else if (this.element_.classList.contains(this.CssClasses_.LEFT)) {
        this.element_.style.left = props.left - this.element_.offsetWidth - 10 + 'px';
    } else {
        this.element_.style.top = props.top + props.height + 10 + 'px';
    }

    this.element_.classList.add(this.CssClasses_.IS_ACTIVE);
};

////////////////////////// MDL SELECT ADDON ////////////////////////

var getmdlSelect = {
    _addEventListeners: function (dropdown) {
        var input = dropdown.querySelector('input');
        var hiddenInput = dropdown.querySelector('input[type="hidden"]');
        var list = dropdown.querySelectorAll('li');
        var menu = dropdown.querySelector('.mdl-js-menu');
        var arrow = dropdown.querySelector('.mdl-icon-toggle__label');
        var label = '';
        var previousValue = '';
        var previousDataVal = '';
        var opened = false;

        var setSelectedItem = function (li) {
            var value = li.textContent.trim();
            input.value = value;
            list.forEach(function (li) {
                li.classList.remove('selected');
            });
            li.classList.add('selected');
            dropdown.MaterialTextfield.change(value); // handles css class changes
            setTimeout(function () {
                dropdown.MaterialTextfield.updateClasses_(); //update css class
            }, 250);

            // update input with the "id" value
            hiddenInput.value = li.dataset.val || '';

            previousValue = input.value;
            previousDataVal = hiddenInput.value;

            if ('createEvent' in document) {
                var evt = document.createEvent('HTMLEvents');
                evt.initEvent('change', false, true);
                menu['MaterialMenu'].hide();
                input.dispatchEvent(evt);
            } else {
                input.fireEvent('onchange');
            }
        };

        var hideAllMenus = function () {
            opened = false;
            input.value = previousValue;
            hiddenInput.value = previousDataVal;
            if (!dropdown.querySelector('.mdl-menu__container').classList.contains('is-visible')) {
                dropdown.classList.remove('is-focused');
            }
            var menus = document.querySelectorAll('.getmdl-select .mdl-js-menu');
            [].forEach.call(menus, function (menu) {
                if (menu['MaterialMenu']) {
                    menu['MaterialMenu'].hide();
                }
            });
            var event = new Event('closeSelect');
            menu.dispatchEvent(event);
        };
        document.body.addEventListener('click', hideAllMenus, false);

        //hide previous select after press TAB
        dropdown.onkeydown = function (event) {
            if (event.keyCode == 9) {
                input.value = previousValue;
                hiddenInput.value = previousDataVal;
                if (menu['MaterialMenu']) {
                    menu['MaterialMenu'].hide();
                }
                dropdown.classList.remove('is-focused');
            }
        };

        //show select if it have focus
        input.onfocus = function (e) {
            menu['MaterialMenu'].show();
            menu.focus();
            opened = true;
        };

        input.onblur = function (e) {
            e.stopPropagation();
        };

        //hide all old opened selects and opening just clicked select
        input.onclick = function (e) {
            e.stopPropagation();
            if (!menu.classList.contains('is-visible')) {
                menu['MaterialMenu'].show();
                hideAllMenus();
                dropdown.classList.add('is-focused');
                opened = true;
            } else {
                menu['MaterialMenu'].hide();
                opened = false;
            }
        };

        input.onkeydown = function (event) {
            if (event.keyCode == 27) {
                input.value = previousValue;
                hiddenInput.value = previousDataVal;
                menu['MaterialMenu'].hide();
                dropdown.MaterialTextfield.onBlur_();
                if (label !== '') {
                    dropdown.querySelector('.mdl-textfield__label').textContent = label;
                    label = '';
                }
            }
        };

        menu.addEventListener('closeSelect', function (e) {
            input.value = previousValue;
            hiddenInput.value = previousDataVal;
            dropdown.classList.remove('is-focused');
            if (label !== '') {
                dropdown.querySelector('.mdl-textfield__label').textContent = label;
                label = '';
            }
        });

        //set previous value and data-val if ESC was pressed
        menu.onkeydown = function (event) {
            if (event.keyCode == 27) {
                input.value = previousValue;
                hiddenInput.value = previousDataVal;
                dropdown.classList.remove('is-focused');
                if (label !== '') {
                    dropdown.querySelector('.mdl-textfield__label').textContent = label;
                    label = '';
                }
            }
        };

        if (arrow) {
            arrow.onclick = function (e) {
                e.stopPropagation();
                if (opened) {
                    menu['MaterialMenu'].hide();
                    opened = false;
                    dropdown.classList.remove('is-focused');
                    dropdown.MaterialTextfield.onBlur_();
                    input.value = previousValue;
                    hiddenInput.value = previousDataVal;
                } else {
                    hideAllMenus();
                    dropdown.MaterialTextfield.onFocus_();
                    input.focus();
                    menu['MaterialMenu'].show();
                    opened = true;
                }
            };
        }

        [].forEach.call(list, function (li) {
            li.onfocus = function () {
                if (li.hasAttribute('disabled')) {
                    return;
                }
                dropdown.classList.add('is-focused');
                var value = li.textContent.trim();
                input.value = value;
                if (!dropdown.classList.contains('mdl-textfield--floating-label') && label == '') {
                    label = dropdown.querySelector('.mdl-textfield__label').textContent.trim();
                    dropdown.querySelector('.mdl-textfield__label').textContent = '';
                }
            };

            li.onclick = function () {
                if (li.hasAttribute('disabled')) {
                    return;
                }
                setSelectedItem(li);
            };

            if (li.dataset.selected) {
                if (li.hasAttribute('disabled')) {
                    return;
                }
                setTimeout(function () {
                    setSelectedItem(li);
                }, 250);
            }
        });
    },
    init: function (selector) {
        var dropdowns = document.querySelectorAll(selector);
        [].forEach.call(dropdowns, function (dropdown) {
            getmdlSelect._addEventListeners(dropdown);
            componentHandler.upgradeElement(dropdown);
            componentHandler.upgradeElement(dropdown.querySelector('ul'));
        });
    },
    whenLoaded: function () {
        getmdlSelect.init('.getmdl-select');
    }
};
window.getmdlSelect = getmdlSelect;

window.addEventListener ?
    window.addEventListener('load', getmdlSelect.whenLoaded, false) :
    window.attachEvent && window.attachEvent('onload', getmdlSelect.whenLoaded);
