module.exports=function(scope){ return `<div id="${scope.id}" class="asseco mdl-card mdl-shadow--16dp asseco-card">
    ${scope.title ? `
        <div class="mdl-card__title mdl-shadow--4dp">
            ${scope.menu ? `
                <button id="${scope.menuId}" class="mdl-button mdl-button--icon asseco-card-menu-icon">
                    ${scope.menuIcon}
                </button>

                ${scope.menu !== true ? `
                    <ul class="mdl-menu mdl-menu--bottom-left mdl-js-menu" for="${scope.menuId}">
                        ${scope.menu}
                    </ul>`
                : ''}
                `
            : ''}

            <h2 class="mdl-card__title-text">${scope.title}</h2>
        </div>`
    : ''}

    <div class="mdl-card__supporting-text" style="top: ${scope.topPx}; bottom: ${scope.bottomPx}">
        ${scope.content}
    </div>

    ${scope.buttons ? `
        <div class="mdl-card__actions mdl-card--border" style="text-align: ${scope.buttonsAlign};">
            ${scope.buttons}
        </div>`
    : ''}

    ${scope.tools ? `
        <div class="mdl-card__menu">
            ${scope.tools}
        </div>`
    : ''}
</div>`};