/* global componentHandler, setInterval, clearInterval */

/**
 * Utility class
 *
 * @private
 */
class Utils {
    /**
     * Copies all the properties of config to obj.
     *
     * @param {Object} o The receiver of the properties
     * @param {Object} c The source of the properties
     * @return {Object} returns obj
     */
    static apply(o, c = {}) {
        if (o && c) {
            for (let p in c) {
                o[p] = c[p];
            }
        }
        return o;
    }

    /**
     * Copies all the properties of config to object if they don't already exist.
     *
     * @param {Object} o The receiver of the properties
     * @param {Object} c The source of the properties
     * @return {Object} returns obj
     */
    static applyIf(o, c = {}) {
        if (o && c) {
            for (let p in c) {
                if (o[p] === undefined) {
                    o[p] = c[p];
                }
            }
        }
        return o;
    }

    /**
     * Copies a set of named properties from the source object to the destination object.
     *
     * @param {Object} d The destination object.
     * @param {Object} s The source object.
     * @param {Array|String} n Either an Array of property names, or a comma-delimited list of property names to copy.
     * @return {Object} The modified object.
     */
    static copyTo(d, s, n) {
        if (typeof n === 'string') {
            n = n.split(/[,;\s]/);
        }
        n.forEach(p => {
            if (s.hasOwnProperty(p)) {
                d[p] = s[p];
            }
        });
        return d;
    }

    /**
     * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
     *
     * - `null`
     * - `undefined`
     * - a zero-length array
     * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
     *
     * @param {Object} val The value to test.
     * @param {Boolean} [allowEmptyStr=false] `true` to allow empty strings.
     * @return {Boolean}
     */
    static isEmpty(val, allowEmptyStr) {
        return (val == null) || (! allowEmptyStr ? val === '' : false) || (Array.isArray(val) && val.length === 0);
    }

    /**
     * Detect if mobile browser is used
     *
     * @return {Boolean}
     */
    static isMobile() {
        return (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i));
    }

    /**
     * Detect if iOS is used
     * 
     * https://stackoverflow.com/a/58065241 -> changed navigator.platform to navigator.userAgent
     */
    static isIOS() {
        return (
            /iPad|iPhone|iPod/.test(navigator.userAgent) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        ) && !window.MSStream;
    }

    /**
     * Converts the first character only of a string to upper case
     *
     * @param {String} val The text to convert
     * @return {String} The converted text
     */
    static capitalize(val) {
        return ! val ? val : val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
    }

    /**
     * Truncate a string and add an ellipsis ('...') to the end if it exceeds the specified length
     *
     * @param {String} val The string to truncate
     * @param {Number} len The maximum length to allow before truncating
     * @param {Boolean} word True to try to find a common work break
     * @return {String} The converted text
     */
    static ellipsis(val, len, word) {
        if (val && val.length > len) {
            var ell = '...';
            if (word) {
                var vs = val.substr(0, len - 2),
                    index = Math.max(vs.lastIndexOf(' '), vs.lastIndexOf('.'), vs.lastIndexOf('!'), vs.lastIndexOf('?'));

                if (index == -1 || index < (len - 15)) {
                    return val.substr(0, len - 3) + ell;
                } else {
                    return vs.substr(0, index) + ell;
                }
            } else {
                return val.substr(0, len - 3) + ell;
            }
        }
        return val;
    }

    /**
     * Return uniqe identifier
     *
     * @param {Number} len Length for generated identifier (default 36)
     * @param {String} p prefix
     * @return {String} Unique identifier
     */
    static generateUUID(len, p = '') {
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

        if (len) {
            uuid = uuid.substring(0, len);
        }

        return p + uuid;
    }

    /**
     * Convert date string to Date or localized date string
     *
     * @param {String} s
     * @param {Boolean} l
     * @return {Date|String}
     */
    static toDate(s, l) {
        if (Utils.isEmpty(s)) {
            return '';
        }

        var d = new Date(s.replace(/-/g, '/')),
            locale = Asseco.Localization.getLang().split('_')[0];

        return l === true ? d.toLocaleString(locale).replace('. ', '.') : d;
    }

    /**
     * Fetch time in current locale
     * @return {String}
     */
    static getLocaleTime() {
        var locale = Asseco.Localization.getLang().split('_')[0];
        return new Date().toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'});
    }

    /**
     * Returns icon markup (img or svg)
     *
     * @param {String} p Image path, embeded data uri or path for SVG
     * @param {String} cssNames Additional css class for img or svg tag
     * @param {String} s Additional css properties added to style attribute of img or svg tag
     * @param {String} f SVG icon fill
     * @param {Boolean} w Wrap img or svg tag in i tag with class material-icons
     * @return {String} HTML icon markup
     */
    static getIconMarkup(p, cssNames = '', s = '', f = '#fff', w = true) {
        if (Utils.isEmpty(p)) {
            return '';
        }

        // check if given path is img src
        var imgSrc = p.indexOf('data:image') > -1 ||
            p.indexOf('.gif') > -1  || p.indexOf('.GIF') > -1  ||
            p.indexOf('.png') > -1  || p.indexOf('.PNG') > -1  ||
            p.indexOf('.jpg') > -1  || p.indexOf('.JPG') > -1  ||
            p.indexOf('.jpeg') > -1 || p.indexOf('.JPEG') > -1 ||
            p.indexOf('.bmp') > -1  || p.indexOf('.BMP') > -1;

        var path = p.indexOf('<path') > -1
            ? p
            : `<path d="${p}" />`;

        var tag = imgSrc
            ? (`<img tabIndex="-1" src="${p}" class="${cssNames}" style="${s}" />`)
            : (`<svg tabIndex="-1" xmlns="http://www.w3.org/2000/svg" fill="${f}" width="24" height="24" viewBox="0 0 24 24" class="${cssNames}" style="${s}">
                    ${path}
                </svg>`);

        return w ? (`<i tabIndex="-1" class="material-icons">${tag}</i>`) : tag;
    }

    /**
     * Show toast notification
     *
     * @param {String} m Tost message
     * @param {Number} t Toast timeout in ms
     * @param {Function} aH Toast action handler
     * @param {String} aT Toast action text
     */
    static toast(m, t = 2000, aH = null, aT = '') {
        var tId = 'asseco-toast';

        // get existing toast element
        var s = document.getElementById(tId);

        // close current toast
        if (s) {
            s.MaterialSnackbar.hideSnackbar();
        }

        // if there is no message given exit
        if (! m) {
            return;
        }

        // create if toast markup not exists
        if (! s) {
            document.body.insertAdjacentHTML('beforeend', `
                <div id="${tId}" aria-live="assertive" aria-atomic="true" aria-relevant="text" class="mdl-snackbar mdl-js-snackbar">
                    <div class="mdl-snackbar__text"></div>
                    <button type="button" class="mdl-snackbar__action"></button>
                </div>`);
            s = document.getElementById(tId);

            // register dynamic material design components
            componentHandler.upgradeElement(s);
        }

        s.MaterialSnackbar.showSnackbar({
            message       : m,
            timeout       : t,
            actionHandler : aH,
            actionText    : aT
        });
    }

    /**
     * Show dialog notification
     *
     * @param {String} t Dialog title
     * @param {String} m Dialog content
     * @param {Array}  a Dialog actions
     * @return {HTMLElement} dialog
      */
    static dialog(t = '', m = '', a = null) {
        var dId = 'asseco-dialog';

        // get existing dialog element
        var d = document.getElementById(dId);

        // close current dialog
        if (d && d.hasAttribute('open')) {
            d.close();
        }

        // if there is no message given exit
        if (! m) {
            return;
        }

        // create if dialog markup not exists
        if (! d) {
            // require dialog polyfill
            require('dialog-polyfill/dialog-polyfill.css');

            document.body.insertAdjacentHTML('beforeend', `
                <dialog id="${dId}" class="mdl-dialog">
                    <h2 class="mdl-dialog__title"></h2>
                    <div class="mdl-dialog__content"></div>
                </dialog>`);

            d = document.getElementById(dId);
            require('dialog-polyfill/dialog-polyfill.js').registerDialog(d);
        }

        // remove old dialog actions
        var da = d.querySelector('.mdl-dialog__actions');
        require('./Element.js').default.removeNode(da);

        // create new dialog actions
        d.insertAdjacentHTML('beforeend', '<div class="mdl-dialog__actions"></div>');
        da = d.querySelector('.mdl-dialog__actions');

        // add actions to dialog actions
        a = a || [{
            text: a24n('Close'),
            handler: () => d.close()
        }];

        // create HTML for action buttons and gather list of handlers
        var buid, bHtml = '', aH = {};
        a.forEach(btn => {
            buid = Utils.generateUUID(5, 'id_');
            bHtml += '<button id="' + buid + '" class="mdl-button mdl-button--colored">' + btn.text + '</button>';

            if (btn.hasOwnProperty('handler')) {
                aH[buid] = {
                    scope     : btn.scope || this,
                    handler   : btn.handler
                };
            }
        }, this);

        da.innerHTML = bHtml;

        // attach handlers to action buttons
        var el, id;
        for (id in aH) {
            el = document.getElementById(id);
            if (el) {
                el.addEventListener('click', aH[id].handler.createDelegate(aH[id].scope || this, [d, el]));
            }
        }

        // empty existing title
        let h2 = d.querySelector('h2');
        if (h2) {
            h2.textContent = '';

            // set title if given
            if (! Utils.isEmpty(t)) {
                h2.textContent = t;
            }
        }

        // empty existing content
        let c = d.querySelector('.mdl-dialog__content');
        if (c) {
            c.innerHTML = '';

            // set content if given
            if (! Utils.isEmpty(m)) {
                c.innerHTML = m;
            }
        }

        d.showModal();
        return d;
    }

    /**
     * Add tooltip
     *
     * @param {String} f ID of element for which tooltip is for
     * @param {String} m Tooltip message
     * @param {String} p Tooltip position
     * @return {HTMLElement} The element of tooltip
     */
    static tooltip(f, m, p = 'bottom') {
        let g = Utils.generateUUID(5, 'id_');

        // check for existing tooltip
        let ex = document.body.querySelector('.mdl-tooltip[for=' + f + ']');
        if (ex) {
            return ex;
        }

        // insert element tooltip into document body
        document.body.insertAdjacentHTML('beforeend',
            `<div id="${g}" class="mdl-tooltip mdl-tooltip--large mdl-tooltip--${p} asseco-tooltip" for="${f}">${m}</div>`);

        let e = document.getElementById(g);
        componentHandler.upgradeElement(e);

        return e;
    }

    /**
     * Return spinner HTML markup
     *
     * @param {String} s Extra style attribute
     * @return {String}
     */
    static getSpinner(s = '') {
        return `<div class="sk-three-bounce" ${s}>
                    <div class="sk-child sk-bounce1"></div>
                    <div class="sk-child sk-bounce2"></div>
                    <div class="sk-child sk-bounce3"></div>
                </div>`;
    }

    /**
     * Return MDL spinner HTML markup
     *
     * @param {String} s Extra spinner style
     * @param {Number} w Spinner width
     * @param {Number} h Spinner height
     * @return {String}
     */
    static getMdlSpinner(s = '', w = 24, h = 24) {
        return `<div class="mdl-spinner mdl-js-spinner is-active" style="width: ${w}px; height: ${h}px; ${s}"></div>`;
    }

    /**
     * The DelayedTask class provides a convenient way to "buffer" the execution of a method,
     * performing setTimeout where a new timeout cancels the old timeout. When called, the
     * task will wait the specified time period before executing. If durng that time period,
     * the task is called again, the original call will be cancelled. This continues so that
     * the function is only called a single time for each iteration.
     *
     * @example
     * var task = new Utils.DelayedTask(function () {
     *     alert(document.getElementById('myInputField').value.length);
     * });
     *
     * // Wait 500ms before calling our function. If the user presses another key during that 500ms, it will be cancelled and we'll wait another 500ms.
     * document.getElementById('myInputField').onkeyup = function () {
     *     task.delay(500);
     * };
     *
     * @param {Function} fn (optional) The default function to call.
     * @param {Object} s (optional) The default scope in which the function is called. If not specified, scope will refer to the browser window.
     * @param {Array} a (optional) The default Array of arguments.
     */
    static DelayedTask(f, s, a) {
        var me = this,
            id,
            call = function () {
                clearInterval(id);
                id = null;
                f.apply(s, a || []);
            };

        /**
         * Cancels any pending timeout and queues a new one
         *
         * @param {Number} d The milliseconds to delay
         * @param {Function} nF (optional) Overrides function passed to constructor
         * @param {Object} nS (optional) Overrides scope passed to constructor. Remember that if no scope is specified, <code>this</code> will refer to the browser window.
         * @param {Array} nA (optional) Overrides args passed to constructor
         */
        me.delay = function (d, nF, nS, nA) {
            me.cancel();
            f = nF || f;
            s = nS || s;
            a = nA || a;
            id = setInterval(call, d);
        };

        /**
         * Cancel the last queued timeout
         */
        me.cancel = function () {
            if (id) {
                clearInterval(id);
                id = null;
            }
        };
    }

    /**
     * Trigger download of given URL
     *
     * @param {String} u Download URL
     * @param {String} n Download filename
     */
    static download(u, n) {
        // save BLOB
        if (u instanceof Blob) {
            require('file-saver').saveAs(u, n);
        }
        // save URL
        else {
            var a = document.createElement('a');
            // use HTML5 download attribute
            if ('download' in a) {
                a.href = u;
                a.target = '_blank';
                a.download = n;
                a.style.display = 'none';
                document.body.appendChild(a);

                // trigger click on a
                if (document.createEvent) {
                    var event = document.createEvent('MouseEvents');
                    event.initEvent('click', true, true);
                    a.dispatchEvent(event);
                } else {
                    a.click();
                }

                document.body.removeChild(a);
            }
            // force download through window popup
            else {
                window.open(u);
            }
        }
    }

    /**
     * Call session ping if defined
     *
     * @param {String} a Action (start/stop)
     */
    static sessionPing(a) {
        // exit if no session ping url is defined
        if (! Asseco.hasOwnProperty('config') || ! Asseco.config.hasOwnProperty('sessionPingUrl')) {
            return;
        }

        var CmpMgr = require('util/ComponentManager').default,
            LiveCard = require('widgets/card/Card').default,

            pUrl = Asseco.config.sessionPingUrl,
            pTime = Asseco.config.sessionPingTime || 300000,

            /**
             * Start session ping interval function
             */
            startSessInt = () => {
                console.log('Utils::startSessionPingInterval (' + pTime + ') - URL: ' + pUrl);
                Asseco.sessionPingInterval = setInterval(() => {
                    // create session ping image if not created
                    var sImg = document.getElementById('sessionPing');
                    if (! sImg) {
                        document.body.insertAdjacentHTML('beforeend', '<img id="sessionPing" src="#">');
                        sImg = document.getElementById('sessionPing');
                    }

                    // change session ping image url
                    console.log('Utils::sessionPing - ' + (pUrl + '?' + Date.now()));
                    sImg.src = pUrl + '?' + Date.now();
                }, pTime);
            },

            /**
             * Stop session ping interval function
             */
            stopSessInt = () => {
                // we should stop session ping interval only if no cards are opened
                var canStop = true;
                for (let xtype in CmpMgr.components) {
                    if (CmpMgr.components.hasOwnProperty(xtype) && CmpMgr.components[xtype] instanceof LiveCard) {
                        canStop = false;
                        break;
                    }
                }
                if (canStop) {
                    console.log('Utils::stopSessionPingInterval');
                    clearInterval(Asseco.sessionPingInterval);
                    delete Asseco.sessionPingInterval;
                }
            };

        // start session ping interval if not started
        if (a === 'start' && ! Asseco.sessionPingInterval) {
            startSessInt();
        }
        // stop session ping interval if started
        else if (a === 'stop' && Asseco.sessionPingInterval) {
            stopSessInt();
        }
    }

////////////////////////////////////////////////
    /**
     * @param {RTCPeerConnection|MediaStream} p
     */
    static webrtcStopMedia(p) {
        if (! p) {
            return;
        }

        try {
            console.log('Utils::webrtcStopMedia');

            let streams;
            if (p instanceof RTCPeerConnection) {
                streams = [].concat(p.getLocalStreams()).concat(p.getRemoteStreams());
            }
            else if (p instanceof MediaStream) {
                streams = [p];
            }

            if (! Utils.isEmpty(streams)) {
                // go through each stream
                streams.forEach(s => {
                    // stop and remove each track of given stream
                    s.getTracks().forEach(t => {
                        t.stop();
                        s.removeTrack(t);
                    });
                });
            }

            if (p instanceof RTCPeerConnection && !Utils.isEmpty(streams)) {
                streams.forEach(s => {
                    p.removeStream(s);
                });
            }

            var remoteVideoEl = document.getElementById('asseco-videochat-window-video-remote');
            if (remoteVideoEl) {
                remoteVideoEl.srcObject = null;
            }

            var localVideoEl = document.getElementById('asseco-videochat-window-video-remote');
            if (localVideoEl) {
                localVideoEl.srcObject = null;
            }
        } catch (e) {
            console.log('Utils::Problem stoping media stracks: ' + e.message);
        }
    }
//////////////

    /**
     * Report error to Sentry
     *
     * @param {String} m Message
     * @param {Object} d  Extra data
     * @param {String} l Report levelt
     */
    static reportError(m, d = {}, l = 'error') {
///////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
/////////////////////
/////////////////////
///////////
//////////////////
    }

    /**
     * Get function callee name
     *
     * @param {String} cN Class name
     * @param {String} mN Method name
     * @return {String}
     */
    static getFuncName(cN, mN) {
        return String.format('{0}.{1}', cN, mN);
    }

//////////////////////////////////////////////////
///////
/////////////////////////////////////////////////
//////
///////////////////////////////////
/////////////////////////////////////
///////
//////////////////////////////
////////////////
////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////
//////////////
}
export default Utils;
