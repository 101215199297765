import CmpMgr from 'util/ComponentManager';
import 'util/Function';
import Utils from 'util/Utils';

////////////////////////////////////
import LiveCaptcha from 'widgets/auth/captcha/Capthca';
//////////

//////////////////////////////////////
import LiveReCaptcha from 'widgets/auth/recaptcha/ReCapthca';
//////////

////////////////////////////////
///////////////////////////////////////////
//////////

////////////////////////////////////
import LiveCheckIP from 'widgets/auth/checkIP/CheckIP';
//////////

/////////////////////////////////////
import LiveAuthUser from 'widgets/auth/user/User';
//////////

////////////////////////////////
import LiveJWS from 'widgets/auth/jws/JWS';
//////////

/**
 * Global connection class (available in Asseco namespace) <br/>
 * Class is used for opening WebSocket connection to chat server and sending/receiving JSON messages
 *
 * @public
 */
class Connection {
    /**
     * Connection status CONNECTING
     *
     * @type {Number}
     */
    static CONNECTING = 0;

    /**
     * Connection status OPEN
     *
     * @type {Number}
     */
    static OPEN = 1;

    /**
     * Connection status CLOSING
     *
     * @type {Number}
     */
    static CLOSING = 2;

    /**
     * Connection status CLOSED
     *
     * @type {Number}
     */
    static CLOSED = 3;

    /**
     * Holds instanceof WebSocket connection
     *
     * @type {WebSocket} wsConn
     */
    static wsConn;

    /**
     * Holds the url WebSocket is connected to
     *
     * @type {String} wsUrl
     */
    static wsUrl;

    /**
     * Holds reference to Element which called connect (optional)
     *
     * @type {HTMLElement} targetEl
     */
    static targetEl;

    /**
     * Holds the event names that other component can subscribe
     *
     * @type {Array} eventNames
     * @private
     */
    static eventNames = ['open', 'close', 'error', 'message'];

    /**
     * Holds the list of web socket open callbacks
     *
     * @type {Object} openCallbacks
     * @private
     */
    static openCallbacks;

    /**
     * Holds the list of web socket close callbacks
     *
     * @type {Object} closeCallbacks
     * @private
     */
    static closeCallbacks;

    /**
     * Holds the list of web socket error callbacks
     *
     * @type {Object} errorCallbacks
     * @private
     */
    static errorCallbacks;

    /**
     * Holds the list of web socket message callbacks
     *
     * @type {Object} messageCallbacks
     * @private
     */
    static messageCallbacks;

    /**
     * Holds the list of web socket message callback for request
     *
     * @type {Object} requestCallbacks
     * @private
     */
    static requestCallbacks;

    /**
     * Open WebSocket connection <br/>
     * Before connection can be opened Asseco.UUID must be generated and token must be set in Connection class
     *
     * @return {WebSocket} Reference to opened web socket connection
     */
    static connect() {
        if (! this.wsConn) {
            // token is needed for opening connection
            if (! this.wsUrl) {
                console.warn('Connection::Need token for opening web socket connection');
                this.acquireToken();
                return;
            }

            // Utils.toast(a24n('Opening connection...'), 5000);
            console.log('Connection::Opening web socket connection to: ' + this.wsUrl);

            if (! window.WebSocket) {
                // Utils.toast(a24n('Browser not supported...'), 5000);
                Utils.dialog(a24n('Error'), a24n('Browser not supported...'));
                return;
            }
            this.wsConn = new WebSocket(this.wsUrl);

            // bind events
            this.wsConn.addEventListener('open', this.onOpen.createDelegate(this));
            this.wsConn.addEventListener('close', this.onClose.createDelegate(this));
            this.wsConn.addEventListener('message', this.onMessage.createDelegate(this));
            this.wsConn.addEventListener('error', this.onError.createDelegate(this));
        }

        return this.wsConn;
    }

    /**
     * Clear WebSocket connection params
     */
    static clearConnectionParams() {
        console.log('Connection::Clear connection params');

        this.wsUrl     = null;
        this.targetEl  = null;
        this.wsConn    = null;

        // renew UUID
        Asseco.UUID = Utils.generateUUID();
        console.log('Connection::Regenerate Asseco UUID: ' + Asseco.UUID);
    }

    /**
     * Returns WebSocket connection status
     *
     * @return {Number} Current status of web socket connection (returns CLOSED if connection is undefined)
     */
    static getStatus() {
        if (! this.wsConn) {
            return this.CLOSED;
        }
        return this.wsConn.readyState;
    }

    /**
     * Send message to WebSocket
     *
     * @param {String} message JSON encoded string for sending to opened web socket
     * @returns {Boolean} Is message sent to web socket
     */
    static send(message) {
        if (Connection.getStatus() !== Connection.OPEN) {
            Utils.toast(a24n('Not connected'));
            return false;
        }

        console.log('Connection::Sending data to web socket: ' + message);
        this.wsConn.send(message);

        return true;
    }

    /**
     * Close WebSocket connection
     */
    static close() {
        if (Connection.getStatus() !== Connection.OPEN) {
            Utils.toast(a24n('Not connected'));
            return;
        }

        this.wsConn.close();
        this.wsConn = null;

        this.clearConnectionParams();
    }

    /**
     * Executed on opening WebSocket connection <br/>
     * Calls callbacks set in this.openCallbacks
     */
    static onOpen() {
        // Utils.toast(null);
        console.log('Connection::Web socket connection opened');
        var oC = this.openCallbacks;
        if (oC) {
            for (var caller in oC) {
                oC[caller].forEach(cb => {
                    cb.callback.apply(cb.scope, [this]);
                }, this);
            }
        }
    }

    /**
     * Executed on closing WebSocket connection <br/>
     * Calls callbacks set in this.closeCallbacks
     *
     * @param {Event} e CloseEvent
     */
    static onClose(e) {
        console.log('Connection::Web socket connection closed: ', e);
        // ignore connection close if client requested closing connection
        // if (e.code !== 1006) {
        //     Utils.toast(String.format(a24n('Web Socket connection closed {0}'), e.reason || ''));
        // }

        var cC = this.closeCallbacks;
        if (cC) {
            for (var caller in cC) {
                cC[caller].forEach(cb => {
                    cb.callback.apply(cb.scope, [this, e]);
                }, this);
            }
        }

        this.clearConnectionParams();
    }

    /**
     * Executed on receiving data on socket <br/>
     * Calls callbacks set in this.messageCallbacks
     *
     * @param {Event} e MessageEvent
     */
    static onMessage(e) {
        // console.log('Connection::Received data from web socket: ' + e.data);
        var d = JSON.parse(e.data);

        var rC = this.requestCallbacks, rCD;
        if (rC && d.hasOwnProperty('type') && rC.hasOwnProperty(d.type)) {
            console.log('Connection::sendMessage - request ' + d.type + ' calling callback');
            rCD = rC[d.type];
            rCD.callback.apply(rCD.scope, [this, d]);
            delete rC[d.type];
        }

        var mC = this.messageCallbacks;
        if (mC) {
            for (var caller in mC) {
                mC[caller].forEach(cb => {
                    cb.callback.apply(cb.scope, [this, d]);
                }, this);
            }
        }
    }

    /**
     * Executed on error <br/>
     * Calls callbacks set in this.errorCallbacks
     *
     * @param {Event} e ErrorEvent
     */
    static onError(e) {
        console.log('Connection::Web socket connection error: ', e);
        // Utils.toast(a24n('Connection error'));
        Utils.dialog(a24n('Error'), a24n('Connection error'));

        var eC = this.errorCallbacks;
        if (eC) {
            for (var caller in eC) {
                eC[caller].forEach(cb => {
                    cb.callback.apply(cb.scope, [this, e]);
                }, this);
            }
        }

        this.clearConnectionParams();
    }

    /**
     * Acquire token needed for opening web socket connection <br/>
     * If Asseco.customer is defined it takes preceding over other adapters <br/>
     * If Asseco.config.authAdapter is defined and is available then it is used as adapter <br/>
     * If no config is defined then best suitable adapter is used if available in order LiveCheckIP, LiveSxS, LiveReCaptcha, LiveCaptcha
     */
    static acquireToken() {
        // we need to use LiveAuthUser adapter for customers
        if (Asseco.customer && typeof LiveAuthUser !== 'undefined') {
            console.log('Connection::acquireToken (LiveAuthUser)');
            new LiveAuthUser();

            return;
        }

        // we need to use LiveJWS adapter for jws tokens
        if (Asseco.jwsToken && typeof LiveJWS !== 'undefined') {
            console.log('Connection::acquireToken (LiveJWS)');
            new LiveJWS();

            return;
        }

        // first check if there is defined auth adapter in config and if its available
        if (Asseco.config.authAdapter) {
            switch (Asseco.config.authAdapter)
            {
            case 'LiveReCaptcha':
                if (typeof LiveReCaptcha !== 'undefined' && ! CmpMgr.hasByXtype(LiveReCaptcha.prototype.xtype) && ! Utils.isEmpty(Asseco.config.reCaptchaSitekey)) {
                    console.log('Connection::acquireToken (LiveReCaptcha)');
                    new LiveReCaptcha();
                } else {
                    // Utils.toast(String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveReCaptcha'));
                    Utils.dialog(a24n('Error'), String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveReCaptcha'));
                }
                return;

            case 'LiveCaptcha':
                if (typeof LiveCaptcha !== 'undefined' && ! CmpMgr.hasByXtype(LiveCaptcha.prototype.xtype)) {
                    console.log('Connection::acquireToken (LiveCaptcha)');
                    new LiveCaptcha();
                } else {
                    // Utils.toast(String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveCaptcha'));
                    Utils.dialog(a24n('Error'), String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveCaptcha'));
                }
                return;

            case 'LiveCheckIP':
                if (typeof LiveCheckIP !== 'undefined' && ! CmpMgr.hasByXtype(LiveCheckIP.prototype.xtype)) {
                    console.log('Connection::acquireToken (LiveCheckIP)');
                    new LiveCheckIP();
                } else {
                    // Utils.toast(String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveSxS'));
                    Utils.dialog(a24n('Error'), String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveCheckIP'));
                }
                return;

            case 'LiveSxS':
                if (typeof LiveSxS !== 'undefined' && ! CmpMgr.hasByXtype(LiveSxS.prototype.xtype)) {
                    console.log('Connection::acquireToken (LiveSxS)');
                    new LiveSxS();
                } else {
                    // Utils.toast(String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveSxS'));
                    Utils.dialog(a24n('Error'), String.format(a24n('Authorization adapter ({0}) not found or not configured'), 'LiveSxS'));
                }
                return;
            }
        }

        // if we didn't find configured adapter use most suitable adapter
        if (typeof LiveCheckIP !== 'undefined' && ! CmpMgr.hasByXtype(LiveCheckIP.prototype.xtype)) {
            console.log('Connection::acquireToken (LiveCheckIP)');
            new LiveCheckIP();
        } else if (typeof LiveSxS !== 'undefined' && ! CmpMgr.hasByXtype(LiveSxS.prototype.xtype)) {
            console.log('Connection::acquireToken (LiveSxS)');
            new LiveSxS();
        } else if (typeof LiveReCaptcha !== 'undefined' && ! CmpMgr.hasByXtype(LiveReCaptcha.prototype.xtype) && ! Utils.isEmpty(Asseco.config.reCaptchaSitekey)) {
            console.log('Connection::acquireToken (LiveReCaptcha)');
            new LiveReCaptcha();
        } else if (typeof LiveCaptcha !== 'undefined' && ! CmpMgr.hasByXtype(LiveCaptcha.prototype.xtype)) {
            console.log('Connection::acquireToken (LiveCaptcha)');
            new LiveCaptcha();
        }
    }

    /**
     * Add callback to execute on event name
     *
     * @param {Mixed} caller Reference to component that attached callback
     * @param {String} eventName Event name for attached callback
     * @param {Function} cb Callback function
     * @param {Object} scope Scope in which callback function will execute
     */
    static addCallback(caller, eventName, cb, scope) {
        if (this.eventNames.indexOf(eventName) === -1) {
            return;
        }

        // resolve caller
        if (typeof caller !== 'string') {
            caller = caller instanceof HTMLElement
                ? caller.id
                : caller.__proto__.xtype;
        }

        var evCbName = eventName + 'Callbacks';
        this[evCbName] = this[evCbName] || {};
        if (! this[evCbName].hasOwnProperty(caller)) {
            console.log('Connection::addCallback', caller, eventName);

            // allow only one event name per caller
            this[evCbName][caller] = [{callback: cb, scope: scope || window}];
        }
    }

    /**
     * Remove callback for given event name and caller
     *
     * @param {Mixed} caller Reference to component that attached callback
     * @param {String} eventName Event name for removing attached callbacks
     */
    static removeCallback(caller, eventName) {
        if (eventName && (this.eventNames.indexOf(eventName) === -1 || ! this[eventName + 'Callbacks'])) {
            return;
        }

        if (typeof caller !== 'string') {
            caller = caller instanceof HTMLElement
                ? caller.id
                : caller.__proto__.xtype;
        }

        var evCbName;

        // remove callbacks of given name for caller
        if (eventName) {
            console.log('Connection::removeCallback', caller, eventName);
            evCbName = eventName + 'Callbacks';
            if (this[evCbName].hasOwnProperty(caller)) {
                delete this[evCbName][caller];
            }
        }
        // remove all callback for caller
        else {
            console.log('Connection::removeCallback - ALL', caller);
            this.eventNames.forEach(evName => {
                evCbName = evName + 'Callbacks';
                if (this[evCbName] && this[evCbName].hasOwnProperty(caller)) {
                    delete this[evCbName][caller];
                }
            }, this);
        }
    }

    /**
     * Helper method for send message to chat server
     *
     * @param {String} t Message type
     * @param {Object} d Message data
     * @param {Function} cb Request callback
     * @param {Object} s Scope in which request callback will execute
     * @returns {Boolean} Is message sent to web socket
     */
    static sendMessage(t, d, cb, s) {
        if (cb) {
            console.log('Connection::sendMessage - request ' + t + ' added callback');
            this.requestCallbacks = this.requestCallbacks || {};
            this.requestCallbacks[t] = {callback: cb, scope: s || window};
        }

        return Connection.send(JSON.stringify({
            type: t,
            data: d || {}
        }));
    }

    /**
     * Apply connection config from global setting (Asseco.config) <br/>
     * Available config params are 'ChatServerUrl'
     */
    static applyConfig() {
        var c = Asseco.config || {};
        console.log('Connection::applyConfig', c);
        Utils.copyTo(Asseco, c, ['ChatServerUrl']);
    }
}
export default Connection;
