import Constants from 'data/Constants';
import Connection from 'data/Connection';
import 'util/String';
import 'util/Function';
import Element from 'util/Element';
import Utils from 'util/Utils';
import LiveList from 'widgets/list/List';

//////////////////////////////////////////////////
import LiveDirectMessagingNewWindow from 'components/directmessaging/new/New';
//////////

/**
 * LIVE Groups Card Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveGroups extends LiveList {
    /**
     * Holds configuration object for chat window
     *
     * @type {Object} winCfg
     */
    winCfg;

    /**
     * Specify media type which opened this window
     *
     * @type {String} mediaType
     */
    mediaType;

    /**
     * Specify which list to fetch (all/chat/audiochat/videochat)
     *
     * @type {String} list
     */
    list = 'all';

    /**
     * Specify chat window class to open
     *
     * @type {LiveCard} chatWindowClass
     */
    chatWindowClass;

    /**
     * Holds the skill group ID for automatic enqueue without showing list
     *
     * @type {String} autoGroupId
     */
    autoGroupId;

    /**
     * Holds id of group we issued call
     *
     * @private {String} groupId
     */
    groupId;

    /**
     * Holds name of group we issued call
     *
     * @private {String} groupName
     */
    groupName;

    /**
     * Hold get queue order interval id
     *
     * @private {Number} getQueueOrderInterval
     */
    getQueueOrderInterval;

    /**
     * Is enqueue active
     *
     * @private {Boolean}
     */
    enqueued;

    /**
     * Chat info message recevied (in case we get it before CONNECTED signal)
     *
     * @private {Object} chatInfoMsg
     */
    chatInfoMsg;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config) {
        config = config || {};

        // apply default config if not specified
        Utils.applyIf(config, {
            id            : 'asseco-groups-window',
            title         : a24n('Available groups'),
            enableRefresh : false
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Groups.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        // add callback for web socket message
        Connection.addCallback(this, 'message', this.onMessageReceived, this);

        super.afterRender();

        // ensure css class for asseco group list
        Element.addClass(this.containerEl, 'asseco-list-groups');
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        super.beforeDestroy();

        if (this.enqueued) {
            this.cancelCallGroup();
        }
        this.queueOrderCheck(false);

        return true;
    }

    /**
     * Get list of skill groups
     */
    fetchList() {
        super.fetchList();

        if (this.list !== 'all') {
            this.showMask();

            console.log('LiveGroups::Fetching list');
            Connection.sendMessage(Constants.REQ_GET_SKILL_GROUP_LIST, {
                // for audiochat list we call same URL as for videochat but later we'll filter only audio groups
                list: this.list === 'audiochat' ? 'videochat' : this.list
            }, this.onFetchList, this);
        }
        // else {
        //     Utils.toast(a24n('Unexpected param'));
        // }
    }

    /**
     * Executed after list of skill groups is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onFetchList(conn, msg) {
        var gs = JSON.parse(msg.data);

        // do auto enqueue to given skill group id of exist in received list
        if (! Utils.isEmpty(gs) && Array.isArray(gs) && ! Utils.isEmpty(this.autoGroupId)) {
            var auto = false;
            gs.some(function (g) {
                if (g._id === this.autoGroupId && g.working) {
                    auto = true;
                    console.log('LiveGroups::Do auto skill group enqueue to: ' + (this.mediaType + '_' + g._id));
                    this.onItemClick.defer(50, this, [null, {id: 'g__' + (this.mediaType + '_' + g._id), name : g.name}]);
                    return true;
                }
            }, this);

            // if auto skill group is not found show list
            if (! auto) {
                this.addGroups(gs);
            }
        } else {
            this.addGroups(gs);
        }
        this.hideMask();
    }

    /**
     * Show loading mask for queue
     *
     * @param {String} msg Mask message
     */
    showGroupMask(msg) {
        var showDm = typeof Asseco.LiveDirectMessaging !== 'undefined'
                 && typeof LiveDirectMessagingNewWindow !== 'undefined'
                 && Asseco.customer
                 && Asseco.config.LiveDirectMessaging
                 && Asseco.config.LiveDirectMessaging.hasOwnProperty('enabled')
                 && Asseco.config.LiveDirectMessaging.enabled;

        this.setContent(require('babel-loader!template-string-loader!./GroupsMask.html')({
            message    : msg,
            cancelText : a24n('Cancel'),
            dm         : showDm,
            dmText     : a24n('Leave a message')
        }));

        var c = this.getEl('.asseco-group-mask-cancel > a');
        if (c) {
            c.onclick = this.cancelCallGroup.createDelegate(this, [c], true);
        }

        var dm = this.getEl('.asseco-group-mask-dm > a');
        if (dm) {
            dm.onclick = () => {
                new LiveDirectMessagingNewWindow({
                    onSuccess: () => {
                        this.cancelCallGroup.defer(1000, this);
                    }
                });
            };
        }

        var masMsgEl = this.getEl('.asseco-group-mask-message');

        // find element in which group name is displayed
        var gN = masMsgEl.querySelector('.groupName');
        if (gN) {
            gN.textContent = this.groupName;
        }

        // find element in which wait order is displayed
        var wO = masMsgEl.querySelector('.waitOrder');
        if (wO) {
            wO.innerHTML = Utils.getSpinner();
        }
    }

    /**
     * Show fetched group list
     *
     * @param {Array|Object} gs Item to be added to group list
     */
    addGroups(gs) {
        // if we didn't get array we asume its object with error message
        if (! Array.isArray(gs)) {
            this.listEl.innerHTML = gs.msg || a24n('Unknown error');
            return;
        }

        // no groups fetched
        if (Utils.isEmpty(gs)) {
            this.noGroups();
            return;
        }

        // for audiochat list we use same media type as for videochat but we use different filter for groups
        var list = this.list === 'audiochat' ? 'videochat' : this.list;

        var gItms = [], useGroup, day = new Date().getDay(), wI;
        gs.forEach(gr => {
            useGroup = list !== 'videochat' || (this.list === 'audiochat' && gr.audioOnly) || (this.list === 'videochat' && ! gr.audioOnly);
            if (useGroup) {
                wI = gr.hasOwnProperty('workingTimePerDay') && gr.workingTimePerDay.hasOwnProperty(day) ? gr.workingTimePerDay[day] : null;
                gItms.push({
                    id   : list.toUpperCase() + '_' + gr._id,
                    name : String.format(
                        '{0}{1}',
                        gr.name,
                        gr.working ? '' : (wI ? String.format(' (' + a24n('Not working') + ' {0}-{1})', wI.from, wI.until) : '')
                    ),
                    itemCls: gr.working ? '' : ' disabled '
                });
            }
        }, this);

        // add working groups to list
        this.addItem(gItms);
    }

    /**
     * Executed if no groups are fetched
     *
     * @private
     */
    noGroups() {
        var mE = '';
        if (typeof LiveDirectMessagingNewWindow !== 'undefined' && Asseco.customer) {
            mE = '<div class="asseco-absolute-center asseco-group-mask-dm"><a class="mdl-button mdl-button--colored mdl-button--raised">' + a24n('Leave a message') + '</a></div>';
        }

        this.listEl.innerHTML = a24n('No available groups') + mE;

        var dm = this.getEl('.asseco-mask-message .asseco-group-mask-dm > a');
        if (dm) {
            dm.onclick = this.onLeaveMessage.createDelegate(this);
        }
    }

    /**
     * Executed on list item click
     * (issue call (enqueue) to choosen skill group)
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} el Clicked HTML Element
     * @private
     */
    onItemClick(e, el) {
        console.log('LiveGroups::Issuing enqueue to group: ', el);
        if (Connection.getStatus() === Connection.OPEN) {
            var gData = el.id.split('__');

            this.groupId = Utils.capitalize(gData[1]);
            this.groupName = el.name || el.querySelector('span.asseco-list-item-label').innerHTML;

//////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////
            this.showGroupMask(this.groupName);
            Connection.sendMessage(Constants.REQ_ENQUEUE, {
                mediaType   : this.mediaType,
                sgid        : this.groupId,
                displayName : Asseco.displayName
            }, this.onEnqueue, this);
//////////////////////
        }
        // else {
        //     Utils.toast(a24n('Not connected'));
        // }
    }

//////////////////////////////////////////
///////
/////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
///////////////
///////
//////////////////////////////////////
/////////////////////////////////////
////////
////////////////////////
////////////////////////

//////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
///////////////////////////////////
///////////////////////////////////
////////////////
/////////////////////////////////////
/////////
/////

///////
////////////////////////////
//////
///////////////
///////
/////////////////////////
////////////////////
///////////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////
////////////////////////////////////////////
/////////////////////////////////
/////
//////////////

    /**
     * Executed after call is enqueued
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onEnqueue(conn, msg) {
        var d = JSON.parse(msg.data);
        if (d.status === 'enqueued') {
            console.log('LiveGroups::Skill group enqueued (' + d.id + ')');

            // set enqueued flag
            this.enqueued = true;

            Connection.sendMessage(Constants.REQ_GET_QUEUE_ORDER, null, this.onQueueOrderCheck, this);
            this.queueOrderCheck(true);
        }
    }

    /**
     * Cancel call to group (close web socket connection)
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} el Clicked HTML element
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    cancelCallGroup(e, el) {
        console.log('LiveGroups::Issuing dequeue to group');
        if (Connection.getStatus() === Connection.OPEN) {
            Connection.sendMessage(Constants.REQ_DEQUEUE, null, this.onDequeue, this);
        }
        // else {
        //     Utils.toast(a24n('Not connected'));
        // }
    }

    /**
     * Executed after call is dequeued
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onDequeue(conn, msg) {
        var d = JSON.parse(msg.data);
        if (d.status === true) {
            // Utils.toast(a24n('Dequeued'));

            // set enqueued flag
            this.enqueued = false;

            // cancel queue order check
            this.queueOrderCheck(false);

            // close window
            this.hide(true);
        }
        // else {
        //     Utils.toast(a24n('Problem with dequeue'));
        // }
    }

    /**
     * Start / Stop queue order check
     *
     * @param {Boolean} s Sholud check be started or stoped
     * @param {Number} i Interval (in ms) for checking status
     */
    queueOrderCheck(s, i) {
        // start check interval
        if (s === true) {
            this.getQueueOrderInterval = setInterval(function () {
                if (! Connection.sendMessage(Constants.REQ_GET_QUEUE_ORDER, null, this.onQueueOrderCheck, this)) {
                    this.queueOrderCheck(false);
                }
            }.createDelegate(this), i || 3000);
        }
        // stop check interval
        else if (! Utils.isEmpty(this.getQueueOrderInterval)) {
            clearInterval(this.getQueueOrderInterval);
            this.getQueueOrderInterval = null;
        }
    }

    /**
     * Executed after info about queue is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onQueueOrderCheck(conn, msg) {
        var d = JSON.parse(msg.data);
        var masMsgEl = this.getEl('.asseco-group-mask-message');
        if (masMsgEl) {
            // find element in which wait order is displayed
            if (d.hasOwnProperty('order')) {
                var wO = masMsgEl.querySelector('.waitOrder');
                if (wO) {
                    wO.textContent = d.order;
                    var tTxt = String.format(a24n('You are currently {0} in line'), d.order);

                    // set tooltip for wait order
                    if (Utils.isEmpty(wO.id)) {
                        let wOId = Utils.generateUUID(5, 'id_');
                        wO.id = wOId;
                        wO.style.cursor = 'pointer';

                        this.tips.push(Utils.tooltip(wOId, tTxt));
                    }
                    // update tooltip
                    else {
                        document.querySelector('.mdl-tooltip[for=' + wO.id + ']').innerHTML = tTxt;
                    }
                }
            }

            // find element in which wait expectancy is displayed
            if (d.hasOwnProperty('waitExpectancy')) {
                var wE = masMsgEl.querySelector('.waitExpectancy');
                if (wE) {
                    var t = parseInt(d.waitExpectancy, 10),
                        m = '0' + Math.floor(t / 60) ,
                        s = '0' + (t - m * 60);

                    wE.textContent = m.substr(-2) + 'm ' + s.substr(-2) + 's';

                    // set tooltip for wait expectancy
                    if (Utils.isEmpty(wE.id)) {
                        let wEId = Utils.generateUUID(5, 'id_');
                        wE.id = wEId;
                        wE.style.cursor = 'pointer';

                        this.tips.push(Utils.tooltip(wEId, a24n('Wait expectancy to reach agent')));
                    }
                }
            }
        }
    }

    /**
     * Executed when message is received on web socket
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onMessageReceived(conn, msg) {
        switch (msg.type)
        {
        case Constants.MSG_CAT_INFO:
            this.chatInfoMsg = msg.data;
            break;

        case Constants.MSG_CAT_SIGNAL:
            // handle CONNECTED signal - open chat window
            if (msg.data === Constants.SIGNAL_CONNECTED) {
                var chatInfoMsg = this.chatInfoMsg;

                // set enqueued flag
                this.enqueued = false;

                // cancel queue order check
                this.queueOrderCheck(false);

                this.hide(true);

                // show chat window
                new this.chatWindowClass(Utils.applyIf({
                    chatInfoMsg : chatInfoMsg,
                    initMessage : '',
                    agent       : '',
                    group       : this.groupName
                }, this.winCfg || {}));
            }
            // handle DISCONNECTED signal
            else if (msg.data === Constants.SIGNAL_DISCONNECTED) {
                // set enqueued flag
                this.enqueued = false;

                // cancel queue order check
                this.queueOrderCheck(false);

                this.hide(true);

                // Utils.toast(a24n('Disconnected'));
            }
            break;
        }
    }
}
LiveGroups.prototype.xtype = 'LiveGroups';
export default LiveGroups;
