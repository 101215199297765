module.exports=function(scope){ return `<div style="padding-bottom: 10px;">${scope.msg}</div>

${scope.pass}
${scope.otp}

<div class="asseco-rds-fRight" style="margin-top: 65px;">
    <a id="${scope.forgetLinkId}" tabindex="-1" class="asseco-link" style="font-size: 0.8rem;">${scope.forgetLinkLabel}</a>
</div>

<div id="${scope.errTxtId}" class="mdl-textfield asseco-rds-fMiddle" style="margin-top: 15px;">
    <span class="mdl-textfield__error"></span>
</div>`};