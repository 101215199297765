/**
 * Component manager
 *
 * @private
 */
class ComponentManager {
    /**
     * Holds collection of rendered components
     *
     * @public {Object} components
     */
    static components = {};

    /**
     * Holds collection of xtype mapped to component constructor
     *
     * @public {Object} xtypes
     */
    static xtypes = {};

    /**
     * Register xtype for component
     *
     * @param {String} x
     * @param {Function} c
     */
    static registerXtype(x, c) {
        this.xtypes[x] = c;
    }

    /**
     * Get registered component by xtype and id
     *
     * @param {String} xtype Xtype of the component
     * @param {String} id ID of the component
     * @return {Object|null} Reference to found component or null
     */
    static get(xtype, id) {
        return this.components.hasOwnProperty(xtype + '_' + id) ? this.components[xtype + '_' + id] : null;
    }

    /**
     * Get first registred component that matches given xtype
     *
     * @param {String} xtype Xtype of the component for search
     * @return {Object|null} Reference to found component in collection or null
     */
    static getByXtype(xtype) {
        for (let p in this.components) {
            if (p.indexOf(xtype) > -1) {
                return this.components[p];
            }
        }
        return null;
    }

    /**
     * Check if component is registered by xtype and id
     *
     * @param {String} xtype Xtype of the component
     * @param {String} id ID of the component
     * @returns {Boolean} Return true if component with given xtype and ID is found in collection else false
     */
    static has(xtype, id) {
        return ComponentManager.get(xtype, id) !== null;
    }

    /**
     * Check if component is registered by xtype
     *
     * @param {String} xtype Xype of the component
     * @returns {Boolean} Return true if component with given xtype is found in collection
     */
    static hasByXtype(xtype) {
        return ComponentManager.getByXtype(xtype) !== null;
    }

    /**
     * Register component by xtype and id
     *
     * @param {String} xtype Xtype of the component
     * @param {String} id ID of the component
     * @param {Object} cmp Reference to the component that will be added to collection
     */
    static register(xtype, id, cmp) {
        this.components[xtype + '_' + id] = cmp;
        console.log('ComponentManager::registered - ' + xtype + ' (' + id + ')');
    }

    /**
     * Unregister component by xtype and id
     *
     * @param {String} xtype Xtype of the component
     * @param {String} id ID of the component
     */
    static unregister(xtype, id) {
        if (this.components.hasOwnProperty(xtype + '_' + id)) {
            delete this.components[xtype + '_' + id];
            console.log('ComponentManager::unregistered - ' + xtype + ' (' + id + ')');
        }
    }

    /**
     * Change enable/disable status for list of components
     *
     * @param {Array} c List of components xtype names
     * @param {String} s Status to update (enable/disable)
     * @private
     */
    static updateComponents(c, s) {
        c.forEach(cp => {
            let cmp = ComponentManager.getByXtype(cp);
            if (cmp && typeof cmp[s] === 'function') {
                cmp[s]();
            }
        });
    }

    /**
     * Bring component with given id to front (set high z-index)
     *
     * @param {String} id Component id
     */
    static bringToFront(id) {
        for (let p in this.components) {
            if (this.components.hasOwnProperty(p) && this.components[p].containerEl) {
                let cEl = this.components[p].containerEl;
                cEl.style.zIndex = cEl.id === id ? 50050 : 50000;
            }
        }
    }
}
export default ComponentManager;
