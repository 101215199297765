/*global encodeURI*/

import Utils from 'util/Utils';
import Connection from 'data/Connection';
import LiveField from 'widgets/field/Field';
import LiveCard from 'widgets/card/Card';

///////////////////////
/////////////////////////////
//////////

/**
 * LIVE Captcha Card Component (not available in Asseco namespace)
 *
 * This component authenticate anonymouse user who needs to solve captcha
 *
 * @private
 */
class LiveCaptcha extends LiveCard {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-captcha',
            title          : a24n('Captcha validation'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        this.buttons = [{
            text: a24n('Submit'),
            scope: this,
            handler: this.onValidateCaptcha.createDelegate(this)
        }];

        super.initComponent();
    }

    /**
     * Return content for card
     *
     * @private
     */
    getContentHtml() {
        var tF = LiveField.getTextField;

        return require('babel-loader!template-string-loader!./Captcha.html')({
            fName : tF({
                id       : 'asseco-captcha-fullName',
                label    : a24n('Display Name'),
                value    : Asseco.displayName || '',
                required : true
            }),

            fImgId        : 'asseco-captcha-captcha',
            fImgLabel     : a24n('Write the following word'),
            fImgLinkLabel : a24n('Not readable? Change text'),

            fSolution : tF({
                id         : 'asseco-captcha-solution',
                label      : a24n('Write here'),
                cls        : 'asseco-textfield-lowercase',
                required   : true,
                inputAttrs : 'autocomplete="off"'
            })
        });
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Captcha.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        this.getEl('#asseco-captcha-captcha-change').onclick = this.changeCaptcha.createDelegate(this);

        // register dynamic material design components
        this.cHU(this.getEl('#asseco-captcha-fullName'));
        this.cHU(this.getEl('#asseco-captcha-solution'));

        // attach keyup event to input element for sending catpcha
        this.getEl('#asseco-captcha-solution').onkeyup = (e) => {
            // send message on ENTER
            if (e.keyCode === 13  && ! e.shiftKey) {
                var msg = e.target.value.trim();
                if (! Utils.isEmpty(msg)) {
                    this.onValidateCaptcha();
                }
            }
        };

        this.changeCaptcha.defer(150, this);
    }

    /**
     * Generate new captcha image
     *
     * @private
     */
    changeCaptcha() {
        this.getEl('#asseco-captcha-captcha').src = Asseco.ChatServerUrl + '/authAdapters/captcha/?uuid=' + Asseco.UUID + '&t=' + Date.now();
        this.resetFields();
    }

    /**
     * Reset textfields and change captcha image
     *
     * @param {Boolean} all Should we reset all fields (regenerate captcha image)
     * @private
     */
    resetFields(all) {
        var cEl = this.getEl('#asseco-captcha-solution-input');
        cEl.value = '';
        cEl.focus();
        cEl.blur();

        if (all === true) {
            var fNEl = this.getEl('#asseco-captcha-fullName-input');
            fNEl.value = '';
            fNEl.focus();
            fNEl.blur();

            this.changeCaptcha();
        }
    }

    /**
     * Validate captcha
     *
     * @private
     */
    onValidateCaptcha() {
        var fullNameEl = this.getEl('#asseco-captcha-fullName-input'),
            fullNameVal = fullNameEl.value,

            captchaVal = this.getEl('#asseco-captcha-solution-input').value;

        if (Utils.isEmpty(fullNameVal) || Utils.isEmpty(captchaVal)) {
            if (Utils.isEmpty(fullNameVal)) {
                fullNameEl.focus();
                fullNameEl.blur();
            }
            return;
        }

        // var url = Asseco.ChatServerUrl + '/authAdapters/captcha/?validate&uuid=' + Asseco.UUID + '&captcha=' + captchaVal + '&displayName=' + fullNameVal;
        var url = Asseco.ChatServerUrl + '/authAdapters/captcha/?validate';

        var postData = Asseco.contextData || {};
        postData.uuid = Asseco.UUID;
        postData.captcha = captchaVal;
        postData.displayName = fullNameVal;

        // Utils.toast(a24n('Verifying user...'), 5000);
        fetch(encodeURI(url ), {
            method: 'post',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                if (res.success) {
                    // Utils.toast(null);

                    // set display name to Asseco namespace
                    Asseco.displayName = fullNameVal;

///////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////
//////////////////////////////

                    console.log('LiveCaptcha::Got ws url: ' + res.wsUrl);
                    Connection.wsUrl = res.wsUrl;
                    Connection.connect();

                    this.resetFields(true);
                    this.hide(true);
                }
                else {
                    // Utils.toast(a24n('Error verifying user'));
                    Utils.dialog(a24n('Error'), a24n('Error verifying user'));
                    this.resetFields();
                }
            })
            .catch((err) => {
                // Utils.toast(String.format(a24n('Error fetching token: {0}'), err.message));
                Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }
}
LiveCaptcha.prototype.xtype = 'LiveCaptcha';
export default LiveCaptcha;
