import Constants from 'data/Constants';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveChat from './../chat/index';
import Svgs from 'data/Svgs';

////////////////////////////
import LiveVideoChatWindowIM from './window/WindowIM';
//////////

////////////////////////////
//////////////////////////////////////////////////////
//////////

/**
 * LIVE VideoChat Component (available in Asseco namespace) <br/>
 * Component is used for making video call using WebRTC
 *
 * @example
 * var aACh = new Asseco.LiveVideoChat({
 *     deferShow: 500,
 *     position: {
 *         position: 'absolute',
 *         top: '255px',
 *         right: '25px'
 *     },
 *     icon: 'custom icon - SVG path or IMG src',
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 * });
 */
class LiveVideoChat extends LiveChat {
////////////////////////////////
    /**
     * Specify chat window class to open
     *
     * @private {LiveVideoChatWindowIM} chatWindowClass
     */
    chatWindowClass = LiveVideoChatWindowIM;
//////////////

////////////////////////////////
///////
////////////////////////////////////////
//////
///////////////////////////////////////////////////////
///////
////////////////////////////////////////////
//////////////

    /**
     * Holds media type of this component
     *
     * @private {String} mediaType
     */
    mediaType = Constants.MEDIA_TYPE_VIDEOCHAT;

    /**
     * List of groups/plans to fetch
     *
     * @private {String} list
     */
    list = 'videochat';

    /**
     * Holds info if this component is supported
     *
     * @private {Boolean} isSupported
     */
    isSupported;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier      : 'videochat',
            label           : a24n('Start Video Chat'),
            icon            : Svgs.VIDEO
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        this.isSupported = require('webrtc-adapter').default.browserDetails.browser !== 'Not a supported browser.';

        // check if WebRTC is supported on this browser
        if (! this.isSupported) {
            this.disable();
            document.querySelector('.mdl-tooltip[for=' + this.id + ']').innerHTML = a24n('Not Supported');
        }

        if (this.isSupported && this.identifier === 'videochat' && this.isCalendarEventGiven()) {
            this.disable();
            this.getCalendarEventInfo();
        }
    }

    /**
     * Enable component if disabled (enable is not possible if WebRTC is not supported)
     */
    enable() {
        if (! this.isSupported) {
            return;
        }

        super.enable();
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Check if event parameter is given
     */
    isCalendarEventGiven() {
        var url = new URL(location.href);
        return !!url.searchParams.get('event');
    }

    /**
     * Makes videochat call with given calendar event info
     */
    getCalendarEventInfo() {
        var url = new URL(location.href);
        var eventId = url.searchParams.get('event');

        console.log(this.__proto__.xtype + '::getCalendarEventInfo - ' + eventId);
        fetch(Asseco.ChatServerUrl + '/server/chat-calendar.php?event=' + eventId)
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                this.enable();
                if (res.success) {
                    Asseco.customer = {customer_lr: res.data.customer_id};

                    if (res.data.sgId) {
                        this.sgID = res.data.sgId;
                    } else if (res.data.planTo) {
                        this.autoNumber = res.data.planTo;
                    }

                    if (res.data.agent) {
                        this.reservedAgent = res.data.agent;
                    }

                    this.onClick();
                }
                else {
                    Utils.dialog(a24n('Calendar Info'), res.data);
                }
            })
            .catch((err) => {
                this.enable();
                Utils.toast(String.format(a24n('Error fetching Calendar Event info: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }
}
LiveVideoChat.prototype.xtype = 'LiveVideoChat';
CmpMgr.registerXtype(LiveVideoChat.prototype.xtype, LiveVideoChat);
export default LiveVideoChat;
