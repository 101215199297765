/* global getmdlSelect */
import Utils from 'util/Utils';
import LiveField from 'widgets/field/Field';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE Meeting Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveMeetingWindow extends LiveCard {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-meeting-window',
            title          : a24n('Meeting Request'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        this.buttons = [{
            text: a24n('Submit'),
            scope: this,
            handler: this.onSaveMeeting.createDelegate(this)
        }];

        super.initComponent();
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Return content for card
     *
     * @return {String}
     * @private
     */
    getContentHtml() {
        var tF = LiveField.getTextField,
            aF = LiveField.getTextareaField,
            tS = LiveField.getSelectField;

        return require('babel-loader!template-string-loader!./Window.html')({
            fClient : tF({
                id       : 'asseco-meeting-client',
                label    : a24n('Client'),
                required : true
            }),

            fBranch : tS({
                id    : 'asseco-meeting-branch',
                label : a24n('Branch'),
                value : 'Branch 1',
                items : ['Branch 1', 'Branch 2', 'Branch 3']
            }),

            fDate : tF({
                id       : 'asseco-meeting-date',
                label    : a24n('Meeting Date'),
                required : true
            }),

            fTerms : tS({
                id    : 'asseco-meeting-term',
                label : a24n('Free Terms'),
                items : ['10:00 - 11:00', '14:00 - 15:00', '15:00 - 16:00']
            }),

            fComment : aF({
                id    : 'asseco-meeting-comment',
                label : a24n('Comment')
            })
        });
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // register dynamic material design components
        getmdlSelect.init('#asseco-meeting-branch');
        this.cHU(this.getEl('#asseco-meeting-date'));
        getmdlSelect.init('#asseco-meeting-term');
    }

    /**
     * Executed on meeting save
     *
     * @private
     */
    onSaveMeeting() {
    }
}
LiveMeetingWindow.prototype.xtype = 'LiveMeetingWindow';
export default LiveMeetingWindow;
