/* global getmdlSelect */

import Svgs from 'data/Svgs';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import Element from 'util/Element';
import LiveField from 'widgets/field/Field';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE Direct Messaging New Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveDirectMessagingNewWindow extends LiveCard {
    /**
     * Holds list of groups
     *
     * @private {Array} groups
     */
    groups;

    /**
     * Prefix for elements ids
     *
     * @type {String} pf
     * @private
     */
    pf;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-directmessaging-new-window',
            title          : a24n('New message'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        // save prefix for elements ids
        this.pf = 'asseco-directmessaging-';

        // add buttons
        this.buttons = [{
            text: a24n('Send'),
            scope: this,
            handler: this.sendMessage
        }, {
            text: a24n('Close'),
            scope: this,
            handler: this.hide.createDelegate(this, [true], false)
        }];

        super.initComponent();
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // register dynamic material design components
        this.cHU(this.getEl('#' + this.pf + 'title'));
        this.cHU(this.getEl('#' + this.pf + 'message'));

        // fetch message body
        this.fetchGroups();
    }

    /**
     * Fetch Message body
     *
     * @private
     */
    fetchGroups() {
        this.showMask();

        console.log('LiveDirectMessagingNewWindow::Fetch groups');
        Connection.sendMessage(Constants.REQ_DIRECT_MESSAGE_GROUP, null, this.onFetchGroups, this);
    }

    /**
     * Executed after message body is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onFetchGroups(conn, msg) {
        var d = JSON.parse(msg.data);

        this.hideMask();
        this.groups = d.data;

        var g = '', iD, iN, day = new Date().getDay(), wI;
        this.groups.forEach(gr => {
            iD = gr.working ? '' : ' disabled ';
            wI = gr.groupWorkTime.results.hasOwnProperty(day) ? gr.groupWorkTime.results[day] : null;
            iN = gr.working ? gr.name :
                (
                    gr.name +
                    String.format(
                        ' ({0}{1}) ',
                        a24n('Not working'),
                        wI ? (String.format(' {0}-{1}', wI.from, wI.until)) : ''
                    )
                );

            g += '<li class="mdl-menu__item" ' + iD + ' data-val="' + gr.id + '">' + iN + '</li>';
        }, this);
        this.getEl('#' + this.pf + 'group ul').innerHTML = g;

        // init select after filling with values
        getmdlSelect.init('#' + this.pf + 'group');
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./New.scss');
    }

    /**
     * Return content for card
     *
     * @return {String}
     * @private
     */
    getContentHtml() {
        var tF = LiveField.getTextField,
            aF = LiveField.getTextareaField,
            tS = LiveField.getSelectField;

        return require('babel-loader!template-string-loader!./New.html')({
            uploadIcon : Utils.getIconMarkup(Svgs.UPLOAD, '', '', '#000'),

            fSubject : tS({
                id      : this.pf + 'group',
                label   : a24n('Subject'),
                require : true,
                value   : '',
                items   : []
            }),

            fTitle : tF({
                id       : this.pf + 'title',
                label    : a24n('Title'),
                required : true
            }),

            fMsg : aF({
                id       : this.pf + 'message',
                label    : a24n('Message'),
                required : true,
                rows     : 5
            })
        });
    }

    /**
     * Executed on message send
     *
     * @private
     */
    sendMessage() {
        var els = {
            'group'   : '',
            'title'   : '',
            'message' : ''
        };

        var req = true, input, val;

        // get reference to each el
        for (var prop in els) {
            els[prop] = this.getEl('#' + this.pf + prop);

            input = els[prop].MaterialTextfield ? els[prop].MaterialTextfield.input_ : els[prop];
            if (Element.hasClass(els[prop], 'getmdl-select')) {
                val = els[prop].querySelector('input[name=' + this.pf + prop + '-input]').value;
            } else {
                val = input.value;
            }

            // save value to elements custom property
            els[prop].myVal = val;

            // check required
            if (['group', 'title', 'message'].indexOf(prop) > -1 && Utils.isEmpty(val)) {
                req = false;
                input.focus();
                input.blur();

                Element.addClass(els[prop], 'is-invalid');
            }
            else {
                Element.removeClass(els[prop], 'is-invalid');
            }
        }

        // missing required fields
        if (! req) {
            return false;
        }

        this.showMask();
        console.log('LiveDirectMessagingNewWindow::Send message');
        Connection.sendMessage(Constants.REQ_DIRECT_MESSAGE_NEW, {
            group   : els.group.myVal,
            title   : els.title.myVal,
            message : els.message.myVal
        }, this.onSendMessage, this);
    }

    /**
     * Executed after message is sent
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onSendMessage(conn, msg) {
        this.hideMask();

        var d = JSON.parse(msg.data);
        if (d.success) {
            Utils.toast('Message sent');

            if (this.onSuccess) {
                this.onSuccess();
            }

            this.onClose();
        }
        else {
            // Utils.toast(String.format(a24n('Problem sending message ({0})'), d.msg));
            Utils.dialog(a24n('Error'), String.format(a24n('Problem sending message ({0})'), d.msg));

            if (this.onFailure) {
                this.onFailure();
            }
        }
    }

    /**
     * Executed when close button is clicked
     */
    onClose() {
        // reload list window before closing preview window
        var c = CmpMgr.getByXtype('LiveDirectMessagingWindow');
        if (c) {
            c.fetchList();
        }

        super.onClose();
    }
}
LiveDirectMessagingNewWindow.prototype.xtype = 'LiveDirectMessagingNewWindow';
export default LiveDirectMessagingNewWindow;
