import Svgs from 'data/Svgs';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import Element from 'util/Element';
import LiveFab from 'widgets/fab/Fab';
import LiveGroups from 'widgets/groups/Groups';
import LiveChatConsentWindow from './window/Consent';

////////////////////////////
////////////////////////////////////////////
//////////

//////////////////////////////////////////////
import LiveFilemanagerWindow from 'components/filemanager/window/Window';
//////////

////////////////////////////
import LiveChatWindowIM from './window/WindowIM';
//////////

////////////////////////////
/////////////////////////////////////////////////
//////////

/**
 * LIVE Chat Component - xtype: LiveChat - (available in Asseco namespace) <br/>
 * Component is used for online chat with agent in LIVE
 *
 * @example
 * var aCh = new Asseco.LiveChat({
 *     deferShow: 500,
 *     position: {
 *         position: 'absolute',
 *         top: '125px',
 *         right: '25px'
 *     },
 *     icon: 'custom icon - SVG path or IMG src',
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 * });
 */
class LiveChat extends LiveFab {
////////////////////////////////
    /**
     * Holds the skill group ID for automatic enqueue without showing skill group list
     *
     * @type {String} sgID
     */
    sgID;
//////////////

////////////////////////////////
///////
/////////////////////////////////////////////////////////////////////
//////
////////////////////////////////
///////
///////////////
//////////////

    /**
     * Holds login name of an agent that needs to be reserved
     * 
     * @type {String} reservedAgent
     */
    reservedAgent;

    /**
     * Holds configuration object for queue window {@link LiveComponent}
     *
     * @type {Object} queueWinCfg
     */
    queueWinCfg;

    /**
     * Holds configuration object for chat window {@link LiveComponent}
     *
     * @type {Object} winCfg
     */
    winCfg;

////////////////////////////////
    /**
     * Specify chat window class to open
     *
     * @private {LiveChatWindowIM} chatWindowClass
     */
    chatWindowClass = LiveChatWindowIM;
//////////////

////////////////////////////////
///////
////////////////////////////////////////
//////
//////////////////////////////////////////////////
///////
///////////////////////////////////////
//////////////

    /**
     * Holds media type of this component
     *
     * @private {String} mediaType
     */
    mediaType = Constants.MEDIA_TYPE_CHAT;

    /**
     * List of groups/plans to fetch
     *
     * @private {String} list
     */
    list = 'chat';

    /**
     * Is this component available (working time)
     *
     * @private {Boolean} isWorking
     */
    isWorking = true;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier : 'chat',
            label      : a24n('Start Chat'),
            icon       : Svgs.CHAT
        });
        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        var req = null;
////////////////////////////////////
        if (! Utils.isEmpty(this.sgID)) {
            req = 'sgId=' + this.sgID;
        }
//////////////////
////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////
/////////
//////////////////

        if (req) {
            console.log(this.__proto__.xtype + '::starting check working interval');

            // create interval for checking working time
            this.checkWorkingInterval = setInterval(() => {
                this.checkWorking(req);
            }, Asseco.config.workingPingTime || 60000);

            // check working for the first time
            this.checkWorking(req);
        }
    }

    /**
     * Check working time
     *
     * @param {String} req
     * @private
     */
    checkWorking(req) {
        if (! req) {
            return;
        }

        console.log(this.__proto__.xtype + '::checkWorking - ' + req);
        fetch(Asseco.ChatServerUrl + '/server/chat-worktime.php?mediaType=' + this.mediaType + '&' + req)
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                if (res.success) {
                    if (! res.data.working) {
                        this.disable();
                        Element.addClass(this.containerEl, 'not-working');

                        var day = new Date().getDay(),
                            wI = res.data.workingTimePerDay.hasOwnProperty(day) ? res.data.workingTimePerDay[day] : null;

                        document.querySelector('.mdl-tooltip[for=' + this.id + ']').innerHTML =
                            String.format(
                                a24n('Not working ({0})'),
                                wI ? (String.format('{0}-{1}', wI.from, wI.until)) : ''
                            );
                    } else {
                        Element.removeClass(this.containerEl, 'not-working');
                        document.querySelector('.mdl-tooltip[for=' + this.id + ']').innerHTML = this.label;

                        var doEnable = true;
                        // don't enable component if other chat window exists
                        for (let c in CmpMgr.components) {
                            if (/Live(Chat|VideoChat|AudioChat)Window/g.test(CmpMgr.components[c].__proto__.xtype)) {
                                doEnable = false;
                            }
                        }
                        if (doEnable) {
                            this.enable();
                        }
                    }
                }
                else {
                    Utils.toast(String.format(a24n('Error fetching work time: {0}'), res.data));
                }
            })
            .catch((err) => {
                Utils.toast(String.format(a24n('Error fetching work time: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        // remove chat working interval
        if (this.checkWorkingInterval) {
            clearInterval(this.checkWorkingInterval);
        }

        // destroy chat window if available
        var chatWin = CmpMgr.getByXtype(this.chatWindowClass.prototype.xtype);
        if (chatWin) {
            chatWin.destroy();
        }

        return super.beforeDestroy();
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onClick(event) {
        if (this.disabled) {
            return;
        }

        // if web socket connection is not opened we need to establish connection first
        if (Connection.getStatus() !== Connection.OPEN) {
            this.checkConsent();
        }
        else {
            this.onConnectionOpen();
        }
    }

    /**
     * Check if consent window needs to be show before opening connection
     * 
     * @private
     */
    checkConsent() {
        if (Asseco.config.showChatConsentWindow === false) {
            this.openConnection();
            return;
        }

        // dont open consent window if already opened
        if (CmpMgr.has(LiveChatConsentWindow.prototype.xtype, 'asseco-chat-consent-window')) {
            return;
        }

        var self = this;
        new LiveChatConsentWindow({
            onContinue: function () {
                self.openConnection();
                this.hide(true);
            }
        });
    }

    /**
     * Open web socket connection to server
     * 
     * @private
     */
    openConnection() {
        // add callback for web socket connection open
        Connection.addCallback(this, 'open', this.onConnectionOpen, this);

        // renew user UUID
        Asseco.UUID = Utils.generateUUID();
        console.log('LiveChat::Regenerate Asseco UUID: ' + Asseco.UUID);

        Connection.wsUrl = null;
        Connection.targetEl = this.containerEl;
        Connection.connect();
    }

    /**
     * Executed on opening web socket connection
     *
     * @private
     */
    onConnectionOpen() {
        // eslint-disable-next-line no-unused-vars
        var filesWin;

        // show window chat if registered
        var chatWin = CmpMgr.getByXtype(this.chatWindowClass.prototype.xtype);
        if (chatWin) {
            chatWin.show();

            if (typeof chatWin.onRestore === 'function') {
                chatWin.onRestore();
            }
        }
////////////////////////////////////
        // show group list if not already rendered or do automatic enqueue to given skill group (if given)
        else if (! CmpMgr.hasByXtype(LiveGroups.prototype.xtype)) {
            console.log('LiveChat::onConnectionOpen - show available groups');

            // remove callback for web socket connection open
            Connection.removeCallback(this, 'open');

//////////////////////////////////////////////////////////
            // hide files window if opened
            filesWin = CmpMgr.getByXtype(LiveFilemanagerWindow.prototype.xtype);
            if (filesWin) {
                filesWin.hide(true);
            }
//////////////////////

            // open group list window
            // eslint-disable-next-line no-unused-vars
            new LiveGroups(Utils.applyIf({
                chatWindowClass : this.chatWindowClass,
                winCfg          : this.winCfg || {},
                mediaType       : this.mediaType,
                list            : this.list.toLowerCase(),
                itemIcon        : this.icon,
                autoGroupId     : this.sgID
            }, this.queueWinCfg || {}));
        }
//////////////////
////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////

//////////////////////////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
///////////////////////////
////////////////////////////////////
/////////////
//////////////////////

////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////////////////////
////////////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////
/////////
//////////////////

        Connection.sendMessage(Constants.REQ_INFO_LOG, 'User agent - ' + navigator.userAgent);

        var lang = 'en';
        if (Asseco.config.lang) {
            lang = Asseco.config.lang;
        }
        Connection.sendMessage(Constants.REQ_INFO_LANG, lang);
    }
}
LiveChat.prototype.xtype = 'LiveChat';
CmpMgr.registerXtype(LiveChat.prototype.xtype, LiveChat);
export default LiveChat;
