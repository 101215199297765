import Connection from 'data/Connection';
import 'util/Function';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveFab from 'widgets/fab/Fab';
import LiveDirectMessagingWindow from './window/Window';

/**
 * LIVE Direct Messaging Component - xtype: LiveDirectMessaging - (available in Asseco namespace) <br/>
 * Component is used for exchanging offline messages with agents in LIVE
 *
 * @example
 * var aDirect = new Asseco.LiveDirectMessaging({
 *     deferShow: 500,
 *     position: {
 *         position: 'absolute',
 *         top: '125px',
 *         right: '25px'
 *     },
 *     icon: 'custom icon - SVG path or IMG src',
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 * });
 */
class LiveDirectMessaging extends LiveFab {
    /**
     * Holds configuration object for main window {@link LiveComponent}
     *
     * @type {Object} winCfg
     */
    winCfg;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier : 'directmessaging',
            label      : a24n('Direct Messaging'),
            icon       : 'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} e ClickEvent
     * @private
     */
    onClick(e) {
        if (this.disabled) {
            return;
        }

        // by default show survey window
        var showWin = e.hasOwnProperty('showWin') ? e.showWin : true;

        // if web socket connection is not opened we need to establish connection first
        if (Connection.getStatus() !== Connection.OPEN) {
            // add callback for web socket connection open
            Connection.addCallback(this, 'open', this.onConnectionOpen.createDelegate(this, [showWin]), this);

            // renew user UUID
            Asseco.UUID = Utils.generateUUID();
            console.log('LiveDirectMessaging::Regenerate Asseco UUID: ' + Asseco.UUID);

            Connection.wsUrl = null;
            Connection.targetEl = this.containerEl;
            Connection.connect();
        }
        else {
            this.onConnectionOpen(showWin);
        }
    }

    /**
     * Executed on opening web socket connection
     *
     * @param {Boolean} showWin If window with message list sholud be shown after web socket open
     * @private
     */
    onConnectionOpen(showWin) {
        // show direct messages list if not already rendered
        if (! CmpMgr.hasByXtype(LiveDirectMessagingWindow.prototype.xtype)) {
            console.log('LiveDirectMessaging::onConnectionOpen - show direct messaging window');

            // remove callback for web socket connection open
            Connection.removeCallback(this, 'open');

            // open filemanager list window
            var win = new LiveDirectMessagingWindow(Utils.applyIf({
                renderHidden: true
            }, this.winCfg || {}));

            if (showWin) {
                win.show();
            }
        }
        else if (showWin) {
            CmpMgr.getByXtype(LiveDirectMessagingWindow.prototype.xtype).show();
        }
    }
}
LiveDirectMessaging.prototype.xtype = 'LiveDirectMessaging';
CmpMgr.registerXtype(LiveDirectMessaging.prototype.xtype, LiveDirectMessaging);
export default LiveDirectMessaging;
