import Constants from 'data/Constants';
import Connection from 'data/Connection';
import 'util/Function';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveChatWindowIM from 'components/chat/window/WindowIM';
import LiveChatVideo from './Video';

/**
 * LIVE Chat Winow Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveVideoChatWindowIM extends LiveChatWindowIM {
    /**
     * Not ready for establishing PeerConnection
     *
     * @type {Number}
     */
    static NOTREADY = -1;

    /**
     * PeerConnection status ready
     *
     * @type {Number}
     */
    static READY = 0;

    /**
     * PeerConnection status connecting
     *
     * @type {Number}
     */
    static CONNECTING = 1;

    /**
     * PeerConnection status connected
     *
     * @type {Number}
     */
    static CONNECTED = 2;

    /**
     * Specify if video stream is enabled
     *
     * @type {Boolean} videoEnabled
     */
    videoEnabled;

    /**
     * Is muted signal shown to client
     *
     * @type {Boolean} showMutedSignal
     */
    showMutedSignal;

    /**
     * Holds the reference to window video component
     *
     * @private {LiveChatVideo} windowStream
     */
    windowStream;

    /**
     * Holds number of turn request
     *
     * @private {Number} turnRequested
     */
    turnRequested;

    /**
     * Holds list of turn servers
     *
     * @private {Array} turnIceServers
     */
    turnIceServers;

    /**
     * Holds reference to local stream
     *
     * @private {Stream} localStream
     */
    localStream;

    /**
     * Holds reference to remote stream
     *
     * @private {Stream} remoteStream
     */
    remoteStream;

    /**
     * Holds reference to local stream for screen share
     *
     * @private {Stream} localScreenShareStream
     */
    localScreenShareStream;

    /**
     * Holds reference to remote stream for screen share
     *
     * @private {Stream} remoteScreenShareStream
     */
    remoteScreenShareStream;

    /**
     * Holds reference to PeerConnection
     *
     * @private {PeerConnection} webRtcPeerConnection
     */
    webRtcPeerConnection;

    /**
     * Holds list of webrtc ICE candidates
     *
     * @private {Array} webRtcIceCandidates
     */
    webRtcIceCandidates = [];

    /**
     * Holds info about webrtc local description
     *
     * @private {Boolean} webRtcHasLocalDescription
     */
    webRtcHasLocalDescription;

    /**
     * Holds info about webrtc remote description
     *
     * @private {Boolean} webRtcHasRemoteDescription
     */
    webRtcHasRemoteDescription;

    /**
     * Holds info about webrtc connection status
     *
     * @private {Integer} webRtcStatus
     */
    webRtcStatus;

    /**
     * Audo select camera on switch if there is only one additional camera
     * 
     * @param {Boolean} switchCameraAutoSelect
     */
    switchCameraAutoSelect;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id                     : 'asseco-videochat-window',
            title                  : a24n('Agent') + ': ' + config.agent,
            drawer                 : true,
            videoEnabled           : true,
            switchCameraAutoSelect : false
        });

        // modify default window size if we are not using fixed drawer
        if (config.drawerFixed === false && ! config.hasOwnProperty('width')) {
            config.width = '600px';
        }

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // disable LiveChat, LiveAudioChat
        CmpMgr.updateComponents(['LiveChat', 'LiveAudioChat'], 'disable');

        // enable LiveVideoChat (for maximizing), LiveCoBrowsing, LiveScreenShare
        CmpMgr.updateComponents(['LiveVideoChat', 'LiveCobrowsing', 'LiveScreenShare'], 'enable');

        this.webRtcGetUserMedia();
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        super.beforeDestroy();

        if (this.windowStream) {
            this.windowStream.hide(true);
        }

        // enable LiveChat, LiveAudioChat, LiveVideoChat
        CmpMgr.updateComponents(['LiveChat', 'LiveAudioChat', 'LiveVideoChat'], 'enable');

        // disable LiveCobrowsing, LiveScreenShare
        CmpMgr.updateComponents(['LiveCobrowsing', 'LiveScreenShare'], 'disable');

        // close WebRTC
        Utils.webrtcStopMedia(this.localStream);
        Utils.webrtcStopMedia(this.remoteStream);

        this.webrtcStopConnection();

        return true;
    }

    /**
     * Executed when message is received on web socket
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onMessageReceived(conn, msg) {
        super.onMessageReceived(conn, msg);

        switch (msg.type)
        {
        case Constants.REQ_GET_WEBRTC_TURN:
            this.webRtcProcessTurnData(msg.data);
            break;

        case Constants.MSG_CAT_VIDEO:
            this.webRtcProcessSignalingMessage(msg.data);
            break;

        case Constants.MSG_CAT_SIGNAL:
            if (msg.data === Constants.SIGNAL_DISCONNECTED) {
                this.windowStream.showMask(a24n('Disconnected'), false);
            }
            break;
        }
    }

    /**
     * Executed on receiving SIGNAL message
     *
     * @param {Object} msg
     * @private
     */
    onSignalMessage(msg) {
        super.onSignalMessage(msg);

        if (msg.data === Constants.SIGNAL_TOGGLEFRAME) {
            this.windowStream.toggleFrame();
        }

        /**
         * If red frame is shown on the screen than take snapshot from LiveChat and upload it to LIVE
         * This is done to get images with better quality
         */
        if (msg.data === Constants.SIGNAL_SNAPSHOTTED && this.windowStream.overlayFrameShown) {
            var data = this.windowStream.getImageFromVideo(this.windowStream.localMediaEl);

            Connection.sendMessage(Constants.REQ_SEND_SNAPSHOT_IM, {
                base64Image: data.replace('data:image/png;base64,', '').replace(' ', '+')
            });
        }

        if (! this.showMutedSignal) {
            return;
        }

        var sm;
        if (msg.data === Constants.SIGNAL_AUDIOMUTED) {
            sm = a24n('Agent audio muted');
        } else if (msg.data === Constants.SIGNAL_AUDIOUNMUTED) {
            sm = a24n('Agent audio unmuted');
        } else if (msg.data === Constants.SIGNAL_VIDEOMUTED) {
            sm = a24n('Agent video muted');
        } else if (msg.data === Constants.SIGNAL_VIDEOUNMUTED) {
            sm = a24n('Agent video unmuted');
        } else if (msg.data === Constants.SIGNAL_SNAPSHOTTED) {
            sm = a24n('Agent took video snapshot');
        }

        if (sm) {
            this.addNewMessage(require('babel-loader!template-string-loader!./../../chat/window/Agent.html')({
                agentIcon : Utils.getIconMarkup(this.agentIcon, '', '', '#000'),
                message   : '<i>' + sm + '</i>',
                time      : Utils.getLocaleTime()
            }));
        }
    }

    /**
     * Create window for stream
     *
     * @private
     */
    createStreamWindow() {
        console.log('LiveVideoChatWindow::createStreamWindow ');
        this.windowStream = new LiveChatVideo({
            renderTo: this.getEl('.mdl-layout__content'),
            windowCmp: this
        });
    }

    /**
     * WebRTC - process signaling messages
     *
     * @param {Object} msg Message to process
     * @private
     */
    webRtcProcessSignalingMessage(msg) {
        if (this.webRtcStatus === LiveChatWindowIM.NOTREADY) {
            console.log('LiveVideoChatWindow::STOP processing signaling messages!');
            return;
        }

        console.log('LiveVideoChatWindow::Process signaling message (' + msg.name + '): ', msg);

        switch (msg.name)
        {
        case 'offer':
            // wait for peer connection
            if (! this.webRtcPeerConnection) {
                console.log('LiveVideoChatWindow::Waiting for peer connection...');
                setTimeout(() => {
                    this.webRtcProcessSignalingMessage(msg);
                }, 500);
                return false;
            }

            console.log('LiveVideoChatWindow::Offer received...', JSON.parse(msg.value));
            this.webRtcSetRemote(new RTCSessionDescription(JSON.parse(msg.value)), true);
            break;

        case 'answer':
            // wait for peer connection
            if (! this.webRtcPeerConnection) {
                console.log('LiveVideoChatWindow::Waiting for peer connection...');
                setTimeout(() => {
                    this.webRtcProcessSignalingMessage(msg);
                }, 500);
                return false;
            }

            console.log('LiveVideoChatWindow::Answer received...', JSON.parse(msg.value));
            this.webRtcSetRemote(new RTCSessionDescription(JSON.parse(msg.value)));
            break;

        case 'candidate':
            console.log('LiveVideoChatWindow::ICE candidate received...', JSON.parse(msg.value));
            // if we have set remote description then we can add ice candidates, otherwise we need to collect then and add later
            if (this.webRtcPeerConnection && this.webRtcHasRemoteDescription) {
                try {
                    this.webRtcPeerConnection.addIceCandidate(new RTCIceCandidate(JSON.parse(msg.value)));
                } catch (e) {
                    //
                }
            }
            else {
                console.log('LiveVideoChatWindow::Collecting ICE candidate');
                this.webRtcIceCandidates.push(JSON.parse(msg.value));
            }
            break;
        }
    }

    /**
     * Send WebRTC data
     *
     * @param {String} value Message value to send
     * @param {String} name Message name (candidate, offer, answer, ...)
     * @private
     */
    webrtcSendMessage(value, name) {
        Connection.sendMessage(Constants.REQ_MESSAGE, {
            meta: [{
                category : Constants.MSG_CAT_VIDEO,
                name     : name,
                value    : value
            }]
        });
    }

    /**
     * Request media sources from web browser
     *
     * @private
     */
    webRtcGetUserMedia() {
        // set webrtc status
        console.log('LiveVideoChatWindow::Set WebRTC status to NOTREADY');
        this.webRtcStatus = LiveVideoChatWindowIM.NOTREADY;

        // create stream window
        this.createStreamWindow();

        // Call into getUserMedia via the polyfill (adapter.js).
        try {
            var constraints = {audio: true, video: this.videoEnabled};
            console.log('LiveVideoChatWindow::Requesting local stream: ', constraints);

            navigator.mediaDevices.getUserMedia(constraints)
                // success
                .then((stream) => {
                    console.log('LiveVideoChatWindow::User has granted access to local stream');
                    this.localStream = stream;

                    // set webrtc status
                    console.log('LiveVideoChatWindow::Set WebRTC status to READY');
                    this.webRtcStatus = LiveVideoChatWindowIM.READY;

                    // get TURN info
                    this.webRtcGetTurnInfo();
                })

                // failure
                .catch((err) => {
                    console.log('LiveVideoChatWindow::Failed to get access to local media. Error code was ' + err.code, err);

                    if (this.windowStream) {
                        this.windowStream.showMask(a24n('Unable to acquire media device'), false);
                    }

                    this.webrtcStopConnection();
                });
        } catch (e) {
            console.log('LiveVideoChatWindow::getUserMedia failed with exception: ' + e.message);
        }
    }

    /**
     * Request TURN info
     *
     * @private
     */
    webRtcGetTurnInfo() {
        console.log('LiveVideoChatWindow::Requesting TURN info...');

        this.turnRequested = 0;
        this.turnIceServers = [];

        var tU = Asseco.config.TurnUrl || [];
        if (Utils.isEmpty(tU)) {
            this.webRtcCreatePeerConnection();
        }
        else {
            for (var i = 0; i < tU.length; i++) {
                Connection.sendMessage(Constants.REQ_GET_WEBRTC_TURN, {
                    url: tU[i],
                    post: 'service=turn&key=' + (Asseco.config.TurnKey || '') + '&username=' + Asseco.UUID
                });
            }
        }
    }

    /**
     * Process TRUN data fetched from server
     *
     * @param {Object} data Data fetched from TURN
     * @private
     */
    webRtcProcessTurnData(data) {
        this.turnRequested++;

        if (! Utils.isEmpty(data)) {
            var d = JSON.parse(data);
            console.log('LiveVideoChatWindow::TURN info fetched: ', d);
            this.turnIceServers.push({'urls': typeof d.uris[0] === 'string' ? d.uris[0] : ('turn:' + d.uris[0].turn), 'username': d.username, 'credential': d.password});
        }
        else {
            console.warn('LiveVideoChatWindow::Problem fetching TURN info');
        }

        var tU = Asseco.config.TurnUrl || [];
        if (this.turnRequested === tU.length) {
            this.webRtcCreatePeerConnection();
        }
    }

    /**
     * WebRTC - set local descriptors
     *
     * @param {String} desc
     * @param {String} message
     * @private
     */
    webRtcSetLocal(desc, message) {
        this.webRtcPeerConnection.setLocalDescription(
            new RTCSessionDescription(desc),
            // callback on success
            () => {
                console.log('LiveVideoChatWindow::Local description set');
                this.webRtcHasLocalDescription = true;

                try {
                    var jsonD = JSON.stringify(desc);
                    console.log('LiveVideoChatWindow::Sending ' + message, desc);
                    this.webrtcSendMessage(jsonD, message);
                } catch (e) {
                    //
                }
            },
            // callback on error
            (e) => {
                console.log('LiveVideoChatWindow::Failed set local descriptor ' + e.message);
            }
        );
    }

    /**
     * WebRTC - set remote descriptors
     *
     * @param {String} desc
     * @param {Boolean} createAnswer
     * @private
     */
    webRtcSetRemote(desc, createAnswer) {
        this.webRtcPeerConnection.setRemoteDescription(
            new RTCSessionDescription(desc),
            // callback on success
            () => {
                console.log('LiveVideoChatWindow::Remote description set');
                this.webRtcHasRemoteDescription = true;

                if (createAnswer) {
                    console.log('LiveVideoChatWindow::Creating answer...');
                    this.webRtcPeerConnection.createAnswer(
                        (answer) => {
                            this.webRtcSetLocal(answer, 'answer');
                        },
                        () => {}
                    );
                }

                // add ICE candidate after remote description is set
                if (this.webRtcIceCandidates.length > 0) {
                    for (var c = 0; c < this.webRtcIceCandidates.length; c++) {
                        this.webRtcPeerConnection.addIceCandidate(new RTCIceCandidate(this.webRtcIceCandidates[c]));
                    }
                    this.webRtcIceCandidates = [];
                }
            },
            // callback on error
            (e) => {
                console.log('LiveVideoChatWindow::Failed set remote descriptor ' + e.message);
            }
        );
    }

    /**
     * WebRTC - create pear connection
     *
     * @private
     */
    webRtcCreatePeerConnection() {
        try {
            console.log('LiveVideoChatWindowIM::webRtcCreatePeerConnection - turnIceServers: ', this.turnIceServers);

            // get STUN servers
            var s = Asseco.config.hasOwnProperty('StunUrl') ? Asseco.config.StunUrl : [];
            console.log('LiveVideoChatWindowIM::webRtcCreatePeerConnection - stunIceServers: ', s);

            // set webrtc status
            console.log('LiveVideoChatWindow::Set WebRTC status to CONNECTING');
            this.webRtcStatus = LiveVideoChatWindowIM.CONNECTING;

            var pcConfig = {
                iceServers: this.turnIceServers.concat(s),
                iceTransportPolicy: Asseco.config.hasOwnProperty('IcePolicy') ? Asseco.config.IcePolicy : 'all',
                iceCandidatePoolSize: Asseco.config.hasOwnProperty('IceCandidatePoolSize') ? parent(Asseco.config.IceCandidatePoolSize, 10) : 0
            };
            console.log('LiveVideoChatWindow::Creating PeerConnection with config... ', pcConfig);
            this.webRtcPeerConnection = new RTCPeerConnection(pcConfig);
            console.log('LiveVideoChatWindow::RTCPeerConnnection created');

            /**
             * Signaling state
             */
            // console.log('LiveVideoChatWindow::RTCPeerConnnection signaling state: ' + this.webRtcPeerConnection.signalingState || this.webRtcPeerConnection.readyState);
            // this.webRtcPeerConnection.onsignalingstatechange = function () {
            //     if (this.webRtcPeerConnection) {
            //         console.log('LiveVideoChatWindow::RTCPeerConnnection signaling state change: ' + (this.webRtcPeerConnection.signalingState || this.webRtcPeerConnection.readyState));
            //     }
            // }.createDelegate(this);

            /**
             * ICE connection state
             */
            console.log('LiveVideoChatWindow::RTCPeerConnnection ICE connection state: ' + this.webRtcPeerConnection.iceConnectionState);
            this.webRtcPeerConnection.oniceconnectionstatechange = () => {
                if (this.webRtcPeerConnection) {
                    console.log('LiveVideoChatWindow::RTCPeerConnnection ICE connection state change: ' + this.webRtcPeerConnection.iceConnectionState);
                    if (this.webRtcPeerConnection.iceConnectionState === 'close') {
                        if (this.windowStream) {
                            this.windowStream.hide(true);
                        }
                    }
                }
            };

            /**
             * ICE gathering state
             */
            // console.log('LiveVideoChatWindow::RTCPeerConnnection ICE gathering state: ' + this.webRtcPeerConnection.iceGatheringState);
            // this.webRtcPeerConnection.onicegatheringstatechange = function () {
            //     if (this.webRtcPeerConnection) {
            //         console.log('LiveVideoChatWindow::RTCPeerConnnection ICE gathering state change: ' + this.webRtcPeerConnection.iceGatheringState);
            //     }
            // }.createDelegate(this);

            /**
             * Send ICE candidates
             */
            this.webRtcPeerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    try {
                        var jsonC = JSON.stringify(event.candidate);
                        console.log('LiveVideoChatWindow::Sending Local ICE candidate to remote client');
                        this.webrtcSendMessage(jsonC, 'candidate');
                    } catch (e) {
                        //
                    }
                }
            };

            /**
             * Attach remote media
             */
            this.webRtcPeerConnection.onaddstream = (event) => {
                console.log('LiveVideoChatWindow::Got Remote stream');

                // assume screen sharing stream if remote stream already exists
                if (this.remoteStream && event.stream.getAudioTracks().length === 0) {
                    console.log('LiveVideoChatWindow::Got Remote screen sharing stream');
                    this.remoteScreenShareStream = event.stream;

                //    shareScreenVideoEl = attachMediaStream(shareScreenVideoEl, remoteScreenShareStream);
                }
                else {
                    this.remoteStream = event.stream;
                    this.windowStream.attachStream(this.localStream, this.remoteStream);

                    // set webrtc status
                    console.log('LiveVideoChatWindow::Set WebRTC status to CONNECTED');
                    this.webRtcStatus = LiveVideoChatWindowIM.CONNECTED;

                    // disable chat button
                    var chBtn = CmpMgr.getByXtype('LiveChat');
                    if (chBtn) {
                        chBtn.disable();
                    }

                    // enable screen sharing
                    var scBtn = CmpMgr.getByXtype('LiveScreenShare');
                    if (scBtn) {
                        scBtn.windowVideo = this;
                        scBtn.enable();
                    }
                }
            };

            /**
             * Remove remote media
             */
            this.webRtcPeerConnection.onremovestream = (event) => {
                console.log('LiveVideoChatWindow::Remote stream removed');

                // assume screen sharing stream
                if (this.remoteScreenShareStream && event.stream.getAudioTracks().length === 0) {
                    this.remoteScreenShareStream = null;
                }
            };

            /**
             * Negotitation needed event
             *  - use for screen sharing negotiation only
             */
            // eslint-disable-next-line no-unused-vars
            this.webRtcPeerConnection.onnegotiationneeded = (event) => {
                if (this.localScreenShareStream) {
                    console.log('LiveVideoChatWindow::Creating offer for screen sharing...');
                    this.webRtcPeerConnection.createOffer(
                        (offer) => {
                            this.webRtcSetLocal(offer, 'offer');
                        },
                        () => {}
                    );
                }
            };

            console.log('LiveVideoChatWindow::Adding Local Stream to peer connection');
            this.webRtcPeerConnection.addStream(this.localStream);
        } catch (e) {
            console.log('LiveVideoChatWindow::Failed to create PeerConnection, exception: ', e);
///////////////////////////////////
/////////////////////////////////
//////////////////////
        }
    }

    /**
     * WebRTC - stop peer connection
     *
     * @private
     */
    webrtcStopConnection() {
        if (this.webRtcPeerConnection) {
            this.turnRequested = 0;
            this.turnIceServers = [];

            this.localStream = null;
            this.remoteStream = null;

            try {
                this.webRtcPeerConnection.close();
            } catch (e) {
                // already closed
            }

            this.webRtcPeerConnection = null;
            this.webRtcIceCandidates = [];

            this.webRtcHasLocalDescription = false;
            this.webRtcHasRemoteDescription = false;

            // set webrtc status
            console.log('LiveVideoChatWindow::Set WebRTC status to NOTREADY');
            this.webRtcStatus = LiveVideoChatWindowIM.NOTREADY;
        }
    }
}
LiveVideoChatWindowIM.prototype.xtype = 'LiveVideoChatWindow';
export default LiveVideoChatWindowIM;
