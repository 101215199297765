import Svgs from 'data/Svgs';
import Element from 'util/Element';
import Utils from 'util/Utils';
import LiveCard from 'widgets/card/Card';

/**
 * LIVE List Card Component (available in Asseco namespace) <br/>
 * Components is used for showing simple list with optional actions on list item
 *
 * @example
 * var aList = new Asseco.LiveList({
 *     title: 'Example List',
 *     width: '350px',
 *     height: '250px',
 *     itemIcon: 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z',
 *     position: {
 *         position: 'absolute',
 *         top: '575px',
 *         left: '25px'
 *     },
 *     data: [
 *         {id: 'item-1', name: 'Item 1', desc: 'Description'},
 *         {id: 'item-2', name: 'Item 2'},
 *         {id: 'item-3', name: 'Item 3', actions: [{
 *             type: 'action1',
 *             icon: 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'
 *         }]},
 *         {id: 'item-4', name: 'Item 4'},
 *         {id: 'item-5', name: 'Item 5'}
 *     ],
 *     onItemClick: function(e, li, dO, dR) {
 *         console.log('Liste item clicked: ', li, dO, dR);
 *     },
 *     onActionClick: function(e, li, btn, dO, dR, a) {
 *         console.log('Liste item action clicked: ', li, btn, dO, dR, a);
 *     }
 * });
 */
class LiveList extends LiveCard {
    /**
     * Enable filtering for this list
     *
     * @type {Boolean} enableFilter
     */
    enableFilter;

    /**
     * Enable refresh for this list
     *
     * @type {Boolean} enableRefresh
     */
    enableRefresh;

    /**
     * Image path, embeded data uri or path for SVG
     *
     * @type {String} itemIcon
     */
    itemIcon;

    /**
     * Specify data to be loaded after component is rendered
     *
     * @example
     * data: [
     *     {id: 'item-1', name: 'Item 1'},
     *     {id: 'item-2', name: 'Item 2'},
     *     {id: 'item-3', name: 'Item 3'},
     *     {id: 'item-4', name: 'Item 4'},
     *     {id: 'item-5', name: 'Item 5'}
     * ]
     *
     * @type {Array} data
     */
    data;

    /**
     * Holds reference to main list item element
     *
     * @private {HTMLElement} listEl
     */
    listEl;

    /**
     * Hold info about items limit to fetch
     *
     * @private {Number} limit
     */
    limit;

    /**
     * Holds info about filter for items fetch
     *
     * @private {Array} filter
     */
    filter;

    /**
     * Holds reference to actions tips
     *
     * @private {Array} aTips
     */
    aTips;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            enableFilter   : false,
            enableRefresh  : true,
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        this.tools = this.tools || [];

        this.aTips = [];

        // add filter tool
        if (this.enableFilter) {
            this.tools.push({
                icon: Svgs.FILTER,
                tip: a24n('Filter'),
                cls: 'asseco-card-tool-icon',
                eventType: 'click',
                scope: this,
                handler: this.showFilterMask
            });
        }

        // add refresh tool
        if (this.enableRefresh) {
            this.tools.push({
                icon: Svgs.REFRESH,
                tip: a24n('Refresh'),
                cls: 'asseco-card-tool-icon',
                eventType: 'click',
                scope: this,
                handler: this.fetchList
            });
        }

        super.initComponent();
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./List.scss');
    }

    /**
     * Get content template
     *
     * @private
     */
    getContentHtml() {
        return require('babel-loader!template-string-loader!./List.html')({});
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // ensure css class for asseco list
        Element.addClass(this.containerEl, 'asseco-list');

        this.listEl = this.getEl('.mdl-list');
        this.fetchList();
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        // remove existing action tooltips
        (this.aTips || []).forEach((t) => Element.removeNode(t));

        return super.beforeDestroy();
    }

    /**
     * Get list (override this method for fetching data from external source)
     */
    fetchList() {
        // Removing all list items
        while (this.listEl.firstChild) {
            this.listEl.removeChild(this.listEl.firstChild);
        }

        // Removing existing action tooltips
        (this.aTips || []).forEach((t) => Element.removeNode(t));

        // load initial data if defined
        if (! Utils.isEmpty(this.data) && Array.isArray(this.data)) {
            this.addItem(this.data);
            delete this.data;
        }
    }

    /**
     * Add item(s) to list
     *
     * @param {Object|Array} item Item with id, name and icon for adding to list
     */
    addItem(item) {
        // make sure we have array
        item = Array.isArray(item) ? item : [item];

        // if there are no items add one with info
        if (Utils.isEmpty(item)) {
            item.push({
                id   : 'nodata',
                name : a24n('No data to display')
            });
        }

        var li, liId, liA, d, aTipIds = {};
        item.forEach(i => {
            liId = i.id || Utils.generateUUID(5, 'id_');

            liA = i.actions || [];
            liA.forEach(a => {
                a.id = a.id || Utils.generateUUID(5, 'id_');
                a.icon = Utils.getIconMarkup(a.icon, '', '', '#000');
                if (! Utils.isEmpty(a.tooltip)) {
                    aTipIds[a.id] = a.tooltip;
                }
            }, this);

            d = {
                id       : 'asseco-list-item__' + liId,
                lineCls  : Utils.isEmpty(i.desc) ? '' : 'mdl-list__item--two-line',
                itemCls  : i.itemCls || '',
                itemAttr : i.itemAttr || '',
                name     : i.name,
                desc     : i.desc || '',
                icon     : Utils.getIconMarkup(i.icon || this.itemIcon, '', '', i.iconFill || '#000'),
                actions  : liA
            };

            this.listEl.insertAdjacentHTML('beforeend', require('babel-loader!template-string-loader!./ListItem.html')(d));

            // add event listener for added item
            li = this.listEl.querySelector('#asseco-list-item__' + liId);
            li.onclick = this.onItemClick.createDelegate(this, [li, i, d], true);

            // add event listener for action and register tooltips if any
            if (! Utils.isEmpty(liA)) {
                Array.prototype.slice.call(this.listEl.querySelectorAll('#asseco-list-item__' + liId + ' button.mdl-button')).forEach((btn, idx) => {
                    if (aTipIds.hasOwnProperty(btn.id)) {
                        this.aTips.push(Utils.tooltip(btn.id, aTipIds[btn.id]));
                    }
                    btn.onclick = this.onActionClick.createDelegate(this, [li, btn, i, d, d.actions[idx]], true);
                }, this);
            }
        }, this);
    }

    /**
     * Executed on filter tool click (Show filter mask)
     *
     * @private
     */
    showFilterMask() {
    }

    /**
     * Collect filter values
     *
     * @private
     */
    applyFilter() {
    }

    /**
     * Executed on start filter
     *
     * @param {Boolean} f Apply new filters
     * @private
     */
    onFilter(f) {
        if (f) {
            this.applyFilter();
        }

        this.setButtons([]);
        this.setContent(this.getContentHtml());

        this.listEl = this.getEl('.mdl-list');
        this.fetchList();
    }

    /**
     * Executed on list item click
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} li Clicked HTML element (li)
     * @param {Object} dO Original list item data
     * @param {Object} dR Rendered list item data
     */
    // eslint-disable-next-line no-unused-vars
    onItemClick(e, li, dO, dR) {
    }

    /**
     * Executed on list action click
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} li Clicked HTML list element (li)
     * @param {HTMLElement} btn Clicked HTML action button element (button)
     * @param {Object} dO Original list item data
     * @param {Object} dR Rendered list item data
     * @param {Object} a Action configuration
     */
    // eslint-disable-next-line no-unused-vars
    onActionClick(e, li, btn, dO, dR, a) {
        // use this in override method so onItemClick won't be called
        // e.stopPropagation();
    }
}
LiveList.prototype.xtype = 'LiveList';
export default LiveList;
