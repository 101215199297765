import Constants from 'data/Constants';
import Connection from 'data/Connection';
import Utils from 'util/Utils';
import LiveList from 'widgets/list/List';
// import LiveSurvey from './../index';
import LiveSurveyPreviewWindow from './../preview/Preview';

/**
 * LIVE Survey Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveSurveyWindow extends LiveList {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id    : 'asseco-survey-window',
            title : a24n('Survey')
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Get list of surveys
     *
     * @private
     */
    fetchList() {
        super.fetchList();

        this.showMask();

        console.log('LiveSurveyWindow::Fetching list');
        Connection.sendMessage(Constants.REQ_GET_SURVEYS, null, this.onFetchList, this);
    }

    /**
     * Executed after list is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onFetchList(conn, msg) {
        var d = JSON.parse(msg.data);

        this.waitingCount = 0;

        this.addItem(d);
        this.hideMask();
    }

    /**
     * Add item(s) to list
     *
     * @param {Object|Array} item Item with id, name and icon for adding to list
     */
    addItem(item) {
        // make sure we have array
        item = Array.isArray(item) ? item : [item];
        item.forEach(i => {
            // i.name = i.name;
            i.desc = Utils.toDate(i.creation_time, true);
            i.icon = i.status === 'done' ? 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' : '';
        }, this);

        super.addItem(item);
    }

    /**
     * Executed on list action click
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} li Clicked HTML list element (li)
     * @param {Object} dO Original list item data
     * @param {Object} dR Rendered list item data
     */
    // eslint-disable-next-line no-unused-vars
    onItemClick(e, li, dO, dR) {
        // info item about no data to display
        if (li.id === 'asseco-list-item__nodata') {
            return;
        }

        // open preview window
        var preview = new LiveSurveyPreviewWindow({
            title: dO.name,
            survey: dO,
            onSuccess: () => {
                preview.hide(true);

                // show toast notification
                Utils.toast(a24n('Survey submitted. Thank you.'));

                this.fetchList();
            }
        });
    }
}
LiveSurveyWindow.prototype.xtype = 'LiveSurveyWindow';
export default LiveSurveyWindow;
