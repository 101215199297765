import Connection from 'data/Connection';
import 'util/Function';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveFab from 'widgets/fab/Fab';
import LiveSurveyWindow from './window/Window';

/**
 * LIVE Survey Component - xtype: LiveSurvey - (available in Asseco namespace) <br/>
 * Components is used for showing and filling surveys created in LIVE
 *
 * @example
 * var aSurvey = new Asseco.LiveSurvey({
 *     deferShow: 500,
 *     position: {
 *         position: 'absolute',
 *         top: '125px',
 *         right: '25px'
 *     },
 *     icon: 'custom icon - SVG path or IMG src',
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 * });
 */
class LiveSurvey extends LiveFab {
    /**
     * Holds configuration object for main window {@link LiveComponent}
     *
     * @type {Object} winCfg
     */
    winCfg;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier : 'survey',
            label      : a24n('Survey'),
            icon       : 'M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     * @private
     */
    onClick(event) {
        if (this.disabled) {
            return;
        }

        // by default show survey window
        var showWin = event.hasOwnProperty('showWin') ? event.showWin : true;

        // if web socket connection is not opened we need to establish connection first
        if (Connection.getStatus() !== Connection.OPEN) {
            // add callback for web socket connection open
            Connection.addCallback(this, 'open', this.onConnectionOpen.createDelegate(this, [showWin]), this);

            // renew user UUID
            Asseco.UUID = Utils.generateUUID();
            console.log('LiveSurvey::Regenerate Asseco UUID: ' + Asseco.UUID);

            Connection.wsUrl = null;
            Connection.targetEl = this.containerEl;
            Connection.connect();
        }
        else {
            this.onConnectionOpen(showWin);
        }
    }

    /**
     * Executed on opening web socket connection
     *
     * @param {Boolean} showWin If window with survey list sholud be shown after web socket open
     * @private
     */
    onConnectionOpen(showWin) {
        // show survey list if not already rendered
        if (! CmpMgr.hasByXtype(LiveSurveyWindow.prototype.xtype)) {
            console.log('LiveSurvey::onConnectionOpen - show survey window');

            // remove callback for web socket connection open
            Connection.removeCallback(this, 'open');

            // open filemanager list window
            var win = new LiveSurveyWindow(Utils.applyIf({
                renderHidden: true
            }, this.winCfg || {}));

            if (showWin) {
                win.show();
            }
        }
        else if (showWin) {
            CmpMgr.getByXtype(LiveSurveyWindow.prototype.xtype).show();
        }
    }
}
LiveSurvey.prototype.xtype = 'LiveSurvey';
CmpMgr.registerXtype(LiveSurvey.prototype.xtype, LiveSurvey);
export default LiveSurvey;
