module.exports=function(scope){ return `<div class="asseco-dm-message"></div>

${scope.atts ? `
    <div class="mdl-card__actions mdl-card--border asseco-chat-window-bbar" ${scope.atts.length > 1 ? `style="height: 95px; overflow: auto;"` : ''}>
        ${scope.atts.map(a => `
            <span class="mdl-chip mdl-chip--contact" style="cursor: pointer;">
                <span class="mdl-chip__contact mdl-color--teal mdl-color-text--white">
                    ${scope.attIcon}
                </span>
                <span id="asseco-dm-message-att_${a.id}" class="mdl-chip__text">${a.name}</span>
            </span>`).join('')}
    </div>`
: ''}
`};