import CmpMgr from 'util/ComponentManager';
import Connection from 'data/Connection';
import Utils from 'util/Utils';
import Element from 'util/Element';
import LiveComponent from 'widgets/Component';

///////////////////////////////////////
import LiveChat from './../chat/index';
//////////

/////////////////////////////////////////////
import LiveCobrowsing from './../cobrowsing/index';
//////////

////////////////////////////////////////////
import LiveVideoChat from './../videochat/index';
//////////

////////////////////////////////////////////
import LiveAudioChat from './../audiochat/index';
//////////

//////////////////////////////////////////////
import LiveScreenShare from './../screenshare/index';
//////////

//////////////////////////////////////////////
import LiveFilemanager from './../filemanager/index';
//////////

///////////////////////////////////////////
import LiveCallback from './../callback/index';
//////////

//////////////////////////////////////////////////
import LiveDirectMessaging from './../directmessaging/index';
//////////

/////////////////////////////////////////
import LiveSurvey from './../survey/index';
//////////

//////////////////////////////////////////
import LiveMeeting from './../meeting/index';
//////////

/**
 * LIVE Toolbar Component (available in Asseco namespace) <br/>
 * Components is used as container for holding multiple components
 *
 * @example
 * var tb = new Asseco.LiveToolbar({
 *     position: {
 *         position: 'fixed',
 *         bottom: '25px',
 *         right: '25px'
 *     },
 *     // if plugins is not defined all available components will be included
 *     plugins: [
 *         Asseco.LiveChat,
 *         Asseco.LiveVideoChat
 *     ]
 * });
 */
class LiveToolbar extends LiveComponent {
    /**
     * Image path, embeded data uri or path for SVG
     *
     * @type {String} icon
     */
    startIcon;

    /**
     * Image path, embeded data uri or path for SVG
     *
     * @type {String} icon
     */
    activeIcon;

    /**
     * Is toolbar FAB labels persistent
     *
     * @type {Boolean} persistLabels
     */
    persistLabels;

    /**
     * List of components to include in toolbar
     *
     * @type {Array} plugins
     */
    plugins;

    /**
     * Component xtype which will be auto showed after toolbar
     *
     * @type {String} autoComponent
     */
    autoComponent;

    /**
     * Holds loaded HTML template for this component
     *
     * @private {String} template
     */
    template;

    /**
     * Hold info if toolbar is currently active
     *
     * @private {Boolean} isActive
     */
    isActive;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id            : 'asseco-toolbar',
            renderTo      : document.body,
            position      : {
                position: 'absolute',
                bottom: '10px',
                right: '10px'
            },
            deferShow     : 1,
            persistLabels : Utils.isMobile(),
            showAnim      : 'asseco-bounceIn',
            startIcon     : 'M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z',
            activeIcon    : 'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',

            plugins: [
///////////////////////////////////////////////////////
                LiveChat
//////////////////////////

//////////////////////////////////////////////////////////////
                , LiveFilemanager
//////////////////////////

/////////////////////////////////////////////////////////////
                , LiveCobrowsing
//////////////////////////

////////////////////////////////////////////////////////////
                , LiveVideoChat
//////////////////////////

////////////////////////////////////////////////////////////
                , LiveAudioChat
//////////////////////////

//////////////////////////////////////////////////////////////
                , LiveScreenShare
//////////////////////////

///////////////////////////////////////////////////////////
                , LiveCallback
//////////////////////////

//////////////////////////////////////////////////////////////////
                , LiveDirectMessaging
//////////////////////////

/////////////////////////////////////////////////////////
                , LiveSurvey
//////////////////////////

//////////////////////////////////////////////////////////
                , LiveMeeting
//////////////////////////
            ]
        });

//////////////////////////////////////////////////////
        // remove LiveFilemanager component if customer is no available
        var fIdx = config.plugins.indexOf(LiveFilemanager);
        if (fIdx > -1 && ! Asseco.customer) {
            config.plugins.splice(fIdx, 1);
        }
//////////////////

//////////////////////////////////////////////////////////
        // remove LiveDirectMessaging component if customer is no available
        var dIdx = config.plugins.indexOf(LiveDirectMessaging);
        if (dIdx > -1 && ! Asseco.customer) {
            config.plugins.splice(dIdx, 1);
        }
//////////////////

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        require('./style.scss');

        // NOTE: we need to require theme style scss here so it will be added at the end (LiveToolbar components always is importet last)
        require('./../../themes/default.style.scss');
    }

    /**
     * Get component template
     *
     * @return {String}
     * @private
     */
    getTemplate() {
        if (! this.template) {
            this.template = require('babel-loader!template-string-loader!./template.html')(this.getTemplateData());
        }

        return this.template;
    }

    /**
     * Get template data
     *
     * @return {Object}
     * @private
     */
    getTemplateData() {
        var cssStartClass  = 'asseco-toolbar-floaty-icon-start asseco-absolute-center',
            cssActiveClass = 'asseco-toolbar-floaty-icon-active asseco-absolute-center';

        var templateData = {
            id         : this.id,
            label      : a24n('Communication Menu'),
            startIcon  : Utils.getIconMarkup(this.startIcon, cssStartClass),
            activeIcon : Utils.getIconMarkup(this.activeIcon, cssActiveClass),
            listItems  : ''
        };

        // get template from plugins and add to main template
        if (! Utils.isEmpty(this.plugins)) {
            this.plugins.forEach(p => {
                templateData.listItems += p.getTemplate();
                if (p.onClick) {
                    p.onClick = p.onClick.createSequence(this.onItemClicked, this);
                }
            }, this);
        }

        return templateData;
    }

    /**
     * Show component (optionaly with some duration)
     *
     * @param {Number} d Duration in which component will be shown
     * @private
     */
    doDeferShow(d) {
        if (! this.rendered) {
            return;
        }

        // because deferShow is on toolbar but we want it only on toolbar FAB button,
        // we change toolbar opacity to 1 and animate toolbar FAB
        Element.setStyle(this.containerEl, {
            display : '',
            opacity : 1
        });
        this.hidden = false;

        // toolbar FAB element we want to animate
        var fbEl = this.getEl('.asseco-floaty-btn');
        fbEl.style.opacity = 0;

        d = d || parseInt(this.showDuration, 10) || 0;
        if (d > 0) {
            (function () {
                Element.setStyle(fbEl, {
                    display : ''
                });

                if (! Utils.isEmpty(this.showAnim)) {
                    Element.addClass(fbEl, this.showAnim);
                    this.afterShow();
                }
                // animate opacity transition through given duration
                else {
                    for (var i = 0; i <= 1; i += 0.05) {
                        setTimeout(function (x) {
                            fbEl.style.opacity = x;
                            if (x === 1) {
                                this.hidden = false;
                                this.afterShow();
                            }
                        }.createDelegate(this, [i]), i * d);
                    }
                }
            }).defer(parseInt(this.deferShow, 10), this);
        }
        else {
            Element.setStyle(fbEl, {
                display : '',
                opacity : 1
            });
            this.hidden = false;
            this.afterShow();
        }
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // add click event on toolbar FAB button
        this.getEl('.asseco-floaty-btn').onclick = this.onMenuClicked.createDelegate(this);
    }

    /**
     * Called after component is shown
     *
     * @private
     */
    afterShow() {
        super.afterShow();

        if (this.autoComponent) {
            this.plugins.some(p => {
                if (p.xtype === this.autoComponent) {
                    p.onClick.call(p);
                    return true;
                }
            }, this);
        }
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        if (Connection.getStatus() === Connection.OPEN) {
            Connection.close();
        }

        return true;
    }

    /**
     * Destroy component (remove from DOM)
     */
    destroy() {
        super.destroy();

        // destroy all registered components
        for (var cmp in CmpMgr.components) {
            CmpMgr.components[cmp].destroy();
        }
    }

    /**
     * Executed after item is clicked
     */
    onItemClicked() {
        if (this.isActive) {
            this.onMenuClicked();
        }
    }

    /**
     * Executed when toolbar FAB is clicked
     */
    onMenuClicked() {
        var cE = this.containerEl;
        Element[(Element.hasClass(cE, 'is-active') ? 'remove' : 'add') + 'Class'](cE, 'is-active');

        // current toolbar status
        this.isActive = Element.hasClass(cE, 'is-active');

        // show/hide list items
        var list = Array.prototype.slice.call(this.containerEl.querySelectorAll('.asseco-toolbar-floaty-list-item')).reverse(), tEl;
        list.forEach((li, i) => {
            setTimeout(() => {
                Element[(this.isActive ? 'add' : 'remove') + 'Class'](li, 'asseco-toolbar-floaty-list-item-show');

                if (this.persistLabels) {
                    tEl = document.querySelector('.mdl-tooltip[for="' + li.id + '"]');
                    if (! this.isActive) {
                        Element.removeClass(tEl, 'mdl-tooltip--persistent');
                        tEl.MaterialTooltip.hideTooltip_();
                    }
                }
            }, i * 15);
        }, this);

        if (this.persistLabels && this.isActive) {
            setTimeout(() => {
                list.forEach((li) => {
                    tEl = document.querySelector('.mdl-tooltip[for="' + li.id + '"]');
                    Element.addClass(tEl, 'mdl-tooltip--persistent');
                    tEl.MaterialTooltip.handleMouseEnter_({target: li});
                });
            }, (list.length * 15) + 150);
        }

        // check for MW
        this.showMW(Utils.getFuncName(this.__proto__.xtype, 'onMenuClicked'));
    }

    /**
     * Add badge to this component main element
     *
     * @param {String} msg Badge message (should be max 2 characters long)
     */
    addBadge(msg) {
        var floaty = this.getEl('.asseco-floaty-btn');
        if (floaty) {
            this.removeBadge();
            Element.addClass(floaty, 'mdl-badge mdl-badge--overlap mdl-badge--green');
            floaty.setAttribute('data-badge', msg);
        }
    }

    /**
     * Remove badge from this component main element
     */
    removeBadge() {
        var floaty = this.getEl('.asseco-floaty-btn');
        if (floaty) {
            Element.removeClass(floaty, ['mdl-badge', 'mdl-badge--overlap', 'mdl-badge--green']);
            floaty.removeAttribute('data-badge');
        }
    }
}
LiveToolbar.prototype.xtype = 'LiveToolbar';
CmpMgr.registerXtype(LiveToolbar.prototype.xtype, LiveToolbar);
export default LiveToolbar;
