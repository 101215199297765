import Svgs from 'data/Svgs';
import Utils from 'util/Utils';
import Element from 'util/Element';
import CmpMgr from 'util/ComponentManager';
import LiveComponent from 'widgets/Component';

/**
 * LIVE Card Component - xtype: LiveCard - (available in Asseco namespace) <br/>
 * Component is used as the main container for content to be presented to user
 *
 * @example
 * var aCard = new Asseco.LiveCard({
 *     title: 'Example Card',
 *     width: '350px',
 *     height: '250px',
 *     position: {
 *         position: 'absolute',
 *         top: '25px',
 *         left: '25px'
 *     },
 *     cls: 'customCssClass',
 *     style: {
 *         // add css properties with value to be applied to container element
 *     }
 *     contentHtml: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Mauris sagittis pellentesque lacus eleifend lacinia...',
 *     buttonsAlign: 'center',
 *     buttons: [{
 *         text: 'Button 1',
 *         handler: function () {
 *             alert('Button 1');
 *         }
 *     }, {
 *         text: 'Button 2'
 *     }],
 *     tools: [{
 *         icon: 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z',
 *         handler: function () {
 *             alert('Tool 1');
 *         }
 *     }],
 *     destroyOnClose: true,
 *     closable: true,
 *     minimizable: true
 * });
 */
class LiveCard extends LiveComponent {
    /**
     * Specify card title
     *
     * @type {String} title
     */
    title;

    /**
     * Specify card width
     *
     * @type {String} width
     */
    width;

    /**
     * Specify card height
     *
     * @type {String} height
     */
    height;

    /**
     * Array of button objects
     *
     * @example
     * buttons: [{
     *     text: 'Button 1',
     *     handler: function () {
     *         alert('Button 1');
     *     }
     * }, {
     *     text: 'Button 2'
     * }]
     *
     * @type {Array} buttons
     */
    buttons;

    /**
     * Specify buttons align
     *
     * @type {String} buttonsAlign
     */
    buttonsAlign;

    /**
     * Specify buttons class
     *
     * @type {String} buttonsCls
     */
    buttonsCls;

    /**
     * Array of tools objects
     *
     * @example
     * tools: [{
     *     icon: 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z',
     *     handler: function () {
     *         alert('Tool 1');
     *     }
     * }],
     *
     * @type {Array} tools
     */
    tools;

    /**
     * Specify tools class
     *
     * @type {String} toolsCls
     */
    toolsCls;

    /**
     * Array of menu items
     *
     * @example
     * [{
     *     label: 'Menu item 1',
     *     divider: true,
     *     handler: function () {
     *         alert('Menu item clicked');
     *     }
     * }, {
     *     label: 'Menu item 2',
     *     disabled: true
     * }]
     *
     * @type {Array} menu
     */
    menu;

    /**
     * Specify menu icon
     *
     * @type {String} menuIcon
     */
    menuIcon;

    /**
     * Specify HTML content of this card
     *
     * @type {String} contentHtml
     */
    contentHtml;

    /**
     * Is this card closable
     *
     * @type {Boolean} closable
     */
    closable;

    /**
     * Specify closableIcon icon (Image path, embeded data uri or path for SVG)
     *
     * @type {String} icon
     */
    closableIcon;

    /**
     * Is this card minimizable
     *
     * @type {Boolean} minimizable
     */
    minimizable;

    /**
     * Specify minimizable icon (Image path, embeded data uri or path for SVG)
     *
     * @type {String} icon
     */
    minimizableIcon;

    /**
     * Is this card destroyed on close
     *
     * @type {Boolean} destroyOnClose
     */
    destroyOnClose;

    /**
     * Holds loaded HTML template for this component
     *
     * @private {String} template
     */
    template;

    /**
     * Holds data for attaching handler to Element
     *
     * @private {Object} attachHandlers
     */
    attachHandlers;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            renderTo        : document.body,
            buttonsAlign    : 'right',
            buttonsCls      : 'mdl-button mdl-button--colored',
            toolsCls        : 'mdl-button mdl-button--icon asseco-card-tool-icon',
            menuIcon        : Svgs.MENU_V,
            closable        : true,
            closableIcon    : Svgs.CLOSE,
            minimizable     : false,
            minimizableIcon : Svgs.MINIMIZE,
            destroyOnClose  : false,
            position        : {
                position : 'fixed',
                bottom   : '10px',
                right    : '85px'
            }
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Return content for card
     *
     * @return {String}
     * @private
     */
    getContentHtml() {
        return '';
    }

    /**
     * Load this component style (loaded css is added to head)
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Card.scss');
    }

    /**
     * Get component template
     *
     * @return {String}
     * @private
     */
    getTemplate() {
        if (! this.template) {
            this.template = require('babel-loader!template-string-loader!./Card.html')(this.getTemplateData());
        }

        return this.template;
    }

    /**
     * Get template data
     *
     * @return {Object}
     * @private
     */
    getTemplateData() {
        var data = {
            id           : this.id,
            title        : this.title || '',
            content      : this.contentHtml || this.getContentHtml(),
            buttonsAlign : this.buttonsAlign || 'right',
            buttons      : '',
            tools        : '',
            menu         : ''
        };

        // add buttons
        if (! Utils.isEmpty(this.buttons)) {
            data.buttons = this.setButtons(this.buttons, true);
        }

        // add tools
        if (this.minimizable) {
            this.tools = this.tools || [];
            this.tools = Array.isArray(this.tools) ? this.tools : [this.tools];
            this.tools.push({
                icon: this.minimizableIcon,
                tip: a24n('Minimize'),
                eventType: 'click',
                scope: this,
                handler: this.onMinimize
            });
        }

        if (this.closable) {
            this.tools = this.tools || [];
            this.tools = Array.isArray(this.tools) ? this.tools : [this.tools];
            this.tools.push({
                icon: this.closableIcon,
                eventType: 'click',
                scope: this,
                handler: this.onClose
            });
        }

        if (! Utils.isEmpty(this.tools)) {
            // we need to show title even if its empty because tools icons are show in title
            if (Utils.isEmpty(data.title)) {
                data.title = '&#160;';
            }

            data.tools = this.setTools(this.tools, true);
        }

        // add title menu
        if (Array.isArray(this.menu) || this.menu === true) {
            data.menuId = Utils.generateUUID(5, 'id_');
            data.menu = Array.isArray(this.menu) ? this.setMenu(this.menu, data.menuId, true) : true;
            data.menuIcon = Utils.getIconMarkup(this.menuIcon);
        }

        // reserve space for title and buttons if needed
        data.topPx = Utils.isEmpty(data.title) ? '0' : '50px';
        data.bottomPx = Utils.isEmpty(data.buttons) ? '0' : '52px';

        return data;
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // call start session ping if defined
        Utils.sessionPing('start');

        if (Array.isArray(this.menu)) {
            // register dynamic material design components
            this.cHU(this.getEl('.mdl-menu'));
        }

        // render tooltip for tools if defined
        Array.prototype.slice.call(this.containerEl.querySelectorAll('button')).forEach((b) => {
            if (b.dataset.tip) {
                this.tips.push(Utils.tooltip(b.id, b.dataset.tip));
            }
        }, this);

        // make card draggable
        Element.makeDraggable('#' + this.id + ' .mdl-card__title', '#' + this.id);
        CmpMgr.bringToFront(this.id);
    }

    /**
     * Executed when close button is clicked
     */
    onClose() {
        this.hide(this.destroyOnClose);

        // call start session ping if defined
        Utils.sessionPing('stop');
    }

    /**
     * Executed when minimize buttons is clicked
     */
    onMinimize() {
        this.hide();
    }

    /**
     * Executed when card is restored for minimized state
     */
    onRestore() {
        this.hidden = false;
    }

    /**
     * Show loading mask over card content
     *
     * @param {String} msg Mask message
     * @param {Boolean} hs Hide spinner
     */
    showMask(msg, hs) {
        if (! this.containerEl) {
            return;
        }

        var maskEl = this.getEl('.mdl-card__supporting-text');
        maskEl.insertAdjacentHTML('beforeend', require('babel-loader!template-string-loader!./CardMask.html')({
            msg   : msg || '',
            hSpin : hs || false
        }));

        // register dynamic material design components
        if (hs !== false) {
            this.cHU(maskEl.querySelector('.mdl-spinner'));
        }
    }

    /**
     * Hide loading mask over card content
     */
    hideMask() {
        if (! this.containerEl) {
            return;
        }

        var m = this.getEl('.asseco-mask');
        if (m) {
            m.parentNode.removeChild(m);
        }
    }

    /**
     * Set card size
     *
     * @param {Number} w Width
     * @param {Number} h Height
     */
    setSize(w, h) {
        if (w) {
            Element.setStyle(this.containerEl, 'width', w);
        }
        if (h) {
            Element.setStyle(this.containerEl, 'height', h);
        }
    }

    /**
     * Set card title
     *
     * @param {String} str
     */
    setTitle(str = '') {
        var t = this.getEl('.mdl-card__title-text');
        if (t) {
            t.textContent = str;
        }
    }

    /**
     * Set card content
     *
     * @param {String} str
     */
    setContent(str = '') {
        var c = this.getEl('.mdl-card__supporting-text');
        if (c) {
            c.innerHTML = str;
        }
    }

    /**
     * Set buttons for card
     *
     * @param {Array} btns Array of buttons configuration
     * @param {Boolean} r Return HTML
     */
    setButtons(btns, r) {
        var buid, bHtml = '';

        this.attachHandlers = this.attachHandlers || {};

        btns = Array.isArray(btns) ? btns : [btns];
        btns.forEach(b => {
            buid = b.id || Utils.generateUUID(5, 'id_');
            bHtml += '<button id="' + buid + '" class="' + (b.cls || this.buttonsCls) + '" ' + (b.disabled ? 'disabled' : '') + '>'
                        + (b.icon ? (Utils.getIconMarkup(b.icon) + ' ') : '') + b.text +
                    '</button>';

            if (b.hasOwnProperty('handler')) {
                this.attachHandlers[buid] = {
                    eventType : b.eventType || 'click',
                    scope     : b.scope     || this,
                    handler   : b.handler
                };
            }
        }, this);

        // don't add generated HTML, just return it
        if (r) {
            return bHtml;
        }

        // check if div for buttons exists and if not create it
        var t = this.getEl('.mdl-card__actions');
        if (! t) {
            this.containerEl.insertAdjacentHTML('beforeend', '<div class="mdl-card__actions mdl-card--border" style="text-align: ' + this.buttonsAlign + ';"></div>');
            t = this.getEl('.mdl-card__actions');
        } else {
            Element.setStyle(t, 'textAlign', this.buttonsAlign);
        }

        // if no buttons to add remove actions div
        if (Utils.isEmpty(btns)) {
            Element.removeNode(t);
            return;
        }

        t.innerHTML = bHtml;

        this.doAttachHandlers();
    }

    /**
     * Set tools for card
     *
     * @param {Array} tls Array of tools configuration
     * @param {Boolean} r Return HTML
     */
    setTools(tls, r) {
        var tuid, tHtml = '';

        this.attachHandlers = this.attachHandlers || {};

        tls = Array.isArray(tls) ? tls : [tls];
        tls.forEach(t => {
            tuid = t.id || Utils.generateUUID(5, 'id_');
            tHtml += '<button id="' + tuid + '" ' + (t.tip ? ('data-tip="' + t.tip + '"') : '') + ' class="' + this.toolsCls + '">'
                        + Utils.getIconMarkup(t.icon, t.cls) +
                    '</button>';

            if (t.hasOwnProperty('handler')) {
                this.attachHandlers[tuid] = {
                    eventType : t.eventType || 'click',
                    scope     : t.scope     || this,
                    handler   : t.handler
                };
            }
        }, this);

        // don't add generated HTML, just return it
        if (r) {
            return tHtml;
        }

        // check if div for buttons exists and if not create it
        var m = this.getEl('.mdl-card__menu');
        if (! m) {
            this.containerEl.insertAdjacentHTML('beforeend', '<div class="mdl-card__menu"></div>');
            m = this.getEl('.mdl-card__menu');
        }
        m.innerHTML = tHtml;

        // render tooltip for tools if defined
        Array.prototype.slice.call(this.containerEl.querySelectorAll('button')).forEach((b) => {
            if (b.dataset.tip) {
                this.tips.push(Utils.tooltip(b.id, b.dataset.tip));
            }
        }, this);

        this.doAttachHandlers();
    }

    /**
     * Set tools for card
     *
     * @param {Array} ms Array of menu items configuration
     * @param {String} mid  Menu id
     * @param {Boolean} r Return HTML
     */
    setMenu(ms, mid, r) {
        var muid, mHtml = '';

        this.attachHandlers = this.attachHandlers || {};

        if (Array.isArray(ms)) {
            ms.forEach(m => {
                muid = m.id || Utils.generateUUID(5, 'id_');
                mHtml += '<li id="' + muid + '" ' + (m.disabled ? 'disabled' : '') + ' class="mdl-menu__item ' + (m.divider ? 'mdl-menu__item--full-bleed-divider' : '') + '">'
                            + m.label +
                        '</li>';

                if (m.hasOwnProperty('handler')) {
                    this.attachHandlers[muid] = {
                        eventType : m.eventType || 'click',
                        scope     : m.scope     || this,
                        handler   : m.handler
                    };
                }
            }, this);
        }

        // don't add generated HTML, just return it
        if (r) {
            return mHtml;
        }

        mid = mid || Utils.generateUUID(5, 'id_');

        // get card title element (in title element are menu button and ul)
        var tEl = this.getEl('.mdl-card__title');

        // check if menu button exists and if not create it
        var mb = this.getEl('.mdl-card__title .asseco-card-menu-icon');
        if (! mb) {
            tEl.insertAdjacentHTML('afterbegin',
                '<button id="' + mid + '" class="mdl-button mdl-button--icon asseco-card-menu-icon">'
                    + Utils.getIconMarkup(this.menuIcon) +
                '</button>');
        }

        // check if ul for menu exists and if not create it
        var mu = this.getEl('.mdl-card__title .mdl-menu');
        if (! mu && ms !== true) {
            tEl.insertAdjacentHTML('afterbegin', '<ul class="mdl-menu mdl-menu--bottom-left mdl-js-menu" for="' + mid + '"></ul>');
            mu = this.getEl('.mdl-card__title ul.mdl-menu');
        }
        mu.innerHTML = mHtml;

        if (ms !== true) {
            this.doAttachHandlers();

            // register dynamic material design components
            this.cHU(this.getEl('.mdl-menu'));
        }
    }
}
LiveCard.prototype.xtype = 'LiveCard';
export default LiveCard;

