import Svgs from 'data/Svgs';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import Utils from 'util/Utils';
import LiveField from 'widgets/field/Field';
import LiveList from 'widgets/list/List';
import LiveDirectMessagingPreviewWindow from './../preview/Preview';
import LiveDirectMessagingNewWindow from './../new/New';

/**
 * LIVE Messaging Window Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveDirectMessagingWindow extends LiveList {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id           : 'asseco-directmessaging-window',
            title        : a24n('Direct Messaging'),
            enableFilter : true,
            inIcon       : 'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z',
            outIcon      : 'M2.01 21L23 12 2.01 3 2 10l15 2-15 2z',
            flagIcon     : 'M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z'
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        // add title menu
        this.menu = [{
            label: a24n('New message'),
            eventType: 'click',
            scope: this,
            handler: this.onNewMessage
        }];

        super.initComponent();
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Get list of files
     *
     * @private
     */
    fetchList() {
        super.fetchList();

        this.showMask();

        var d = {
            limit  : this.limit || 15,
            filter : this.filter || [
                {field: 'folder_id', value: '2'},
                {field: 'seen',      value: '0'}
            ]
        };

        console.log('LiveDirectMessagingWindow::Fetching list');
        Connection.sendMessage(Constants.REQ_DIRECT_MESSAGE_LIST, d, this.onFetchList, this);
    }

    /**
     * Executed after list is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onFetchList(conn, msg) {
        var d = JSON.parse(msg.data);

        this.newCount = 0;

        this.addItem(d.data);
        this.hideMask();
    }

    /**
     * Add item(s) to list
     *
     * @param {Object|Array} item Item with id, name and icon for adding to list
     */
    addItem(item) {
        // make sure we have array
        item = Array.isArray(item) ? item : [item];
        var desc;
        item.forEach(i => {
            i.name = i.title;

            desc = '<span class="itemDesc">';
            desc += '<span class="itemDescPrim">' + Utils.toDate(i.sent_received, true) + '</span>';

            var s = '<span class="itemDescSec">';
            if (i.has_attachment === '1') {
                s += Utils.getIconMarkup(Svgs.ATT, '', '', '#000');
            }
            if (! Utils.isEmpty(i.flags)) {
                i.flags.some(f => {
                    if (f.flag === 'Flagged') {
                        s += Utils.getIconMarkup(this.flagIcon, '', '', '#d32f2f');
                        return true;
                    }
                }, this);
            }
            s += '</span>';
            desc += s;

            desc += '</span>';
            i.desc = desc;
            i.itemCls = i.folder_id === '2' && i.seen === '0' ? 'newMessage' :  '';
            i.icon = i.folder_id === '1' ? this.outIcon : this.inIcon;
        }, this);

        console.log('Add item: ', item);
        super.addItem(item);
    }

    /**
     * Executed on filter tool click (Show filter mask)
     *
     * @private
     */
    showFilterMask() {
        var fR = LiveField.getRadioField;

        var folder = '2', seen = '0';
        if (! Utils.isEmpty(this.filter)) {
            this.filter.forEach(f => {
                switch (f.field)
                {
                case 'folder_id':
                    folder = f.value;
                    break;

                case 'seen':
                    seen = f.value;
                    break;
                }
            }, this);
        }

        this.setContent(
            '<div style="padding: 15px 0 15px 0;">' + a24n('Show items') + ': </div>' +
            fR({
                name  : 'folder_id',
                label : a24n('All'),
                value : 'all',
                checked : folder === 'all',
                attrs : ' style="width: 100%"'
            }) +
            fR({
                name    : 'folder_id',
                label   : a24n('Inbox'),
                value   : '2',
                checked : folder === '2',
                attrs   : ' style="width: 100%"'
            }) +
            fR({
                name  : 'folder_id',
                label : a24n('Sent'),
                value : '1',
                checked : folder === '1',
                attrs : ' style="width: 100%"'
            }) +
            '<div style="padding: 15px 0 15px 0;">' + a24n('Show status') + ': </div>' +
            fR({
                name  : 'seen',
                label : a24n('All'),
                value : 'all',
                checked : seen === 'all',
                attrs : ' style="width: 100%"'
            }) +
            fR({
                name    : 'seen',
                label   : a24n('Seen'),
                value   : '1',
                checked : seen === '1',
                attrs   : ' style="width: 100%"'
            }) +
            fR({
                name  : 'seen',
                label : a24n('Not Seen'),
                value : '0',
                checked : seen === '0',
                attrs : ' style="width: 100%"'
            })
        );

        // register radio buttons
        Array.prototype.slice.call(this.containerEl.querySelectorAll('label.mdl-radio')).forEach(c => {
            this.cHU(c);
        }, this);

        this.setButtons([{
            text: a24n('Filter'),
            cls: this.buttonsCls + ' mdl-button--raised',
            icon: Svgs.FILTER,
            scope: this,
            handler: this.onFilter.createDelegate(this, [true], 0)
        }, {
            text: a24n('Close'),
            cls: this.buttonsCls + ' mdl-button--raised',
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onFilter.createDelegate(this, [false], 0)
        }]);
    }

    /**
     * Collect filter values
     *
     * @private
     */
    applyFilter() {
        this.filter = [
            {field: 'folder_id', value: this.getEl('input[name="folder_id"]:checked').value},
            {field: 'seen',      value: this.getEl('input[name="seen"]:checked').value}
        ];
    }

    /**
     * Executed on list action click
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} li Clicked HTML list element (li)
     * @param {Object} dO Original list item data
     * @param {Object} dR Rendered list item data
     */
    // eslint-disable-next-line no-unused-vars
    onItemClick(e, li, dO, dR) {
        // info item about no data to display
        if (li.id === 'asseco-list-item__nodata') {
            return;
        }

        // open preview window
        new LiveDirectMessagingPreviewWindow({
            title: dO.name,
            message: dO
        });
    }

    /**
     * Executed on new message click
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} el HTML element that was clicked
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onNewMessage(e, el) {
        // open new window
        new LiveDirectMessagingNewWindow();
    }
}
LiveDirectMessagingWindow.prototype.xtype = 'LiveDirectMessagingWindow';
export default LiveDirectMessagingWindow;
